 
 
import { config } from "./helper";


const {Url}=config(process.env.REACT_APP_SERVER_ENVIRONMENT)
 
  export const SWIPELINC_API =  Url
  
console.log(SWIPELINC_API , process.env.REACT_APP_SERVER_ENVIRONMENT )

export const CHARGE_BACK_API =
  `${SWIPELINC_API}MerchantPanel/merchant/chargeback/findbymid/`;

// export const PIE_CHART_API =
//   "https://api.swipelinc.com/MerchantPanel/merchant/dashboard/getdashboardpiechart/";

/* testing server*/
export const PIE_CHART_API =
  `${SWIPELINC_API}MerchantPanel/merchant/dashboard/getdashboardpiechart/`;

// export const MULTILINE_CHART_API =
//   "https://api.swipelinc.com/MerchantPanel/merchant/dashboard/getdashboardTrendchart/";

/* testing server*/
export const MULTILINE_CHART_API =
  `${SWIPELINC_API}MerchantPanel/merchant/dashboard/getdashboardTrendchart/`;

// export const SETTLEMENT_REPORT_API =
//   "https://api.swipelinc.com/MerchantPanel/merchant/settlement/findBymid/";

/* testing server*/
export const SETTLEMENT_REPORT_API =
`${SWIPELINC_API}MerchantPanel/merchant/settlement/findBymid/`;

// export const GET_REPORT_BY_CONDITION_API =
//   "https://api.swipelinc.com/MerchantPanel/merchant/reports/getreportByCondition";
/* testing server*/
export const GET_REPORT_BY_CONDITION_API =
`${SWIPELINC_API}MerchantPanel/merchant/reports/getreportByCondition`;

  export const GET_DASHBOARD_API_TILLLASTDAY=
  `${SWIPELINC_API}MerchantPanel/merchant/dashboard/getdashboardtillLastday/`;
// export const GET_REPORT_API =
//   "https://api.swipelinc.com/MerchantPanel/merchant/reports/getreport";

/* testing server*/
export const GET_REPORT_API =
`${SWIPELINC_API}MerchantPanel/merchant/reports/getreport`;

/* testing server*/
export const GET_DASHBOARD_API =
`${SWIPELINC_API}MerchantPanel/merchant/dashboard/getdashboard/`;

export const GET_DASHBOARD_AMOUNT_API =
`${SWIPELINC_API}MerchantPanel/merchant/dashboard/getamountdata/`;

// export const LOGIN_API =
//   "https://api.swipelinc.com/MerchantPanel/merchant/login";

/* testing server*/
export const LOGIN_API =
`${SWIPELINC_API}MerchantPanel/merchant/login`;
// export const LOGIN_API =
//   "https://merchant.lincpay.in/SwipelincMerchantPanel/merchant/login";

// export const FORGOT_PASSWORD_API =
//   "https://api.swipelinc.com/MerchantPanel/merchant/passwordreset/forgotPassword";

/* testing server*/
export const FORGOT_PASSWORD_API =
`${SWIPELINC_API}MerchantPanel/merchant/passwordreset/forgotPassword`;

// export const OTP_VERIFY_API =
//   "https://api.swipelinc.com/MerchantPanel/merchant/passwordreset/otpverify";

/* testing server*/
export const OTP_VERIFY_API =
`${SWIPELINC_API}MerchantPanel/merchant/passwordreset/otpverify`;

// export const CHANGE_PASSWORD =
//   "https://api.swipelinc.com/MerchantPanel/merchant/passwordreset/changePassword";

/* testing server*/
export const CHANGE_PASSWORD =
`${SWIPELINC_API}MerchantPanel/merchant/passwordreset/changePassword`;

  export const MULTIPLE_FILTER_API =
  `${SWIPELINC_API}MerchantPanel/merchant/reports/getreportByMultpalCondition`;

 export const FIND_BY_ORDER_NO =
 `${SWIPELINC_API}MerchantPanel/merchant/reports/findByOrderNo`

  // verify account number and ifsc code
  export const ACC_NUM_IFSC_VERIFY_API =
`${SWIPELINC_API}MerchantPanel/merchant/ekyc/bankVerification`;


  /* get details by ifsc */
export const IFSC_VERIFY_API =
`${SWIPELINC_API}MerchantPanel/merchant/ekyc/findByIfsc`;

 /* verify upi id */
 export const VERIFY_UPI_API =
 `${SWIPELINC_API}MerchantPanel/merchant/ekyc/VPAVerification`;

 /* verify vender registration email id */
 export const VERIFY_VENDER_EMAIL =
 `${SWIPELINC_API}MerchantPanel/merchant/vendor/findByEmail/`;

  /* verify vender registration email id */
export const VERIFY_VENDER_MOBILE =
`${SWIPELINC_API}MerchantPanel/merchant/vendor/findByMobile/`;

  /* verify vender registration aadhaar card and send otp */
export const VERIFY_VENDER_AADHAAR =
  `${SWIPELINC_API}MerchantPanel/merchant/ekyc/aadhaarVerification`;
  
    /* verify vender registration aadhaar card and send otp */
export const VERIFY_VENDER_AADHAAR_OTP =
  `${SWIPELINC_API}MerchantPanel/merchant/ekyc/aadhaarOtpVerification`;
    

  /* verify vender registration pan card and send otp */
  export const VERIFY_VENDER_PAN =
  `${SWIPELINC_API}MerchantPanel/merchant/ekyc/panVerification`;
  
   /* save vender registration data*/
   export const SAVE_VENDER_DETAILS =
   `${SWIPELINC_API}MerchantPanel/merchant/vendor/save`;
   
   /* get all vender*/
   export const FETCH_ALL_VENDERS_BY_MID =
   `${SWIPELINC_API}MerchantPanel/merchant/vendor/getAllVendorByMid/`;
   
   export const FETCH_ALL_VENDERS_BY_STATUS_AND_MID =
   `${SWIPELINC_API}MerchantPanel/merchant/vendor/getAllVendorByStatus`;
   

   /* get vender details by vender id*/
   export const FETCH_VENDER_DETAILS_BY_VENDER_ID =
   `${SWIPELINC_API}MerchantPanel/merchant/vendor/getVendorById/`;

   // --------MERCHANT ONBOARDING------------

   // verify email is pre verified or not
  export const VERIFY_MERCHANT_ONBOARD_EMAIL = 
  `${SWIPELINC_API}MerchantPanel/merchant/onboard/verified/email`;

     // verify mobile is pre verified or not
     export const VERIFY_MERCHANT_ONBOARD_MOBILE = 
     `${SWIPELINC_API}MerchantPanel/merchant/onboard/verified`;

      
   export const VIEW_PAYOUT_REQUEST_UPI =
   `${SWIPELINC_API}/MerchantPanel/merchant/payoutRagister/getAllSubmittedPayoutByMid`;
   
         
   export const VIEW_PAYOUT_REQUEST_UPI_BY_MID =
   `${SWIPELINC_API}MerchantPanel/merchant/payoutRagister/findByMidWithStatusNotPending`;
   

   // check file is pdf or not
   export const CHECK_FILE_IS_PDF = 
   `${SWIPELINC_API}MerchantPanel/merchant/onboard/checkPdf`;

   // UPLOAD MERCHANT DOCS
   export const UPLOAD_MERCHANT_DOCS = 
   `${SWIPELINC_API}MerchantPanel/merchant/onboard/savemerchantdocuments`;

// UPDATE MERCHANT BANK DETAILS
export const UPDATE_MERCHANT_BANK_DETAILS = 
`${SWIPELINC_API}MerchantPanel/merchant/onboard/savebankDetails`;

export const VIEW_E_KYC_REPORT = 
`${SWIPELINC_API}MerchantPanel/merchant/ekyc/userUses`;

// send otp to mobile
export const SEND_OTP_TO_MOBILE = 
`${SWIPELINC_API}MerchantPanel/merchant/verify-email/sendotpmobile`;

export const SAVE_PAYOUT_REQUEST = 
`${SWIPELINC_API}MerchantPanel/merchant/payoutRequest/save`;

export const GET_ALL_PAYOUT_REQUEST = 
`${SWIPELINC_API}/MerchantPanel/merchant/payoutRequest/getAll/`;

export const  PAYOUT_REQUEST_DELETE = 
`${SWIPELINC_API}/MerchantPanel/merchant/payoutRequest/delete/`;


export const  WITHDRAW_REQUEST_FETCH = 
`${SWIPELINC_API}MerchantPanel/merchant/withdrawal/findByMidWithStatusNotPending/`;

export const  PENDING_WITHDRAW_REQUEST_FETCH = 
`${SWIPELINC_API}/MerchantPanel/merchant/withdrawal/getWithdrawalByMidForAllTransactionStatusPending/`;


export const SETTLEMENT_REPORT_FETCH = 
`${SWIPELINC_API}MerchantPanel/merchant/settlement/settlement/findBymid/`;

export const SAVE_MISSING_CALLBACK = 
`${SWIPELINC_API}MerchantPanel/merchant/missingCallback/save`;

export const SETTLEMENT_MULTIPLE_FILTER_API = 
`${SWIPELINC_API}MerchantPanel/merchant/settlement/settlement/getSettlementByMultipalCondition`;


export const CHARGEBACK_MULTIPLE_FILTER_API = 
`${SWIPELINC_API}MerchantPanel/merchant/chargeback/chargeBack/ByMultpalCondition`;

export const WITHDRAW_MULTIPLE_FILTER_API = 
`${SWIPELINC_API}MerchantPanel/merchant/withdrawal/ByMultpalCondition`;

export const GURANTEE_MULTIPLE_FILTER_API = 
`${SWIPELINC_API}MerchantPanel/admin/guarantAmount/ByMultpalCondition`;

export const PAYOUT_MULTIPLE_FILTER_API = 
`${SWIPELINC_API}MerchantPanel/merchant/payoutRagister/getPayoutByMultipleCondition`;


export const GUARANTEE_REPORT_FETCH = 
`${SWIPELINC_API}MerchantPanel/admin/guarantAmount/findAllByMid/`;

export const FIND_BY_TRANSATION_ID= 
`${SWIPELINC_API}MerchantPanel/merchant/payoutRagister/getTransactionStatus`;

export const BALANCE_FETCH = 
`${SWIPELINC_API}MerchantPanel/merchant/payoutRagister/getBalance/`;

export const GET_NOTIFICATION_BY_MID= 
`${SWIPELINC_API}MerchantPanel/merchant/notification/findByMid/`;

export const DISABLE_NOTIFICATION_BY_ID= 
`${SWIPELINC_API}MerchantPanel/merchant/notification/Disable/`;

export const CHECK_EMAIL_IS_VERIFIED_OR_NOT = 
`${SWIPELINC_API}MerchantPanel/merchant/onboard/verified/email`;

export const CHECK_MOBILE_IS_VERIFIED_OR_NOT = 
`${SWIPELINC_API}MerchantPanel/merchant/onboard/verified`;


export const CHECK_THIRD_PARTY_API_STATUS = 
`${SWIPELINC_API}MerchantPanel/merchant/third-party-service/get`;

export const UPLOAD_CALLBACK_CSV = `${SWIPELINC_API}MerchantPanel/merchant/missingCallback/csv/upload`;

export const FIND_BY_MID_AND_ORDERNO = `${SWIPELINC_API}MerchantPanel/merchant/reports/findByMidAndOrderNo`;

export const FIND_PAYOUT_BALANCE = `${SWIPELINC_API}MerchantPanel/merchant/withdrawal/getWithdrawalAmount/`;

export const GET_ALL_RECONCIALIATION_DATA = `${SWIPELINC_API}MerchantPanel/merchant/reconsilation/getAll/reconciliationData`;

export const GET_ALL_SETTLEMET_ADJUSTMENT_DATA = `${SWIPELINC_API}/MerchantPanel/merchant/settlement/get-by-mid-and-remark/`;

export const GET_RECONCEALITION_DATE = `${SWIPELINC_API}/MerchantPanel/merchant/reconsilation/getDate`;

export const FETCH_MERCHANT_PROFILE = `${SWIPELINC_API}MerchantPanel/merchant/merchant-profile/getByMid/`;

export const FETCH_RELEASE_BY_MID = `${SWIPELINC_API}MerchantPanel/merchant/release/getBy/`;

export const FETCH_LIVE_TRACKING = `${SWIPELINC_API}MerchantPanel/MerchantPanel/LiveReport/getDataBy/`;

export const  UPLOAD_PAYOUT_TXN_CALLBACK_CSV = `${SWIPELINC_API}MerchantPanel/merchant/payoutRagister/csv/upload`;

export const RECONCILIATION_MULTIPLE_FILTER_API = 
`${SWIPELINC_API}MerchantPanel/merchant/reconsilation/getReconciliationByMultipleConditions`;

export const   GET_QR_STRING = `${SWIPELINC_API}/MerchantPanel/merchant/Util/getData`;
export const   VIRTUAL_TERMINAL_REQUEST_SUBMIT = `${SWIPELINC_API}/MerchantPanel/merchant/virtual-terminal/request`;

export const WALLET_BALANCE_REPORT_API = `${SWIPELINC_API}MerchantPanel/merchant/wallet/findByStatuss`;

export const FETCH_HOLD_BY_MID = `${SWIPELINC_API}MerchantPanel/merchant/hold/getHoldReport/`;

export const FETCH_DEPOSIT_BY_MID = `${SWIPELINC_API}MerchantPanel/merchant/deposit/getDepositeReportByMid/`;

export const column = [
  {
    label: "Payment Type",
    field: "paymentType",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Transaction ID",
    field: "txn_id",
    sort: "asc",
    width: "850px",
  },

  {
    label: "Transaction Date",
    field: "txnDate",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Transaction Time",
    field: "txnTime",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Terminal ID",
    field: "terminalId",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Amount",
    field: "amount",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Actual Amount",
    field: "actualAmount",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Currency",
    field: "currency",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Transaction Status",
    field: "txnStatus",
    sort: "asc",
    width: "auto",
    color: "red",
  },
  {
    label: "Response Date",
    field: "responseDate",
    sort: "asc",
    width: "auto",
  },
  {
    label: "UTR-No",
    field: "custRefNo",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Order-No",
    field: "orderNo",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Payer VPA",
    field: "userVpa",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Mobile No",
    field: "mobileNo",
    sort: "asc",
    width: "auto",
  },
  {
    label: "e-Mail ID",
    field: "email",
    sort: "asc",
    width: "auto",
  },

  {
    label: "Transaction Response Code",
    field: "txnRespCode",
    sort: "asc",
    width: "850px",
  },
  {
    label: "Bank Response Code",
    field: "evokTxnCode",
    sort: "asc",
    width: "850px",
  },
  {
    label: "Remark Description",
    field: "remark",
    sort: "asc",
    width: "550 em",
  },
  {
    label: "IFSC Code",
    field: "customerIfsc",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Bank Location",
    field: "bankLocation",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Customer IP address",
    field: "customerIp",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Customer Location",
    field: "customerLocation",
    sort: "asc",
    width: "auto",
  },
  // {
  //   label: "View Response",
  //   field: "view",
  //   sort: "asc",
  //   width: "auto",
  // },
  // {
  //   label: "View Txn Status",
  //   field: "txnStatusBtn",
  //   sort: "asc",
  //   width: "auto",
  // },
];

export const columnForVendorTable=[
  {
    label: 'e-Mail Id',
    field: 'email',
    sort: 'asc',
    width: 'auto'

  },
  {
    label: 'Payer VPA',
    field: 'userVPA',
    sort: 'asc',
    width: 'auto'

  },
  {
    label: 'Mobile Number',
    field: 'mobile',
    sort: 'asc',
    width: 'auto'

  },

]

export const columnForDepositTable = [
  {
    label: "Deposit Date",
    field: "Deposit Date",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Deposit Method",
    field: "Deposit Method",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Amount",
    field: "Amount",
    sort: "asc",
    width: "auto",
  },
];

export const columnForEKYCTable = [
  {
    label: "Request For",
    field: "Request For",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Result/Response",
    field: "Result/Response",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Particular",
    field: "Particular",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Charges",
    field: "Charges",
    sort: "asc",
    width: "auto",
  },
];

export const columnForWithdrawTable = [
  {
    label: "Created Date",
    field: "createDate",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Transaction Date",
    field: "transactionDate",
    sort: "asc",
    width: "auto",
  },
  {
    label: "UTR Number",
    field: "utr",
    sort: "asc",
    width: "auto",
  },
 
  {
    label: "Amount",
    field: "amount",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Transfer Type",
    field: "transferType",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Transfer Mode",
    field: "virtualAccountType",
    sort: "asc",
    width: "auto",
  },
  
  {
    label: "Transaction Status",
    field: "status",
    sort: "asc",
    width: "auto",
  },
 
];

export const columnForPendingWithdrawTable = [
  {
    label: "Created Date",
    field: "createDate",
    sort: "asc",
    width: "auto",
  },
  
 
  {
    label: "Amount",
    field: "amount",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Transfer Type",
    field: "transferType",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Transfer Mode",
    field: "virtualAccountType",
    sort: "asc",
    width: "auto",
  },
  
  {
    label: "Transaction Status",
    field: "status",
    sort: "asc",
    width: "auto",
  },
 
];

export const columnForReconciliationTable = [
  {
    label: "Date",
    field: "date",
    sort: "asc",
    width: "auto",
  },
  {
    label: "UTR Number",
    field: "switchRrn",
    sort: "asc",
    width: "auto",
  },
 
  {
    label: "Transaction ID",
    field: "extId",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Amount",
    field: "amount",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Transaction Status ",
    field: "transactionStatus",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Payer VPA / UPI ID",
    field: "payerVpa",
    sort: "asc",
    width: "auto",
  },
  // {
  //   label: "Transaction Status",
  //   field: "status",
  //   sort: "asc",
  //   width: "auto",
  // },
  {
    label: "Action",
    field: "action",
    sort: "asc",
    width: "auto",
  },
];
export const columnForReleaseReport = [
    
    
  { label: "Merchant ID", field: "mid", width: "auto" },
  { label: "Amount", field: "amount", width: "auto" },
  { label: "Release From", field: "releaseFrom", width: "auto" },
  { label: "Date", field: "createDate", width: "auto" },
  // { label: "Time", field: "createTime", width: "auto" },
  
];
export const columnForsettlementAdjustmentReport = [
   // { label: 'ID', field: 'id', width:'auto' },
       
      
     
    
   { label: "Merchant ID", field: "mid", width: "auto" },
   { label: "Transaction Status", field: "txnStatus", width: "auto" },
   //   { label: "Transaction ID", field: "txnId", width: "auto" },
   { label: "Amount", field: "netAmount", width: "auto" },
  //  { label: "Transaction Status", field: "txnStatus", width: "auto" },
 //   { label: "Company Name", field: "companyName", width: "auto" },
 //   { label: "RRN", field: "rrn", width: "auto" },
   { label: "Create Date", field: "createDate", width: "auto" },
   // { label: "Create Time", field: "settlmentTime", width: "auto" },
  //  { label: " Remark    ", field: "remark", width: "auto" },
];

export const columnForPayoutTable = [
  {
    label: "Date",
    field: "Date",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Time",
    field: "Time",
    sort: "asc",
    width: "auto",
  },
  {
    label: "To Customer/Vendor Name",
    field: "To Customer/Vendor Name",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Transfer Type",
    field: "Transfer Type",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Particular(A/C or VPA)",
    field: "Particular(A/C or VPA)",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Amount",
    field: "Amount",
    sort: "asc",
    width: "auto",
  },
  {
    label: "RRN",
    field: "rrn",
    sort: "asc",
    width: "auto",
  },
]


export const columnForSattlementTable = [
  {
    label: "Order Number",
    field: "orderNo",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Created Date",
    field: "createDate",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Created Time",
    field: "createTime",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Pay In Amount",
    field: "payinAmount",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Service Charge Amount",
    field: "serviceChargeAmount",
    sort: "asc",
    width: "auto",
  },
  {
    label: "GST Amount",
    field: "gstAmount",
    sort: "asc",
    width: "auto",
  },

  {
    label: "Net Amount",
    field: "netAmount",
    sort: "asc",
    width: "auto",
  },

  {
    label: "Transaction ID",
    field: "txnId",
    sort: "asc",
    width: "auto",
  },

  {
    label: "Remark",
    field: "remark",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Collection method",
    field: "collectionMethod",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Terminal ID",
    field: "terminalId",
    sort: "asc",
    width: "auto",
  },
];
export const columnForLedgerTable = [
  {
    label: "Date",
    field: "Date",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Time",
    field: "Time",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Transaction ID",
    field: "To Customer/Vendor Name",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Particular",
    field: "Transfer Type",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Credit",
    field: "Particular(A/C or VPA)",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Debit",
    field: "Amount",
    sort: "asc",
    width: "auto",
  },
  {
    label: "",
    field: "RRN",
    sort: "asc",
    width: "auto",
  },
];


export const columnForRequestPayout = [
  

  {
    label: "Vendor Name",
    field: "vendorName",
    sort: "disabled",
  },
  {
    label: "Type",
    field: "payoutFor",
    sort: "disabled",
  },
  // {
  //   label: "Category",
  //   field: "category",
  //   sort: "disabled",
  // },

  // {
  //   label: "Mobile",
  //   field: "mobile",
  //   sort: "asc",
  //   width: "auto",
  // },
  // {
  //   label: " e-Mail ID",
  //   field: "email",
  //   sort: "asc",
  //   width: "850px",
  // },

  {
    label: "VPA / UPI ID",
    field: "userVpa",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Account number",
    field: "accountNumber",
    sort: "asc",
    width: "auto",
  },
  {
    label: "IFSC Code",
    field: "ifscCode",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Amount In Rupees",
    field: "amount",
    sort: "disabled",
    width: "400px",
  },
  
];


export const columnForGuaranteeTable = [
  {
    label: "Transaction Date",
    field: "transactionDate",
    sort: "asc",
    width: "auto",
  },

  {
    label: "Guarantee Amount",
    field: "guarantyAmount",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Transaction Type",
    field: "transactionType",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Reference Number",
    field: "utrNumber",
    sort: "asc",
    width: "auto",
  },

  {
    label: "Net Amount",
    field: "netAmount",
    sort: "asc",
    width: "auto",
  },
];
export const columnforChargeback = [
  {
    label: "SNo",
    field: "index",
    sort: "asc",
    width: "auto",
  },
  {
    label: "RRN",
    field: "rrn",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Chargeback Upload Date",
    field: "createDate",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Chargeback Upload Time",
    field: "createTime",
    sort: "asc",
    width: "auto",
  },
  {
    label: "Chargeback Adjustment Date",
    field: "chargeBackAdjDate",
    sort: "asc",
    width: "auto",
  },
  
  {
      label: "Chargeback Amount",
      field: "chargeBackAmount",
      sort: "asc",
      width: "auto",
    },
    {
      label: "Compensation/Penalty",
      field: "compensationPenalty",
      sort: "asc",
      width: "auto",
    },
    {
      label: "Transaction Date",
      field: "transactionDate",
      sort: "asc",
      width: "auto",
    },
    {
      label: "Transaction Amount",
      field: "transactionAmount",
      sort: "asc",
      width: "auto",
    },
    {
      label: "Payer VPA",
      field: "payerVpa",
      sort: "asc",
      width: "auto",
    },
    {
      label: "Payer Bank",
      field: "payeeBank",
      sort: "asc",
      width: "auto",
    },
    {
      label: "Transaction UID",
      field: "transactionUid",
      sort: "asc",
      width: "auto",
    },
    {
      label: "Payer Mobile Number",
      field: "mobileNumber",
      sort: "asc",
      width: "auto",
    },
    {
      label: "Payer e-Mail ID",
      field: "emailId",
      sort: "asc",
      width: "auto",
    },
    {
      label: "Payer IFSC Code",
      field: "ifscCode",
      sort: "asc",
      width: "auto",
    },
    {
      label: "Payer Bank Location",
      field: "bankLocation",
      sort: "asc",
      width: "auto",
    },
    {
      label: "Payer IP Address",
      field: "payeeIpAddress",
      sort: "asc",
      width: "auto",
    },
    {
      label: "Payer Location",
      field: "payeeLocation",
      sort: "asc",
      width: "auto",
    },
    {
      label: "Actions",
      field: "actionButtons",
      sort: "asc",
      width: "auto",
    },
];

export const columnforWalletBalance = [
  {
    label: "Date",
    field: "createDate",
    sort: "asc",
    width: "auto"
  },
  {
    label: "Amount",
    field: "amount",
    sort: "asc",
    width: "auto"
  },
  {
    label: "UTR",
    field: "utr",
    sort: "asc",
    width: "auto"
  },
  {
    label: "Transfer Mode",
    field: "transferMode",
    sort: "asc",
    width: "auto"
  },
  {
    label: "Status",
    field: "status",
    sort: "asc",
    width: "auto"
  },
  // {
  //   label: "Remark",
  //   field: "remark",
  //   sort: "asc",
  //   width: "auto"
  // },
 
  // {
  //   label: "Merchant ID",
  //   field: "mid",
  //   sort: "asc",
  //   width: "auto"
  // },

 
  // {
  //   label: "Time",
  //   field: "createTime",
  //   sort: "asc",
  //   width: "auto"
  // },
 
]

export const columnforHoldAmount = [
  
  {
    label: "Amount",
    field: "amount",
    sort: "asc",
    width: "auto"
  },
  {
    label: "Actual Amount",
    field: "acctualAmount",
    sort: "asc",
    width: "auto"
  },
  {
    label: "Hold Percentage",
    field: "holdPercentage",
    sort: "asc",
    width: "auto"
  },
  {
    label: "Order Number",
    field: "orderNo",
    sort: "asc",
    width: "auto"
  },
  {
    label: "Transaction ID",
    field: "txnId",
    sort: "asc",
    width: "auto"
  },
 

  {
    label: "Created Date",
    field: "createDate",
    sort: "asc",
    width: "auto"
  },
 
 ]

 export const columnforDepositAmount = [
  
  {
    label: "Amount",
    field: "amount",
    sort: "asc",
    width: "auto"
  },
  {
    label: "Actual Amount",
    field: "acctualAmount",
    sort: "asc",
    width: "auto"
  },
  {
    label: "Deposite Percentage",
    field: "depositePercentage",
    sort: "asc",
    width: "auto"
  },
  {
    label: "Order Number",
    field: "orderNo",
    sort: "asc",
    width: "auto"
  },
  {
    label: "Transaction ID",
    field: "txnId",
    sort: "asc",
    width: "auto"
  },
 
  {
    label: "Date",
    field: "date",
    sort: "asc",
    width: "auto"
  },

 ]