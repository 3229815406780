import React from "react";
import { useSettlementReportDataFetcher } from "../../services/apiService";
import jwtDecode from "jwt-decode";
import { MDBDataTable } from "mdbreact";
import { FiDownload } from "react-icons/fi";
import Papa from "papaparse";

function SettlementReport() {
  const tokenValue = localStorage.getItem("token");
  const token = tokenValue ? tokenValue.slice(1, -1) : null;
  const decodedToken = jwtDecode(token);
  const { mid } = decodedToken;
  const listData = useSettlementReportDataFetcher(mid, token);

  const tableData = listData.map((item) => ({
    "Collection Date": item?.collectionDate,
    "Collection Method": item?.collectionMethod,
    "Terminal ID": item?.terminalId,
    "Total Count": item?.totalCount,
    "Total Collection": item?.totalCollection,
    "Service Charges": item?.serviceCharge,
    "GST on Charges": item?.gstOnCharge,
    "Charge-Back Amount": item?.chargeBackAmount,
    "Payable Amount": item?.payableAmount,
    "Security Deposit": item?.securityDeposit,
    "Amount on hold": item?.amountOnHold,
    "Settled Amount": item?.settledAmount,
    "Settlement Date": item?.settlementDate,
    "UTR of Settlement": item?.utrNumberOfSettlement,
    "Total Remaining Balance": item?.totalRemainingBalance,
  }));

  const tableColumns = Object.keys(tableData[0] || {});

  const data = {
    columns: tableColumns.map((column) => ({
      label: column,
      field: column,
    })),
    rows: tableData,
  };
  // LOGIC FOR DOWNLOAD CSV:

  const handleDownloadCSV = () => {
    const table = document.getElementById('settlement-datatable-container');
    const visibleRows = table.querySelectorAll('.dataTable tbody tr');

    const visibleData = [];

    visibleRows.forEach(row => {
        const rowData = [];
        row.querySelectorAll('td').forEach(cell => {
            rowData.push(cell.textContent.trim());
        });
        visibleData.push(rowData);
    });

    const headerLabels = data.columns.map(column => column.label);
    const dataWithHeader = [headerLabels, ...visibleData];

    const csv = Papa.unparse(dataWithHeader);

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'settlement_report.csv';
    link.style.display = 'none';

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
};

  

  return (
    <div id="settlement-datatable-container">
      {data === "No Record Found" || listData.length === 0 ? (
        <div className="no-data-message fs-5 w-100 bg-light fw-semibold rounded-3 px-5 pl-5 p-2 d-flex align-items-center justify-content-center">
          {" "}
          No data available.
        </div>
      ) : (
        <MDBDataTable
          className=" dataTable overflow-x-scroll"
          footer="none"
          data={data}
          striped
          bordered
          entries={50}
          exportToCSV
          responsive
          theadColor="#333"
          // small
        />
      )}
      {/*  */}
      {/* <MDBDataTable className=' dataTable overflow-x-scroll' 
            striped 
            bordered 
            exportToCSV 
            responsive 
            theadColor="#333"
            entries={50} 
            hover data={data} /> */}

<button className="advanced-btn btn btn-primary text-capitlize" onClick={handleDownloadCSV}>
                <FiDownload className="d-none" /><i className="bi bi-arrow-down-circle"></i> Download CSV
            </button>
    </div>
  );
}

export default SettlementReport;
