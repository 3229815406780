import jwtDecode from 'jwt-decode';
import { MDBDataTable } from "mdbreact";
import React, { useState } from 'react'
import { Card, Col, Container } from 'react-bootstrap';

const Refund = ({setShowLoader}) => {
    const [searchAdvance, setSearchAdvance] = useState(true);
    const [searchOption, setSearchOption] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [data1, setData1] = useState([]);
    const [error1, setError1] = useState(null);
  
    const [orderNumber, setOrderNumber] = useState("");
    const [RRN, setRRN] = useState("");
  
    // const handleModalClose = () => {
    //     setShow2(false);
    // }


    // const getData1 = async () => {
    //   const tokenValue = localStorage.getItem("token");
    //   const token = tokenValue ? tokenValue.slice(1, -1) : null;
    //   const decodedToken = jwtDecode(token);
    //   const { mid } = decodedToken;
    //   console.log(mid);
    //   console.log(searchValue + " vaus is");
    //   const Payload = {
    //     mid: mid,
    //     orderNumber: orderNumber,
    //     rrn: "",
    //   };
    //   try {
    //     setShowLoader(true);
    //     const response = await axios.post(FIND_BY_ORDER_NO, Payload, {
    //       headers: {
    //         Authentication: "Bearer " + token,
    //       },
    //     });
    //     setShowLoader(false);
    //     setData1(response?.data);
    //     console.log(" data is", response?.data);
    //   } catch (error) {
    //     setError1(true);
    //     console.error(error);
    //   }
    // };
  
    var data2 = [];
    const handleSearch = () => {
    //   getData1();
    //   data2 = [data1];
    };
 const column = [
        {
          label: "Payment Type",
          field: "paymentType",
          sort: "asc",
          width: "auto",
        },
        {
          label: "Transaction ID",
          field: "txn_id",
          sort: "asc",
          width: "850px",
        },
      
        {
          label: "Transaction Date",
          field: "txnDate",
          sort: "asc",
          width: "auto",
        },
        {
          label: "Transaction Time",
          field: "txnTime",
          sort: "asc",
          width: "auto",
        },
        {
          label: "Terminal ID",
          field: "terminalId",
          sort: "asc",
          width: "auto",
        },
        {
          label: "Amount",
          field: "amount",
          sort: "asc",
          width: "auto",
        },
        {
          label: "Actual Amount",
          field: "actualAmount",
          sort: "asc",
          width: "auto",
        },
        {
          label: "Currency",
          field: "currency",
          sort: "asc",
          width: "auto",
        },
        {
          label: "Transaction Status",
          field: "txnStatus",
          sort: "asc",
          width: "auto",
          color: "red",
        },
        {
          label: "Response Date",
          field: "responseDate",
          sort: "asc",
          width: "auto",
        },
        {
          label: "UTR-No",
          field: "custRefNo",
          sort: "asc",
          width: "auto",
        },
        {
          label: "Order-No",
          field: "orderNo",
          sort: "asc",
          width: "auto",
        },
        {
          label: "Payer VPA",
          field: "userVpa",
          sort: "asc",
          width: "auto",
        },
        {
          label: "Mobile No",
          field: "mobileNo",
          sort: "asc",
          width: "auto",
        },
        {
          label: "e-Mail ID",
          field: "email",
          sort: "asc",
          width: "auto",
        },
      
        {
          label: "Transaction Response Code",
          field: "txnRespCode",
          sort: "asc",
          width: "850px",
        },
        {
          label: "Remark Description",
          field: "remark",
          sort: "asc",
          width: "550 em",
        },
        {
          label: "IFSC Code",
          field: "customerIfsc",
          sort: "asc",
          width: "auto",
        },
        {
          label: "Action",
          field: " ",
          sort: "asc",
          width: "auto",
        },
        
      ];
  
    const datas = {
      columns: column,
      rows: data1.responseData,
    };
  
    return (
      <>
        {/* <div className="filter-field-area">
          <div className="bg-danger mb-3  d-flex justify-content-center">
            <div className="w-50 d-flex  ">
              <label className="bg-dark text-white rounded-3 border-none w-100 pl-5 p-2">
                Enter Order No
              </label>
              <input
                className="advanced-input-box form-control w-100"
                type="text"
                value={orderNumber}
                onChange={(e) => setOrderNumber(e.target.value)}
                placeholder="Enter Order Number"
              />
            </div>
           
          </div>
          <Button
            variant="outline-primary"
            size="sm"
            className="text-capitlize mt-2 mb-2 align-self-end"
            onClick={handleSearch}
          >
            <i class="bi bi-search mx-1"></i> Search
          </Button>
        </div> */}
  
        <section className="lincpay-dashboard-sec merchant-admin-info-form aadharform-sec">
            
          <Container>
            <div className="cart-view-sec mt-4">
              
                <Col xs={12} lg={12} md={12}>
                
                  <Card className="shadow-cst border-0 mb-3 chart-boxes merchant-info-form-sec">
                    <Card.Body>
                      <div className="merchant-form-info d-flex justify-content-center">
                        
                        <Col xs={12} lg={4} md={6}>
                                <div className="form-group">
                                  <label
                                    htmlFor="companyPanNumber"
                                    className="form-label"
                                  >
                                   Enter Order No
                                    <sup className="text-danger">*</sup>
                                  </label>
                                  <div className="input-field-validate">
                                    <input
                                      type="text"
                                      value={orderNumber}
                                      onChange={(e) => setOrderNumber(e.target.value)}
                                      className="form-control"
                                    />
  
                                    <div className="login-btn-sec">
                                      <button
                                        id="companyPanVerifyBtn"
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={handleSearch}
                                      >
                                       <i class="bi bi-search mx-1"></i>Search
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                        
                      </div>
                    </Card.Body>
                  </Card>
                  {/* Tab content end here */}
                </Col>
                {/* ./col */}
            
            </div>
          </Container>
        </section>
  
        {/* <div>
          <h2>Data Table</h2>
          <table className="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Title</th>
                <th>Body</th>
              </tr>
            </thead>
            <tbody>
  
              <tr>
                <td>{data1.customerName}</td>
                <td>{data1.upiTxnId}</td>
                <td>{data1.respMessage}</td>
              </tr>
  
            </tbody>
          </table>
        </div> */}
   <div className='p-4'>
     
          <MDBDataTable
            className=" dataTable overflow-x-scroll"
            data={datas}
            footer="none"
            striped
            bordered
            exportToCSV
            responsive
            theadColor="#333"
  
            // small
          />
          </div>
        
      </>
    );
  }

export default Refund
