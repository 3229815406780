import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import "./EmailForm.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateRegisteredIdEmail } from "../../store/slices/onBoardSlice";
import Logo from "../../assets/images/Logo.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";
import MyLoader from "../../common/MyLoader";
import { SEND_OTP_TO_MOBILE, SWIPELINC_API } from "../../utils/constant";
import {
  checkEmailIsVerifiedOrNot,
  checkMobileIsVerifiedOrNot,
  verifyMerchantEmailIsVerifiedOrNot,
  verifyMerchantMobileIsVerifiedOrNot,
} from "../../services/apiService";
import swal from "sweetalert";
import { isEmailIdRegexIsValid } from "../../utils/validations";
import { config } from "../../utils/helper";

const ResumeOnboarding = () => {
  const [pressedKeyName, setPressedKeyName] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [userData, setUserData] = useState({
    email: "",
    mobileNumber: "",
  });

  const { logoPath, Name } = config(process.env.REACT_APP_SERVER_ENVIRONMENT);
  const AppNameCapitalized=Name
  const AppName = AppNameCapitalized.toUpperCase()  

  const navigate = useNavigate();

  const getKeyPressed = (e) => {
    setPressedKeyName(e.key);
  };

  const handelValidations = (e) => {
    if (e.target.name === "mobileNumber") {
      if (userData.mobileNumber.length !== 10) {
        document.getElementById("mobErr").classList.remove("d-none");
        document.getElementById("verifyBtn").disabled = true;
        return;
      }
    }

    if (e.target.name === "email") {
      if (!isEmailIdRegexIsValid(userData.email)) {
        document.getElementById("emailErr").textContent =
          "*Please Enter Valid Email!";
        document.getElementById("emailErr").classList.remove("d-none");
        document.getElementById("verifyBtn").disabled = true;
        return;
      }
    }
  };

  const CheckEmailMobileIsVerifiedOrNot = async () => {
    if (userData.email.trim() === "") {
      document.getElementById("emailErr").classList.remove("d-none");
      document.getElementById("verifyBtn").disabled = true;
      return;
    }

    if (userData.mobileNumber.trim() === "") {
      document.getElementById("mobErr").classList.remove("d-none");
      document.getElementById("verifyBtn").disabled = true;
      return;
    }

    let EmailRes = await checkEmailIsVerifiedOrNot(
      userData.email,
      setShowLoader
    );
    let MobRes = await checkMobileIsVerifiedOrNot(
      userData.mobileNumber,
      setShowLoader
    );

    if (MobRes.statusCode === 200 || EmailRes.statusCode === 200) {
      console.log("Mob Res", MobRes?.data?.VarifyMobile === "true");
      console.log("Email Res", EmailRes?.data?.VarifyEmail === "true");
      console.log(
        "Res",
        MobRes?.data?.VarifyMobile === EmailRes?.data?.VarifyEmail
      );

      if (MobRes?.data?.VarifyMobile==="true" && EmailRes?.data?.VarifyEmail === "true") {
        swal({
          title: "Alert",
          text: "You Have Already OnBoarded. Please LogIn.",
          icon: "warning",
        }).then(function () {
          navigate("/");
        });
      } else if (MobRes?.data?.registeredId === EmailRes?.data?.registeredId) {
        localStorage.setItem("registrationId", MobRes?.data?.registeredId);
        swal({
          title: "Success!",
          text: "Great Please Continue Your Onboarding Process!",
          icon: "success",
        }).then(function () {
          navigate("/AdharForm");
        });
      } else {
        swal({
          title: "Alert!",
          text: "Either Email or Mobile Number does not match to registered Merchant",
          icon: "error",
        });
      }
    }
    if (MobRes?.statusCode === 307 && EmailRes?.statusCode === 307) {
      swal({
        title: "Alert",
        text: "Please Verify Your Email And Mobile First!",
        icon: "error",
      }).then(function () {
        navigate("/EmailForm");
      });
    }
  };

  const handleDataChange = (e) => {
    if (pressedKeyName === "Backspace" && e.target.name === "mobileNumber") {
      setUserData({ ...userData, [e.target.name]: e.target.value });
    }
    if (
      userData.mobileNumber.length >= 10 &&
      e.target.name === "mobileNumber"
    ) {
      return;
    }

    setUserData({ ...userData, [e.target.name]: e.target.value });

    document.getElementById("emailErr").classList.add("d-none");
    document.getElementById("mobErr").classList.add("d-none");
    document.getElementById("verifyBtn").disabled = false;
  };

  //   useEffect(() => {
  //     const verifyMobile = async () => {
  //       if (otpCorrect || mobileOtpCorrect) {
  //         // setShowSuccessPopup(true);
  //         swal({
  //           title: "Success!",
  //           text: "Your OTP's have been verified successfully !",
  //           icon: "success",
  //         }).then(function () {
  //           // window.location = "/MerchantInfoForm";
  //           navigate("/AdharForm");
  //         });
  //         try {
  //           const response = await axios.post(
  //             `${SWIPELINC_API}MerchantPanel/merchant/onboard/verified`,
  //             {
  //               mobile: userData.mobileNumber,
  //             }
  //           );

  //         //   setApiMobileResponse(response.data);
  //           // console.log(response.data, "mobile api");
  //         } catch (error) {
  //           console.error("API call failed:", error);
  //         }
  //         console.log({
  //           email: userData.email,
  //           mobile: userData.mobileNumber,
  //         });
  //         const NA = "NA";
  //         try {
  //           const response = await axios.post(
  //             // "https://api.swipelinc.com/MerchantPanel/merchant/onboard/save",
  //             `${SWIPELINC_API}MerchantPanel/merchant/onboard/save?mid=${NA}`,
  //             {
  //               email: userData.email,
  //               mobile: userData.mobileNumber,
  //             }
  //           );
  //         //   setRegisterId(response?.data);
  //           dispatch(updateRegisteredIdEmail(response?.data?.data?.registeredId));
  //           console.log(
  //             dispatch(
  //               updateRegisteredIdEmail(response?.data?.data?.registeredId)
  //             )
  //           );
  //           localStorage.setItem(
  //             "registrationId",
  //             response?.data?.data?.registeredId
  //           );
  //         } catch (error) {
  //           console.log("API call failed:", error);
  //         }
  //       }
  //     };

  //     verifyMobile();
  //   }, [otpCorrect, mobileOtpCorrect]); //eslint-disable-line

  return (
    <>
      {showLoader ? <MyLoader /> : ""}
      <section className="swiperlinc_login login_inner">
        <Container>
          <Row>
            <Col xs={12} lg={6} md={6} sm={12} className="login-bg">
              <div class="welcome-login">
                <div class="login-banner">
                  <img src={logoPath} alt="logo" class="img-fluid" />
                </div>
                <div class="mentor-course text-center">
                  <h2>Continue Your Journey With {AppName}</h2>
                </div>
              </div>
            </Col>
            <Col xs={12} lg={6} md={6} sm={12} className="login-wrap-bg">
              <div class="login-wrapper position-relative">
                <div class="loginbox">
                  <div class="w-100">
                    <div className="mb-md-5 mb-3 login-right-head">
                      <h2 className="title">Welcome Back</h2>
                      <p className="mb-0">
                        Welcome! Please Continue Your Onboarding Journey from
                        Here.
                      </p>
                    </div>

                    <form
                      //   onSubmit={handleFormSubmit}
                      className="login-right onbording-form-sec"
                    >
                      <div className="onbording-input">
                        <label htmlFor="email" className="form-label">
                          E-mail ID
                        </label>
                        <div className="input-group-onbording">
                          <div className="input-field-validate">
                            <input
                              type="email"
                              id="email_form_input"
                              name="email"
                              onKeyDown={(e) => {
                                if (e.key === " ") {
                                  e.preventDefault();
                                }
                              }}
                              placeholder="E-mail ID"
                              value={userData.email}
                              onChange={handleDataChange}
                              onBlur={handelValidations}
                              className=""
                            />
                          </div>
                          <span id="emailErr" className=" text-danger d-none">
                            *Please Enter Email
                          </span>
                        </div>
                      </div>

                      <div className="onbording-input">
                        <label htmlFor="mobileNumber" className="form-label">
                          Mobile Number
                        </label>

                        <div className="input-group-onbording">
                          <div className="input-field-validate">
                            <input
                              type="text"
                              // id="mobileNumber"
                              id="email_form_input"
                              name="mobileNumber"
                              onKeyDown={(e) => {
                                if (e.key === " ") {
                                  e.preventDefault();
                                }
                                getKeyPressed(e);
                              }}
                              placeholder="Mobile Number"
                              value={userData.mobileNumber}
                              onChange={handleDataChange}
                              onBlur={handelValidations}
                              className=""
                            />
                          </div>
                          <span id="mobErr" className=" text-danger d-none">
                            *Please Enter Mobile Number
                          </span>
                        </div>
                      </div>
                      <div className="login-btn-sec">
                        <button
                          type="button"
                          onClick={CheckEmailMobileIsVerifiedOrNot}
                          className="btn btn-dark"
                          id="verifyBtn"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* <ResponsiveAppBar /> */}
    </>
  );
};

export default ResumeOnboarding;
