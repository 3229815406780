/* eslint-disable react/jsx-no-target-blank */
import axios from "axios";
import jwtDecode from "jwt-decode";
import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useState } from "react";
// import InactiveServices from "../../common/InactiveServices";
// import EarningImg from "../../assets/images/Logo.png";
import { IoSendSharp } from "react-icons/io5";
import { SWIPELINC_API } from "../../utils/constant";
import { EmailIcon, EmailShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
// new import link
import "../../stylesheet/Reports.css";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { config } from "../../utils/helper";

const   Earnings = () => {
    const [activeButton, setactiveButton] = useState(1);
    const [showMyReferral, setShowMyReferral] = useState(false);
    const [earnData, setEarnData] = useState([]);
    const [commision, setCommision] = useState();
    // useEffect(() => {
    //   setactiveButton(1);
    // }, []);
    const referralData = JSON.parse(localStorage?.getItem("data"));
    const tokenValue = localStorage.getItem("token");
    const token = tokenValue ? tokenValue.slice(1, -1) : null;
    const decodedToken = jwtDecode(token);
    const { mid } = decodedToken;
     const [copy,setCopy]=useState("Copy")

     
   
    const {logoPath,Name}=config(process.env.REACT_APP_SERVER_ENVIRONMENT)
  
    const AppNameCapitalized=Name
    const AppName = AppNameCapitalized.toUpperCase()  

    const handleReferralButtonClick = () => {
        setShowMyReferral(true);
        setactiveButton(3);
    };
    const handleMyReferral = () => {
        setactiveButton(1);
        setShowMyReferral(false);
    };
    const generateShareUrl = (referralCode) => {
        // Replace this with your actual URL or content
        const shareUrl = `${referralData?.referralUrl}`;

        // For email sharing
        const subject = "Check out this link!";
        const body = `Hey, check out this link with my referral code (${referralCode}): ${shareUrl}`;

        // For WhatsApp sharing
        const whatsappShareText = `Hey, check out this link with my referral code (${referralCode}): ${shareUrl}`;

        return {
            emailShareUrl: `${subject}&body=${body}`,
            // emailShareUrl: `mailto:?subject=${subject}&body=${body}`,
            whatsappShareText,
        };
    };

    const commisionFetchData = async () => {
        try {
            console.log(`${SWIPELINC_API}`)
            const response = await axios.get(`${SWIPELINC_API}MerchantPanel/merchant/totalcommision/${mid}`, {
                headers: {
                    Authentication: "Bearer " + token,
                },
            });
             console.log(response.data);
            setCommision(response.data);
        } catch (error) {}
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch data from the API endpoint using Axios
                const response = await axios.get(`${SWIPELINC_API}MerchantPanel/merchant/onboard/myReferal/${mid}`);
                setEarnData(response.data.data);
                // console.log(response.data.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        commisionFetchData();
        fetchData();
    }, []);

    const { emailShareUrl, whatsappShareText } = generateShareUrl(referralData?.referralNo);

    const handleCopyClick = () => {
        // Create a temporary textarea element to copy the dynamic value
        const textarea = document.createElement('textarea');
        textarea.value = referralData?.referralNo;
        document.body.appendChild(textarea);
        textarea.select();
    
        // Execute the copy command
        document.execCommand('copy');
    
        // Clean up by removing the textarea
        document.body.removeChild(textarea);

           setCopy("Copied")
      };

    return (
        <>
            <section className="dashboard-header-sec merchant-earning-sec">
                <Container>
                    <div className="ds-header-sec">
                        <Row>
                            <Col xs={12} lg={12} md={12}>
                                <Card className="shadow border-0 mb-4 ds-header-card">
                                    <Card.Body>
                                        {/* <Card.Title className="h3">Reports</Card.Title>  */}
                                        <div className="merchant-tab-btn">
                                            <ul className="report-link nav-report-btn">
                                                <li className={activeButton === 1 ? "active" : ""} onClick={handleMyReferral}>
                                                    My referral link
                                                </li>
                                                <li className={activeButton === 3 ? "active" : ""} onClick={() => handleReferralButtonClick()}>
                                                    My Referral
                                                </li>
                                                <li
                                                    className={activeButton === 4 ? "active" : ""}
                                                    // onClick={() => setactiveButton(1)}
                                                >
                                                    Interest Income
                                                </li>
                                            </ul>
                                        </div>
                                    </Card.Body>
                                </Card>
                                {/* ./card */}
                            </Col>

                            <Col xs={12} lg={12} md={12}>
                                <Card className="shadow border-0 mb-4">
                                    <Card.Body>
                                        {/* <InactiveServices /> */}
                                        {showMyReferral === false && (
                                            <div className="Referral_earn">
                                                <h3 className="referral_value mb-3">Give Referral</h3>
                                                {/* <img src={EarningImg} alt="img-logo" /> */}
                                                <div className="referral_first_icon mb-3">
                                                    <IoSendSharp size={20} color="black" className="referral_logo" />
                                                    <p className="referral_invite">Invite Your Friends to {AppNameCapitalized}</p>
                                                </div>
                                                <div className="referral-cd mb-3">
                                                    <p className="referral_code">Referral Code</p>
                                                    <span className="referral-cd-value">{referralData?.referralNo}</span>
                                                </div>
                                                <div className="referal_links">
                                                    <p className="referral_link_main">Referral Link</p>
                                                    <div className="referral_share_parent">
                                                        <div className="share-link-btn">
                                                        <button onClick={handleCopyClick}>{`${copy}`}</button>
                                                        </div>
                                                        <div className="share-link-btn">
                                                            <WhatsappShareButton url={whatsappShareText}>
                                                                <h4 className="share_text_earning">Share via WhatsApp</h4>
                                                                <WhatsappIcon size={25} round />
                                                            </WhatsappShareButton>
                                                        </div>
                                                    </div>
                                                    {/* <button className="referral_invite_btn">Invite Friends</button> */}
                                                </div>
                                            </div>
                                        )}
                                        {showMyReferral && (
                                            <div className="report-chargeback-report merchant-grid-view table-responsive">
                                                <table className="table custom-table-style">
                                                    <thead>
                                                        <tr>
                                                            <th>Company name</th>
                                                            <th>Email</th>
                                                            <th>Commission percentage</th>
                                                            <th>Commission Amount</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {earnData?.map((item) => (
                                                            <tr key={uuidv4()}>
                                                                <td>{item?.companyName}</td>
                                                                <td>{item?.email}</td>
                                                                <td>{item?.commissionPercentage ? `${item.commissionPercentage}%` : ""}</td>
                                                                <td>{item?.commissionAmt}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default Earnings;
