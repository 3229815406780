import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";

const LogoutOnTokenExpiration = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkTokenExpiration = () => {
      const token = localStorage.getItem("token");

      if (token) {
        const decodedToken = jwtDecode(token);
        const { exp } = decodedToken;
        const tokenExpiry = exp * 1000;
        // const fDate = new Date("2023-07-19T12:10:19.258Z");

        if (new Date() > tokenExpiry) {
          localStorage.removeItem("token");
          navigate("/");
        }
      }
    };

    checkTokenExpiration(); // Check token expiration initially

    // Attach a 'beforeunload' event listener to trigger token expiration check when the user navigates away or refreshes the page
    const handleBeforeUnload = () => {
      checkTokenExpiration();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [navigate]);

  return null;
};

export default LogoutOnTokenExpiration;
