import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import "./EmailForm.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateRegisteredIdEmail } from "../../store/slices/onBoardSlice";
import Logo from "../../assets/images/Logo.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";
import { SEND_OTP_TO_MOBILE, SWIPELINC_API } from "../../utils/constant";
import {
  verifyMerchantEmailIsVerifiedOrNot,
  verifyMerchantMobileIsVerifiedOrNot,
} from "../../services/apiService";
import swal from "sweetalert";
import { config } from "../../utils/helper";

import MyLoader from "../../common/MyLoader";

const EmailForm = () => {
  const [showIncorrectOTP, setShowIncorrectOTP] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [apiMobileResponse, setApiMobileResponse] = useState(null);
  const [registerId, setRegisterId] = useState();
  const [otpCorrect, setOtpCorrect] = useState(false);
  const [mobileOtpCorrect, setMobileOtpCorrect] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const { logoPath, Name } = config(process.env.REACT_APP_SERVER_ENVIRONMENT);
  const [showLoader, setShowLoader] = useState(false);
  const AppNameCapitalized=Name
  const AppName = AppNameCapitalized.toUpperCase()  

  const navigate = useNavigate();

  const initialValues = {
    email: "",
    emailOTP: "",
    mobileNumber: "",
    mobileOTP: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("E-mail ID is required"),
    // emailOTP: Yup.string().required("Email OTP is required"),
    mobileNumber: Yup.string()
      .required("Mobile number is required")
      .matches(/^\d{10}$/, "Mobile number must be 10 digits"),
    // mobileOTP: Yup.string().required("Mobile OTP is required"),
  });

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      // console.log("Form submitted:", values);
      formik.resetForm();
    },
  });

  const handleSendOTP = async () => {
    if (formik.values.email === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Email!",
        icon: "error",
      });
      return;
    }

    if (formik.values.mobileNumber === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Mobile Number!",
        icon: "error",
      });
      return;
    }

    setShowLoader(true);
    let emailRes = await verifyMerchantEmailIsVerifiedOrNot(
      formik.values.email
    );
    let mobRes = await verifyMerchantMobileIsVerifiedOrNot(
      formik.values.mobileNumber
    );
    setShowLoader(false);

    if (emailRes.statusCode === 600 && mobRes.statusCode === 600) {
      swal({
        title: "Opps!",
        text: emailRes.message + " !",
        icon: "error",
      });
      return;
    }

    if (emailRes.statusCode === 200) {
      document.getElementById("email-error-message").classList.remove("d-none");
    }
    if (mobRes.statusCode === 200) {
      document.getElementById("mob-error-message").classList.remove("d-none");
    }

    if (emailRes.statusCode !== 200 && mobRes.statusCode !== 200) {
      try {
        setShowLoader(true);
        const response = await axios.post(
          `${SWIPELINC_API}MerchantPanel/merchant/verify-email/send-otp`,
          {
            email: formik.values.email,
          }
        );

        const responseMobOtp = await axios.post(SEND_OTP_TO_MOBILE, {
          mobile: formik.values.mobileNumber,
        });
        setShowLoader(false);
        document.getElementById("sendOtpBtn").classList.add("d-none");
        setApiResponse(response.data);
        setApiMobileResponse(responseMobOtp.data);
        // setApiMobileResponse(response.data)
        // if(response?.data?.statusCode === 200  ) {
        if (responseMobOtp?.data?.statusCode === 200) {
          setOtpCorrect(false); // Reset OTP correctness status
          setMobileOtpCorrect(false);
        } else {
          swal({
            title: "ALERT!",
            text: `${responseMobOtp.data.message} !`,
            icon: "error",
          });
          return;
        }
        // } else {
        //     swal({
        //       title: "ALERT!",
        //       text: `${response.data.message} !`,
        //       icon: "error",
        //     })
        //     return
        // }
      } catch (error) {
        console.error("API call failed:", error);
      }
    }
  };

  const handleOTPChange = async (event) => {
    const otp = event.target.value;
    formik.handleChange(event);
    let length = otp.length;
    if (length === 6) {
      try {
        const response = await axios.post(
          // "https://api.swipelinc.com/MerchantPanel/merchant/verify-email/emailVerify",
          `${SWIPELINC_API}MerchantPanel/merchant/verify-email/emailVerify`,
          {
            otp: otp,
          }
        );

        if (response.data.statusCode === 200) {
          setOtpCorrect(true);
        } else {
          setOtpCorrect(false);
          swal({
            title: "ALERT!",
            text: `Invalid Email OTP !`,
            icon: "error",
          });
          return;
        }
      } catch (error) {
        console.error("API call failed:", error);
      }
    }
  };

  const handleOTPChangeMobile = async (event) => {
    const otp = event.target.value;
    formik.handleChange(event);
    let length = otp.length;
    if (length === 6) {
      try {
        const response = await axios.post(
          // "https://api.swipelinc.com/MerchantPanel/merchant/verify-email/mobileVerify",
          `${SWIPELINC_API}MerchantPanel/merchant/verify-email/mobileVerify`,
          {
            email: formik.values.mobileNumber,
            otp: otp,
          }
        );

        if (response.data.statusCode === 200) {
          setMobileOtpCorrect(true);
        } else {
          setMobileOtpCorrect(false);
          swal({
            title: "ALERT!",
            text: `Invalid Mobile OTP !`,
            icon: "error",
          });
        }
      } catch (error) {
        console.error("API call failed:", error);
      }
    }
  };

  // const handleIncorrectOTPClose = (e) => {
  //   e.preventDefault();
  //   setShowIncorrectOTP(false);
  // };

  const handleFormSubmit = async () => {
    if (formik.errors.emailOTP || formik.errors.mobileOTP) {
      setShowIncorrectOTP(true);
    } else {
      if (
        apiResponse &&
        apiResponse.statusCode === 200
        //  &&
        // apiResponse.data &&
        // otpCorrect &&
        // mobileOtpCorrect
      ) {
        try {
          // console.log({
          //   email: formik.values.email,
          //   emailVerified: true,
          //   mobile: formik.values.mobileNumber,
          //   mobileVerified: true,
          // });
          // console.log({
          //   email: formik.values.email,
          //   emailVerified: true,
          //   mobile: formik.values.mobileNumber,
          //   mobileVerified: true,
          // });
          const response = await axios.post(
            `${SWIPELINC_API}MerchantPanel/merchant/onboard/save`,
            {
              email: formik.values.email,
              emailVerified: true,
              mobile: formik.values.mobileNumber,
              mobileVerified: true,
            }
          );
          console.log(response);
          if (response.data.statusCode === 200) {
            try {
              const mobileResponse = await axios.post(
                `${SWIPELINC_API}MerchantPanel/merchant/onboard/verified`,
                {
                  mobile: formik.values.mobileNumber,
                }
              );
              // console.log(mobileResponse.data);
              setApiMobileResponse(mobileResponse.data);
            } catch (error) {
              console.error("API call failed:", error);
            }
          } else {
            console.error("API call failed:", response.data.message);
          }
        } catch (error) {
          console.error("API call failed:", error);
        }
      } else {
        setShowIncorrectOTP(true);
      }
    }
  };

  useEffect(() => {
    const verifyMobile = async () => {
      if (otpCorrect || mobileOtpCorrect) {
        // setShowSuccessPopup(true);
        swal({
          title: "Success!",
          text: "Your OTP's have been verified successfully !",
          icon: "success",
        }).then(function () {
          // window.location = "/MerchantInfoForm";
          navigate("/AdharForm");
        });
        try {
          const response = await axios.post(
            `${SWIPELINC_API}MerchantPanel/merchant/onboard/verified`,
            {
              mobile: formik.values.mobileNumber,
            }
          );

          setApiMobileResponse(response.data);
          // console.log(response.data, "mobile api");
        } catch (error) {
          console.error("API call failed:", error);
        }
        console.log({
          email: formik.values.email,
          emailVerified: true,
          mobile: formik.values.mobileNumber,
          mobileVerified: true,
        });
        const NA = "NA";
        try {
          const response = await axios.post(
            // "https://api.swipelinc.com/MerchantPanel/merchant/onboard/save",
            `${SWIPELINC_API}MerchantPanel/merchant/onboard/save?mid=${NA}`,
            {
              email: formik.values.email,
              emailVerified: true,
              mobile: formik.values.mobileNumber,
              mobileVerified: true,
            }
          );
          setRegisterId(response?.data);
          dispatch(updateRegisteredIdEmail(response?.data?.data?.registeredId));
          console.log(
            dispatch(
              updateRegisteredIdEmail(response?.data?.data?.registeredId)
            )
          );

          console.log("BYY&YYYYY", response.data.data);

          if (response?.data?.statusCode === 200) {
            localStorage.setItem(
              "registrationId",
              response?.data?.data?.registeredId
            );
          } else if (response?.data?.statusCode === 600) {
            swal({
              title: "Alert!",
              text: "Unable To Generate Registration ID",
              icon: "error",
            }).then(function () {
              // window.location = "/MerchantInfoForm";
              navigate("/EmailForm");
            });
            return;
          } else {
            swal({
              title: "Alert!",
              text: response?.data?.message,
              icon: "error",
            }).then(function () {
              // window.location = "/MerchantInfoForm";
              navigate("/EmailForm");
            });
            return;
          }
        } catch (error) {
          console.log("API call failed:", error);
        }
      }
    };

    verifyMobile();
  }, [otpCorrect, mobileOtpCorrect]); //eslint-disable-line

  return (
    <>
      {showLoader ? <MyLoader /> : ""}
      <section className="swiperlinc_login login_inner">
        <Container>
          <Row>
            <Col xs={12} lg={6} md={6} sm={12} className="login-bg">
              <div class="welcome-login">
                <div class="login-banner">
                  <img src={logoPath} alt="logo" class="img-fluid" />
                </div>
                <div class="mentor-course text-center">
                  <h2>Welcome to {AppName}</h2>
                  {/* <p className="text-start">
                                    <b className="d-block mb-2">Dear Valued Merchant,</b>
                                    We're excited to welcome you to the SwipeLinc family! As you log in to our merchant panel, you're stepping into a world of seamless payment solutions and unparalleled convenience. <br />
                                    At SwipeLinc, we're dedicated to supporting your business growth with our cutting-edge payment gateway services. Our merchant panel is your gateway to streamlined transactions,
                                    <br /> real-time insights, and powerful customization tools. Thank you for choosing SwipeLinc as your payment partner. Your success is our priority, and we're here to assist you every step of the way.
                                    <span className="tanks-regards d-block mt-2 fw-700">
                                        Best regards, <br />
                                        The SwipeLinc Team
                                    </span>
                                </p> */}
                </div>
              </div>
            </Col>
            <Col xs={12} lg={6} md={6} sm={12} className="login-wrap-bg">
              <div class="login-wrapper position-relative">
                <div class="loginbox">
                  <div class="w-100">
                    <div class="img-logo">
                      {/* <img src={Logo} alt="logo" /> */}
                      <div class="back-home merchant-onbording-btn">
                        <Link to="/ContinueOnboarding">
                          <i class="bi bi-person-fill-add"></i>
                          <span>Resume Your Journey</span>{" "}
                        </Link>
                      </div>
                    </div>
                    <div className="mb-md-5 mb-3 login-right-head">
                      <h2 className="title">Registration</h2>
                      <p className="mb-0">
                        Welcome! Please Create your account.
                      </p>
                    </div>

                    <form
                      onSubmit={handleFormSubmit}
                      className="login-right onbording-form-sec"
                    >
                      <div className="onbording-input">
                        <label htmlFor="email" className="form-label">
                          E-mail ID
                        </label>
                        <div className="input-group-onbording">
                          <div className="input-field-validate">
                            <input
                              type="email"
                              id="email_form_input"
                              name="email"
                              onKeyDown={(e) => {
                                if (e.key === " ") {
                                  e.preventDefault();
                                }
                                document
                                  .getElementById("email-error-message")
                                  .classList.add("d-none");
                              }}
                              placeholder="E-mail ID"
                              value={formik.values.email}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className={
                                formik.touched.email && formik.errors.email
                                  ? "error form-control"
                                  : "form-control"
                              }
                            />

                            {apiResponse && apiResponse.statusCode === 200 && (
                              <div className="login-btn-sec">
                                <button
                                  type="button"
                                  onClick={handleSendOTP}
                                  className="btn btn-success"
                                >
                                  Resend OTP
                                </button>
                              </div>
                            )}
                          </div>
                          <span
                            id="email-error-message"
                            className="error-message d-none "
                          >
                            Email Already registered.
                          </span>
                          {formik.touched.email && formik.errors.email && (
                            <span className="error-message d-block">
                              {formik.errors.email}
                            </span>
                          )}
                        </div>

                        {apiResponse &&
                          apiResponse.statusCode === 200 &&
                          formik.values.email && (
                            <>
                              <div className="input-group-onbording-otp">
                                {otpCorrect ? (
                                  <p className="otp_message">
                                    Entered OTP is correct
                                  </p>
                                ) : (
                                  <p className="otp_message">
                                    Please Enter OTP
                                  </p>
                                )}
                                <input
                                  type="text"
                                  // id="emailOTP"
                                  id="email_form_input"
                                  name="emailOTP"
                                  onKeyDown={(e) => {
                                    if (e.key === " ") {
                                      e.preventDefault();
                                    }
                                  }}
                                  value={formik.values.emailOTP}
                                  onChange={handleOTPChange}
                                  onBlur={formik.handleBlur}
                                  className={
                                    formik.touched.emailOTP &&
                                    formik.errors.emailOTP
                                      ? "error form-control"
                                      : "form-control"
                                  }
                                  placeholder="Enter OTP"
                                />
                              </div>
                            </>
                          )}
                        {formik.touched.emailOTP && formik.errors.emailOTP && (
                          <span className="error-message">
                            {formik.errors.emailOTP}
                          </span>
                        )}
                      </div>

                      <div className="onbording-input">
                        <label htmlFor="mobileNumber" className="form-label">
                          Mobile Number
                        </label>

                        <div className="input-group-onbording">
                          <div className="input-field-validate">
                            <input
                              type="text"
                              // id="mobileNumber"
                              id="email_form_input"
                              name="mobileNumber"
                              onKeyDown={(e) => {
                                if (e.key === " ") {
                                  e.preventDefault();
                                }
                                document
                                  .getElementById("mob-error-message")
                                  .classList.add("d-none");
                              }}
                              placeholder="Mobile Number"
                              value={formik.values.mobileNumber}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className={
                                formik.touched.mobileNumber &&
                                formik.errors.mobileNumber
                                  ? "error form-control"
                                  : "form-control"
                              }
                            />

                            {apiResponse && apiResponse.statusCode === 200 && (
                              <div className="login-btn-sec">
                                <button
                                  type="button"
                                  onClick={handleSendOTP}
                                  className="btn btn-success"
                                >
                                  Resend OTP
                                </button>
                              </div>
                            )}
                          </div>
                          <span
                            id="mob-error-message"
                            className=" text-danger d-none"
                          >
                            *Mobile Number Already Registered !
                          </span>

                          {formik.touched.mobileNumber &&
                            formik.errors.mobileNumber && (
                              <span className="error-message">
                                {formik.errors.mobileNumber}
                              </span>
                            )}
                        </div>
                        {apiMobileResponse &&
                          apiMobileResponse.statusCode === 200 &&
                          formik.values.mobileNumber && (
                            <>
                              <div className="input-group-onbording-otp">
                                {mobileOtpCorrect ? (
                                  <p className="otp_message">
                                    Entered OTP is correct
                                  </p>
                                ) : (
                                  <p className="otp_message">
                                    Please Enter OTP
                                  </p>
                                )}

                                <input
                                  type="text"
                                  // id="mobileOTP"
                                  id="email_form_input"
                                  onKeyDown={(e) => {
                                    if (e.key === " ") {
                                      e.preventDefault();
                                    }
                                  }}
                                  name="mobileOTP"
                                  value={formik.values.mobileOTP}
                                  onChange={handleOTPChangeMobile}
                                  onBlur={formik.handleBlur}
                                  className={
                                    formik.touched.mobileOTP &&
                                    formik.errors.mobileOTP
                                      ? "error form-control"
                                      : "form-control"
                                  }
                                  placeholder="Enter OTP"
                                />

                                {formik.touched.mobileOTP &&
                                  formik.errors.mobileOTP && (
                                    <span className="error-message">
                                      {formik.errors.mobileOTP}
                                    </span>
                                  )}
                              </div>
                            </>
                          )}
                        {formik.touched.mobileOTP &&
                          formik.errors.mobileOTP && (
                            <span className="error-message">
                              {formik.errors.mobileOTP}
                            </span>
                          )}
                      </div>
                      <div className="login-btn-sec">
                        <button
                          type="button"
                          onClick={handleSendOTP}
                          id="sendOtpBtn"
                          className="btn btn-dark"
                        >
                          Send OTP
                        </button>
                      </div>
                      {/* <button
                type="button"
                className="send-otp-button-email-form"
                onClick={handleSendOTP}
              >
                Send OTP
              </button> */}
                      {/* <div className="form-group">
                                            <label className="form-label">Username</label>
                                            <span className="icon icon-type-email"></span>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Username"
                                                value={userName}
                                                onKeyDown={(e) => {
                                                    if (e.key === " ") {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                onChange={(e) => setuserName(e.target.value)}
                                            />
                                            {errorMessages?.mid && <div>{errorMessages?.mid}</div>}
                                        </div>

                                        <div className="form-group">
                                            <label className="form-label">Password</label>
                                            <span className="icon icon-lock"></span>
                                            <div className="password-input">
                                                <input
                                                    type={showPassword ? "text" : "password"}
                                                    className="form-control"
                                                    placeholder="Password"
                                                    value={password}
                                                    onKeyDown={(e) => {
                                                        if (e.key === " ") {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />
                                                <i className={`password-toggle far ${showPassword ? "fa-eye-slash" : "fa-eye"}`} onClick={() => setShowPassword(!showPassword)}></i>
                                            </div>
                                            {errorMessages.password && <div className="alert alert-danger mb-4">{errorMessages.password}</div>}
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12 text-sm-end">
                                                <div className="mb-md-4 mb-2">
                                                    <Link to="/ForgotPassword" className="text-red fnt-18 fnt-s">
                                                        Forgot Password?
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="login-btn-sec">
                                            <button type="submit" className="btn btn-dark">
                                                Log In
                                            </button>
                                        </div> */}

                      {showSuccessPopup && (
                        <div className="success-popup-container">
                          <div className="success-popup">
                            <h3>Successful</h3>
                            <p>Your OTP's have been verified successfully</p>
                            <Link
                              to="/MerchantInfoForm"
                              className="submit-button"
                            >
                              Okay
                            </Link>
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* <ResponsiveAppBar /> */}
      <form
        onSubmit={handleFormSubmit}
        className="form-container-email email-form-onboard d-none"
      >
        <div className="email-parent d-flex email-form-parent border">
          <div className="col-lg-5">
            <div className="login-left  text-center">
              <div className="login-left-head">
                <img src={Logo} alt="logo" className="Logo" />
                <h1 className="title">Registration</h1>
                <p className="mb-0"></p>
              </div>
            </div>
          </div>

          {/* <img src={logo} alt="logo_icon" className="main-logo-email" /> */}
          <div className="col-lg-7">
            <div className="login-right p-md-10 p-3 ">
              <div className="mb-md-5 mb-3 login-right-head email-merchant-title">
                <h2 className="title list-unstyled">Registration.</h2>
                <p className="mb-0">Welcome! Please Create your account.</p>
              </div>
              <div className="form-field-email email-form-child">
                <label htmlFor="email">E-mail ID:</label>
                <input
                  type="email"
                  id="email_form_input"
                  name="email"
                  onKeyDown={(e) => {
                    if (e.key === " ") {
                      e.preventDefault();
                    }
                  }}
                  placeholder="E-mail ID"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={
                    formik.touched.email && formik.errors.email ? "error" : ""
                  }
                />
                {formik.touched.email && formik.errors.email && (
                  <span className="error-message">{formik.errors.email}</span>
                )}
                {apiResponse && apiResponse.statusCode === 200 && (
                  <button
                    type="button"
                    className="resend-otp-button-email"
                    onClick={handleSendOTP}
                  >
                    Resend OTP
                  </button>
                )}
                {apiResponse &&
                  apiResponse.statusCode === 200 &&
                  formik.values.email && (
                    <>
                      <input
                        type="text"
                        // id="emailOTP"
                        id="email_form_input"
                        name="emailOTP"
                        onKeyDown={(e) => {
                          if (e.key === " ") {
                            e.preventDefault();
                          }
                        }}
                        value={formik.values.emailOTP}
                        onChange={handleOTPChange}
                        onBlur={formik.handleBlur}
                        className={
                          formik.touched.emailOTP && formik.errors.emailOTP
                            ? "error"
                            : ""
                        }
                        placeholder="Enter OTP"
                      />
                      {otpCorrect ? (
                        <p className="otp_message">Entered OTP is correct</p>
                      ) : (
                        <p className="otp_message">Please Enter OTP</p>
                      )}
                    </>
                  )}
                {formik.touched.emailOTP && formik.errors.emailOTP && (
                  <span className="error-message">
                    {formik.errors.emailOTP}
                  </span>
                )}
              </div>

              <div className="form-field-email email-form-child">
                <label htmlFor="mobileNumber">Mobile Number:</label>
                <input
                  type="text"
                  // id="mobileNumber"
                  id="email_form_input"
                  name="mobileNumber"
                  onKeyDown={(e) => {
                    if (e.key === " ") {
                      e.preventDefault();
                    }
                  }}
                  placeholder="Mobile Number"
                  value={formik.values.mobileNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={
                    formik.touched.mobileNumber && formik.errors.mobileNumber
                      ? "error"
                      : ""
                  }
                />
                {formik.touched.mobileNumber && formik.errors.mobileNumber && (
                  <span className="error-message">
                    {formik.errors.mobileNumber}
                  </span>
                )}
                {apiResponse && apiResponse.statusCode === 200 && (
                  <button
                    type="button"
                    className="resend-otp-button-email"
                    onClick={handleSendOTP}
                  >
                    Resend OTP
                  </button>
                )}
                {apiResponse && formik.values.mobileNumber && (
                  <>
                    <input
                      type="text"
                      // id="mobileOTP"
                      id="email_form_input"
                      onKeyDown={(e) => {
                        if (e.key === " ") {
                          e.preventDefault();
                        }
                      }}
                      name="mobileOTP"
                      value={formik.values.mobileOTP}
                      onChange={handleOTPChangeMobile}
                      onBlur={formik.handleBlur}
                      className={
                        formik.touched.mobileOTP && formik.errors.mobileOTP
                          ? "error"
                          : ""
                      }
                      placeholder="Enter OTP"
                    />
                    {mobileOtpCorrect ? (
                      <p className="otp_message">Entered OTP is correct</p>
                    ) : (
                      <p className="otp_message">Please Enter OTP</p>
                    )}

                    {formik.touched.mobileOTP && formik.errors.mobileOTP && (
                      <span className="error-message">
                        {formik.errors.mobileOTP}
                      </span>
                    )}
                  </>
                )}
                {formik.touched.mobileOTP && formik.errors.mobileOTP && (
                  <span className="error-message">
                    {formik.errors.mobileOTP}
                  </span>
                )}
              </div>

              <button
                type="button"
                className="send-otp-button-email-form"
                onClick={handleSendOTP}
              >
                Send OTP
              </button>
            </div>

            {showSuccessPopup && (
              <div className="success-popup-container">
                <div className="success-popup">
                  <h3>Successful</h3>
                  <p>Your OTP's have been verified successfully</p>
                  <Link to="/MerchantInfoForm" className="submit-button">
                    Okay
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default EmailForm;