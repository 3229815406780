import { configureStore } from "@reduxjs/toolkit";
import reportSlice from "./slices/reportSlice";
import onBoardSlice from "./slices/onBoardSlice";
const store = configureStore({
  reducer: {
    report: reportSlice,
    onBoard: onBoardSlice,
  },
});

export default store;
