import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
 
  getViewResponseByTxnAndRefId,
} from "../../services/apiService";
import jwtDecode from "jwt-decode";
import { MDBDataTable } from "mdbreact";
import "../../stylesheet/ViewDetails.css";
import { Card, Col, Container, Row } from "react-bootstrap";
import swal from "sweetalert";

const ViewPayoutResponse = ({referenceId, txnId, toggleViewResponseModal}) => {
  const [records, setRecords] = useState([]);
  const [recordsData, setRecordsData] = useState([]);

  const tokenValue = localStorage.getItem("token");
  const token = tokenValue ? tokenValue.slice(1, -1) : "";
  const decodedToken = jwtDecode(token);
  const { mid } = decodedToken;

  const navigate = useNavigate();

  // const { txnId, referenceId } = useParams();

  const payload = {
    referenceId: referenceId,
    txnId: txnId,
  };
  console.log(payload);

  const fetchData = async () => {
    try {
      const response = await getViewResponseByTxnAndRefId(payload, token);

      console.log("response", response);

      if (response.statusCode === 200) {
        setRecords(response?.data);
        console.log(records);
        setRecordsData(response?.data?.data[0]);
      } else if (response.statusCode === 304) {
        swal({
          title: "Alert!",
          text: "No Data Found",
          icon: "error",
        });
        toggleViewResponseModal()
        // navigate("/Reports");
      } else {
        swal({
          title: "Alert!",
          text: "Something Went Wrong.",
          icon: "error",
        });
        // navigate("/Reports");
        toggleViewResponseModal()
      }

      console.log(response?.data);

      //   setFilterRecords(response);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData(payload, token);
  }, []);

  const columns = [
    {
      label: "UPI ID",
      field: "upiId",
    },
    {
      label: "Customer Name",
      field: "customerName",
    },
    {
      label: "MCC",
      field: "mcc",
    },
    {
      label: "Request Time",
      field: "requestTime",
    },
    {
      label: "Response Message",
      field: "respMessge",
    },
    {
      label: "Transaction Time",
      field: "txnTime",
    },
    {
      label: "Payee VPA",
      field: "payeeVpa",
    },
    {
      label: "Terminal ID",
      field: "terminalId",
    },
    {
      label: "Response Code",
      field: "respCode",
    },
    {
      label: "UPI Transaction ID",
      field: "upiTxnId",
    },
    {
      label: "Amount",
      field: "amount",
    },
    {
      label: "Customer Reference No",
      field: "custRefNo",
    },
  ];

  console.log("GJGHHJ-----------------", records.amount);
  // Map the response data to match the columns
  //   const replaceNullWithNA = (obj) => {

  //     return {
  //         channel : obj.channel|| "NA",
  //         amount : obj.amount|| "NA",
  //         extTransactionId : obj.extTransactionId|| "NA",
  //         channel : obj.channel|| "NA",
  //         channel : obj.channel|| "NA",
  //         channel : obj.channel|| "NA",
  //         channel : obj.channel|| "NA",
  //         channel : obj.channel|| "NA",
  //         channel : obj.channel|| "NA",
  // }
  //   };

  //     console.log(records)
  //     // const mappedData =  records || [];
  //     const rows = replaceNullWithNA(records)
  //     console.log(rows)

  //   // Create the data object for MDBDataTable
  //   const dataForTable = {
  //     columns: columns,
  //     rows: [rows],
  //   };

  return (
    <section className="admin-view-details">
      
        
          <Card.Body>
            <Row>
              {/* <Col xs={12} xl={12} lg={12} sm={12}>
									<Card.Title className="h3 mb-4">
										Submitted Applications Details
									</Card.Title>
								</Col> */}
              <Col xs={12} xl={12} lg={12} sm={12}>
                <div className="two-column-layout">
                  <div className="vertical-table ">
                    {/* <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">Amount</div>
                    <div className="vertical-table-cell-value text-left">{records.amount}</div>
                    </div>
                    <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">Amount</div>
                        <div className="vertical-table-cell-value text-left">{records.amount}</div>
                    </div> */}
                    <div className="vertical-table-row ">
                      <div className="vertical-table-cell">Customer Name</div>
                      <div className="vertical-table-cell-value">
                        {records.beneficiaryName ||
                          recordsData?.beneficiaryName}
                      </div>
                    </div>
                    {/* <div className="vertical-table-row">
                    <div className="vertical-table-cell">Error code</div>
                    <div className="vertical-table-cell-value">{records.errCode}</div>
                </div> */}
                    <div className="vertical-table-row">
                      <div className="vertical-table-cell text-left">
                        Bank Reference Number
                      </div>
                      <div className="vertical-table-cell-value text-left">
                        {records.bankReferenceNumber ||
                          recordsData?.bankReferenceNumber}
                      </div>
                    </div>
                    
                    <div className="vertical-table-row" >
                      <div className="vertical-table-cell text-left">
                        Payout Status Transaction ID
                      </div>
                      <div className="vertical-table-cell-value text-left" style={{overflowX: "scroll"}}>
                        {records.payoutStatusCheckTxnId ||
                          recordsData?.payoutStatusCheckTxnId}
                      </div>
                    </div>

                    <div className="vertical-table-row">
                      <div className="vertical-table-cell text-left">
                        Provider Response
                      </div>
                      <div className="vertical-table-cell-value text-left">
                        {records.providerResponse ||
                          recordsData?.providerResponse}
                      </div>
                    </div>

                    <div className="vertical-table-row">
                      <div className="vertical-table-cell text-left">
                        Reference ID
                      </div>
                      <div className="vertical-table-cell-value text-left">
                        {records.referenceId || recordsData?.referenceId}
                      </div>
                    </div>

                    {/* <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">checksum</div>
                    <div className="vertical-table-cell-value text-left">{records.checksum || "NA"}</div>
                </div> */}
                    {/* <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">custRefNo</div>
                    <div className="vertical-table-cell-value text-left">{records.custRefNo || "NA"}</div>
                </div> */}
                    <div className="vertical-table-row">
                      <div className="vertical-table-cell text-left">
                        Transaction ID
                      </div>
                      <div className="vertical-table-cell-value text-left" style={{overflowX: "scroll"}}>
                        {records.txnId}
                      </div>
                    </div>
                    <div className="vertical-table-row">
                      <div className="vertical-table-cell text-left">
                        Transfer Type
                      </div>
                      <div className="vertical-table-cell-value text-left">
                        {records.transferType}
                      </div>
                    </div>

                    <div className="vertical-table-row">
                      <div className="vertical-table-cell text-left">
                        Status
                      </div>
                      <div className="vertical-table-cell-value text-left">
                        {records.status || recordsData.respMessge}
                      </div>
                    </div>

                    {/* <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">extTransactionId</div>
                    <div className="vertical-table-cell-value text-left">{records.extTransactionId || "NA"}</div>
                </div> */}
                    {/* <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">RRN Number</div>
                    <div className="vertical-table-cell-value text-left">{records.rrn || recordsData?.custRefNo}</div>
                </div> */}
                    {/* <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">Payee VPA</div>
                    <div className="vertical-table-cell-value text-left">{records.payeeVpa || recordsData?.payeeVpa}</div>
                </div> */}
                    {/* <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">Status</div>
                    <div className="vertical-table-cell-value text-left">{records.status || recordsData.respMessge}</div>
                </div> */}

                    {/* <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">Transaction Id</div>
                    <div className="vertical-table-cell-value text-left">{records.txnId }</div>
                </div> */}

                    {/* <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">Request time</div>
                    <div className="vertical-table-cell-value text-left">{recordsData?.requestTime}</div>
                </div> */}

                    {/* <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">Terminal ID</div>
                    <div className="vertical-table-cell-value text-left">{records.terminalId || recordsData?.terminalId}</div>
                </div> */}

                    {/* <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">UPI ID </div>
                    <div className="vertical-table-cell-value text-left">{recordsData?.upiId}</div>
                </div>
                 
                <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left"> UPI Transaction ID </div>
                    <div className="vertical-table-cell-value text-left">{recordsData?.upiTxnId}</div>
                </div>
                 
                <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">Response Code </div>
                    <div className="vertical-table-cell-value text-left">{records.respCode || recordsData?.respCode}</div>
                </div> */}
                  </div>
                  {/* <div className="button-toolbar groups-btn text-end">
                    <button
                      className="btn btn-primary"
                      onClick={() => navigate("/Reports")}
                    >
                      Go Back
                    </button>
                  </div> */}
                </div>
              </Col>
            </Row>
          </Card.Body>
        
    
    </section>
  );
};

export default ViewPayoutResponse;
