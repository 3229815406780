import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import { MDBDataTable } from "mdbreact";
import jwtDecode from "jwt-decode";
import { FiDownload } from "react-icons/fi";
import swal from "sweetalert";
import ReconcialiationDate from "./ReconcialiationDate";
import {
  fetchDepositByMid,
  fetchHoldByMid,
  fetchReleaseByMid,
  getAllReconcialitionData,
  getAllWalletBalanceReport,
  getAllsettlementAdjustmentReport,
  getGuaranteeReportByMid,
  getSettlementReportByMid,
  getWithdrawRequestByMid,
  viewEkycReport,
} from "../../services/apiService";
import {
  columnForDepositTable,
  columnForEKYCTable,
  columnForPayoutTable,
  columnForWithdrawTable,
  columnForSattlementTable,
  columnForLedgerTable,
  columnForGuaranteeTable,
  columnForReconciliationTable,
  columnForsettlementAdjustmentReport,
  columnForReleaseReport,
  columnforWalletBalance,
  columnforHoldAmount,
  columnforDepositAmount,
} from "../../utils/constant";

const TableForReports = ({
  reportType,
  setShowLoader,
  responseData,
  setCustomMultiApiResponse,
}) => {
  const [tableData, setTableData] = useState([]);
  const [isShowTotalAmountVisible, setIsShowTotalAmountVisible] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [error, setError] = useState("");
  
  const token = localStorage.getItem("token")?.slice(1, -1);
  const { mid } = jwtDecode(token);

  const fetchData = async () => {
    try {
      setShowLoader(true);
      const response = await viewEkycReport({ mid }, token);
      setShowLoader(false);

      const finalres = response.data.map((item) => ({
        id: item.id || "NA",
        requestFor: item.requestFor || "NA",
        requestDateAndTime: item.requestDateAndTime || "NA",
        requestParameter: "VERIFIED",
        merchantId: item.merchantId || "NA",
        responseParameter: item.responseParameter || "NA",
        amount: item.amount || "NA",
      }));

      setTableData(finalres);
    } catch (error) {
      setError("No Data Found");
      setTableData([]);
    }
  };

  const fetchDataForWithdraw = async () => {
    try {
      setShowLoader(true);
      const response = await getWithdrawRequestByMid(mid, token);
      setShowLoader(false);

      const finalres = response.data.map((item) => ({
        utr: item.utr || "",
        amount: item.amount || "",
        transferType: item.transferType || "",
        virtualAccountType: item.virtualAccountType || "",
        status: item.status.toUpperCase() || "",
        createTime: item.createTime || "",
        transactionDate: item.transactionDate || "",
        createDate: item.createDate || "",
      }));

      setCustomMultiApiResponse(finalres);
      setTotalAmount(
        calculateWithdrawTotalAmount(finalres.filter(item => item.status === "SUCCESS")).toFixed(2)
      );
    } catch (error) {
      setError("No Data Found");
      setCustomMultiApiResponse([]);
    }
  };

  const calculateWithdrawTotalAmount = (data) => {
    return data.reduce((total, item) => total + parseFloat(item.amount), 0);
  };

  const fetchDataForsettlement = async () => {
    try {
      setShowLoader(true);
      const response = await getSettlementReportByMid(mid, token);
      setShowLoader(false);

      const finalres = response.data.map((item) => ({
        netAmount: item.netAmount || "",
        createDate: item.createDate || "",
        createTime: item.createTime || "",
        orderNo: item.orderNo || "",
        txnId: item.txnId || "",
        vpa: item.vpa || "",
        serviceChanrge: item.serviceChanrge || "",
        serviceChargeAmount: item.serviceChargeAmount || "",
        gstAmount: item.gstAmount || "",
        remark: item.remark || "",
        payinAmount: item.payinAmount || "",
        collectionMethod: item.collectionMethod || "",
        terminalId: item.terminalId || "",
      }));

      setCustomMultiApiResponse(finalres);
      setTotalAmount(
        calculateSettlementTotalAmount(finalres).toFixed(2)
      );
    } catch (error) {
      setError("No Data Found");
      setCustomMultiApiResponse([]);
    }
  };

  const calculateSettlementTotalAmount = (data) => {
    return data.reduce((total, item) => total + parseFloat(item.netAmount), 0);
  };

  const fetchDataForGuarantee = async () => {
    try {
      setShowLoader(true);
      const response = await getGuaranteeReportByMid(mid, token);
      setShowLoader(false);

      const finalres = response.data.map((item) => ({
        netAmount: item.netAmount || "NA",
        guarantyAmount: item.guarantyAmount || "NA",
        transactionType: item.transactionType.toUpperCase() || "NA",
        transactionDate: item.transactionDate || "NA",
        utrNumber: item.utrNumber || "NA",
      }));

      setCustomMultiApiResponse(finalres);
    } catch (error) {
      setError("No Data Found");
      setTableData([]);
      setCustomMultiApiResponse([]);
    }
  };

  const fetchDataForRelease = async () => {
    try {
      setShowLoader(true);
      const response = await fetchReleaseByMid(mid, token);
      setShowLoader(false);

      if (response.statusCode === 200) {
        const finalres = response.data.map((item) => ({
          mid: item.mid || "NA",
          amount: item.amount || "NA",
          releaseFrom: item.releaseFrom.toUpperCase() || "NA",
          createDate: item.createDate || "NA",
          createTime: item.createTime || "NA",
        }));

        setTableData(finalres);
      }
    } catch (error) {
      setError("No Data Found");
    }
  };

  const handleViewButtonClick = () => {
    swal({
      title: "Alert!",
      text: "Service not activated yet.",
      icon: "warning",
    });
  };

  const fetchAllReconciliationData = async () => {
    try {
      setShowLoader(true);
      const response = await getAllReconcialitionData(token);
      setShowLoader(false);

      const finalres = response?.data?.map((item) => ({
        date: item.date || "",
        switchRrn: item.switchRrn || "",
        extId: item.extId ,
        amount: item.amount || "",
        transactionStatus: item.transactionStatus || "",
        payerVpa: item.payerVpa || "",
       
        action: (
          <>
            <button
              className="p-2 border bg-success text-white rounded-2"
              onClick={handleViewButtonClick}
            >
              Accept
            </button>
            <button
              className="p-2 border bg-danger text-white rounded-2"
              onClick={handleViewButtonClick}
            >
              Deny & Refund
            </button>
          </>
        ),
      }));

      setCustomMultiApiResponse(finalres);
      setTotalAmount(
        calculateReconcillationTotalAmount(finalres).toFixed(2)
      );
    } catch (error) {
      setError("No Data Found");
    }
  };

  const calculateReconcillationTotalAmount = (data) => {
    const successTransactions = data.filter(
      (item) => item.switchMsg.toUpperCase() === "SUCCESS"
    );
    return successTransactions.reduce(
      (total, item) => total + parseFloat(item.amount),
      0
    );
  };

  const fetchAllsettlementAdjustmentReport = async () => {
    try {
      setShowLoader(true);
      const response = await getAllsettlementAdjustmentReport(mid, token);
      setShowLoader(false);

      const finalres = response?.data?.map((item) => ({
        date: item.settlmentDate || "",
        txnStatus: item.txnStatus || "",
        createDate: item.createDate || "",
        mid: item.mid || "",
        netAmount: item.netAmount || "",
        remark: item.remark || "",
      }));

      setTableData(finalres);
    } catch (error) {
      setError("No Data Found");
    }
  };

  const fetchDataForWalletBalance = async () => {
    try {
      setShowLoader(true);
      const response = await getAllWalletBalanceReport(mid, token);
      setShowLoader(false);

      if (response.statusCode === 200) {
        const finalres = response.data.map((item) => ({
          mid: item.mid || "NA",
          amount: item.amount || "NA",
          // isActive: item.isActive || "NA",
          remark: item.remark || "NA",
          status: item.status || "NA",
          utr: item.utr || "NA",
          transferMode: item.transferMode.toUpperCase() || "NA",
          createDate: item.createDate || "NA",
          createTime: item.createTime || "NA",
        }));

        setTableData(finalres);
      }
    } catch (error) {
      setError("No Data Found");
    }
  };
  const fetchDataForHold = async () => {
    try {
      setShowLoader(true);
      const response = await fetchHoldByMid(mid, token);
      setShowLoader(false);

      if (response.statusCode === 200) {
        const finalres = response?.data?.map((item) => ({
          mid: item.mid || "NA",
          amount: item.amount || "NA",
          holdPercentage: item.holdPercentage || "NA",
          createDate: item.createDate || "NA",
          orderNo: item.orderNo || "NA",
          txnId:item.txnId || "NA",
          acctualAmount:item.acctualAmount || "NA"

        }));

        setTableData(finalres);
      }
    } catch (error) {
      setError("No Data Found");
    }
  };
  const fetchDataForDeposit = async () => {
    try {
      setShowLoader(true);
      const response = await fetchDepositByMid(mid, token);
      setShowLoader(false);

      if (response.statusCode === 200) {
        const finalres = response?.data?.map((item) => ({
          mid: item.mid || "NA",
          amount: item.amount || "NA",
          orderNo: item.orderNo || "NA",
          remark: item.remark || "NA",
          date: item.date || "NA",
          depositePercentage:item.depositePercentage || "NA",
          acctualAmount:item.acctualAmount || "NA",
          txnId:item.txnId || "NA"
        }));

        setTableData(finalres);
      }
    } catch (error) {
      setError("No Data Found");
    }
  };
  useEffect(() => {
    switch (reportType) {
      case "WithdrawReport":
        fetchDataForWithdraw();
        setIsShowTotalAmountVisible(true);
        break;
      case "eKycReport":
        fetchData();
        setIsShowTotalAmountVisible(false);
        break;
      case "settlement":
        fetchDataForsettlement();
        setIsShowTotalAmountVisible(true);
        break;
      case "GuaranteeReport":
        fetchDataForGuarantee();
        setIsShowTotalAmountVisible(false);
        break;
      case "reconciliationReport":
        fetchAllReconciliationData();
        setIsShowTotalAmountVisible(true);
        break;
      case "settlementAdjustmentReport":
        fetchAllsettlementAdjustmentReport();
        setIsShowTotalAmountVisible(false);
        break;
      case "releaseReport":
        fetchDataForRelease();
        setIsShowTotalAmountVisible(false);
        break;
      case "walletBalanceReport":
        fetchDataForWalletBalance();
        setIsShowTotalAmountVisible(false);
        break;
        case "holdReport":
        fetchDataForHold()
        setIsShowTotalAmountVisible(false);
         break;
         case "depositReport":
        fetchDataForDeposit()
        setIsShowTotalAmountVisible(false);
        break;
      default:
        setTableData([]);
    }
  }, [reportType]);

  const columnsMap = {
    DepositeReport: columnForDepositTable,
    PayoutReport: columnForPayoutTable,
    WithdrawReport: columnForWithdrawTable,
    eKycReport: columnForEKYCTable,
    settlement: columnForSattlementTable,
    LedgerReport: columnForLedgerTable,
    GuaranteeReport: columnForGuaranteeTable,
    reconciliationReport: columnForReconciliationTable,
    settlementAdjustmentReport: columnForsettlementAdjustmentReport,
    releaseReport: columnForReleaseReport,
    walletBalanceReport:columnforWalletBalance,
    holdReport:columnforHoldAmount,
    depositReport:columnforDepositAmount
  };

  const column = columnsMap[reportType];
  const isArrayOfObjects = (data) => Array.isArray(data) && data.every((item) => typeof item === "object");

  const RowData = (responseData, reportType) => {
    return responseData?.map((item) => (reportType === "reconciliationReport"
      ? { ...item,
        extId: item.extId ? item.extId.split("x")[1] : "",
       action: (<>
        <button
          className="p-2 border bg-success text-white rounded-2"
          onClick={handleViewButtonClick}
        >
          Accept
        </button>
        <button
          className="p-2 border bg-danger text-white rounded-2"
          onClick={handleViewButtonClick}
        >
          Deny & Refund
        </button>
      </>)}
      : { ...item }));
  };

  const data = {
    columns: column,
    rows: (reportType === "settlement" ||
      reportType === "WithdrawReport" ||
      reportType === "chargeback" ||
      reportType === "reconciliationReport" ||
      reportType === "GuaranteeReport")
      ? isArrayOfObjects(responseData) ? RowData(responseData, reportType) : []
      : isArrayOfObjects(tableData) ? tableData : [],
  };

  const DownloadData = tableData?.map((item) => ({
    Date: item.date || "",
    "UTR Number": item.switchRrn || "",
    "Transaction Id": item.transactionId || "",
    Amount: item.amount || "",
    "Transaction Status": item.transactionStatus || "",
    "Payer VPA / UPI": item.payerVpa || "",
  }));

  function handleDownloadExcel(DownloadData) {
    try {
      const apiData = DownloadData;
      const headerLabels = Object.keys(apiData[0]);
      const apiRows = apiData?.map((item) => Object.values(item));
      const dataWithHeader = [headerLabels, ...apiRows];
      const csv = Papa.unparse(dataWithHeader);
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "Report.csv";
      link.style.display = "none";

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  return (
    <div key={reportType}>
      <div className=" ">
        {reportType === "reconciliationReport" ? <ReconcialiationDate /> : ""}
      </div>
      <MDBDataTable
        className=" dataTable overflow-x-scroll"
        footer="none"
        data={data}
        striped
        bordered
        entries={50}
        exportToCSV
        responsive
        theadColor="#333"
      />
      <div className="d-flex align-items-center justify-content-between mt-3">
        {reportType === "releaseReport" ? (
          ""
        ) : (
          <button
            className="advanced-btn btn btn-primary text-capitlize mt-2"
            onClick={() => handleDownloadExcel(
              reportType === "settlement" ||
              reportType === "WithdrawReport" ||
              reportType === "chargeback" ||
              reportType === "GuaranteeReport" ||
              reportType === "reconciliationReport"
                ? responseData
                : tableData
            )}
          >
            <FiDownload className="d-none" />
            <i className="bi bi-arrow-down-circle"></i> Download CSV
          </button>
        )}
        {isShowTotalAmountVisible ? (
          <div className="d-flex align-items-center justify-content-between w-25 gap-4 border boeder-1 p-2 ">
            <label className="fs-4 w-50">Total Amount</label>
            <span className="fs-4 fw-bold w-50 border boeder-2 rounded-2 p-1">
              {totalAmount}
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default TableForReports;
