import React, { useState } from "react";
import InactiveServices from "../../common/InactiveServices";
// new import link
import "../../stylesheet/Reports.css";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import WithdrawSettlementSetting from "./settting/WithdrawSettlementSetting";
 

const MerchantSetting = () => {
    const [activeButton, setactiveButton] = useState(1);
    return (
        <>
            <section className="dashboard-header-sec merchant-settings-sec">
                <Container>
                    <div className="ds-header-sec">
                        <Row>
                            <Col xs={12} lg={12} md={12}>
                                <Card className="shadow border-0 mb-4 ds-header-card">
                                    <Card.Body>
                                        {/* <Card.Title className="h3">Reports</Card.Title>  */}
                                        <div className="merchant-tab-btn">
                                            <ul className="report-link nav-report-btn">
                                                <li className={activeButton === 1 ? "active" : ""} onClick={() => setactiveButton(1)}>
                                                    Fraud Management
                                                </li>
                                                <li className={activeButton === 2 ? "active" : ""} onClick={() => setactiveButton(1)}>
                                                    User Management
                                                </li>
                                                <li className={activeButton === 3 ? "active" : ""} onClick={() => setactiveButton(1)}>
                                                    Security Mangement
                                                </li>
                                                <li className={activeButton === 4 ? "active" : ""} onClick={() => setactiveButton(1)}>
                                                    Settlement
                                                </li>
                                                <li className={activeButton === 5 ? "active" : ""} onClick={() => setactiveButton(1)}>
                                                    Notifications
                                                </li>
                                                <li className={activeButton === 6 ? "active" : ""} onClick={() => setactiveButton(6)}>
                                                    Withdraw
                                                </li>
                                            </ul>
                                        </div>
                                    </Card.Body>
                                </Card>
                                {/* ./card */}
                            </Col>

                            <Col xs={12} lg={12} md={12}>
                                <Card className="shadow border-0 mb-4">
                                    <Card.Body>
                                        <div className="merchant-report-view">
                                            {activeButton === 6 ? <InactiveServices  /> : <InactiveServices />}
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default MerchantSetting;