import axios from "axios";
import jwtDecode from "jwt-decode";
import React from "react";
import { useState, useEffect } from "react";
import { Button, Card, Col, Container } from "react-bootstrap";
import { SWIPELINC_API } from "../../utils/constant";
import WithdrawInPayout from "./WithdrawInPayout";
import swal from "sweetalert";
import PendingWithdrawRequest from "./PendingWithdrawRequest";

const WithdrawInAccountOrPayout = ({ activeButton, getReq ,setGetReq}) => {
  const [amount, setAmount] = useState("");
  const [error, setError] = useState(null);
  const [transferType, setTransferType] = useState("account");
  const [virtualAccountType, setVirtualAccountType] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
   

  useEffect(() => {
    if (activeButton === 2) {
      setTransferType("payout");
    } else if (activeButton === 1) {
      setTransferType("account");
    }else if(activeButton === 3) {
      setTransferType("pendingWithdraw")
    } else {
      setTransferType("");
    }
    console.log(transferType);
    setAmount(' ')
  }, [activeButton]);

  // const handleModalClose = () => {
  //     setShow2(false);
  // }
  const tokenValue = localStorage.getItem("token");
  const token = tokenValue ? tokenValue.slice(1, -1) : null;
  const decodedToken = jwtDecode(token);
  const { mid } = decodedToken;
  console.log(mid);

  const Payload = {
    mid: mid,
    amount: amount,
    transferType: transferType,
    virtualAccountType: virtualAccountType,
  };

  const handleSubmit = async () => {
    if (isSubmitting || amount.length === 0) {
      // Prevent multiple submissions while one is in progress
      return;
    }
  
    // Show a confirmation dialog
    swal({
      title: "Are you sure?",
      text: "Do you want to submit this withdrawal request?",
      icon: "info",
      buttons: true,
      dangerMode: true,
    }).then(async (willSubmit) => {
      if (willSubmit) {
        // User confirmed, submit the request
        try {
          const response = await axios.post(
            `${SWIPELINC_API}/MerchantPanel/merchant/withdrawal/save`,
            Payload,
            {
              headers: {
                Authentication: "Bearer " + token,
              },
            }
          );
            
          if(response.data.statusCode === 309) {
            swal({
              title: "Alert",
              text: response.data.message,
              icon: "error",
            });
            setAmount("");
            return 
          }

          if (response.data.statusCode === 200) {
            swal({
              title: "Success",
              text: "Your Withdrawal request has been submitted successfully.",
              icon: "success",
            });
            setAmount("");
            setGetReq(!getReq)
          } else {
            swal({
              title: "Failed",
              text: "Your Withdrawal request has been declined.",
              icon: "warning",
            });
          }
        } catch (error) {
          setError(true);
          swal({
            title: "Failed",
            text: "Something went wrong",
            icon: "error",
          });
        } finally {
          setIsSubmitting(false);
        }
      } else {
        // User canceled the submission
        swal("Submission canceled!");
      }
    });
  };
  

  const handleAmount = (e) => {
    const value = e.target.value;

    const numberPattern = /^[1-9]\d*$/; // Updated pattern to disallow 0 and negative numbers

    if (numberPattern.test(value)) {
      setAmount(value);
      setError("");
    } else {
      setAmount("");
      setError("Withdraw Amount must be a positive number.");
    }
  };

  return (
    <div>
      {transferType === "account" ? (
        // <div className="filter-field-area">
        //   <div className="form-group mb-3 d-flex flex-column align-items-center   ">
        //     <div className="w-50">
        //       <label className="bg-dark text-white rounded-3 border-none w-100 pl-5 p-2">
        //         Enter Amount
        //       </label>
        //       <input
        //         className="advanced-input-box form-control w-100"
        //         type="number"
        //         value={amount}
        //         onChange={handleAmount}
        //         placeholder="Enter Amount"
        //       />
        //     </div>
        //     <Button
        //       variant="outline-primary"
        //       size="sm"
        //       className="text-capitlize mt-2 mb-2 justify-self-end d-flex gap-2 justify-content-center align-items-center"
        //       onClick={handleSubmit}
        //     >
        //       Submit <i class="bi bi-arrow-right"></i>
        //     </Button>
        //   </div>
        // </div>

        <section className="lincpay-dashboard-sec merchant-admin-info-form aadharform-sec">
        <Container>
          <div className="cart-view-sec mt-4">
            
              <Col xs={12} lg={12} md={12}>
                {/* Tab content start here */}
                {/* <Card className="shadow-cst border-0 mb-3 chart-boxes merchant-info-form-sec"> */}
                  <Card.Body>
                    <div className="merchant-form-info d-flex justify-content-center">
                      
                      <Col xs={12} lg={4} md={6}>
                              <div className="form-group">
                                <label
                                  htmlFor="companyPanNumber"
                                  className="form-label"
                                >
                                 Enter Amount
                                  <sup className="text-danger">*</sup>
                                </label>
                                <div className="input-field-validate">
                                  <input
                                    type="number"
                                    value={amount}
                                    onChange={handleAmount}
                                    className="form-control"
                                  />

                                  <div className="login-btn-sec">
                                    <button
                                      id="companyPanVerifyBtn"
                                      type="button"
                                      className="btn btn-primary"
                                      onClick={handleSubmit}
                                    >
                                     Submit <i class="bi bi-arrow-right"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </Col>
                      
                    </div>
                  </Card.Body>
                {/* </Card> */}
                {/* Tab content end here */}
              </Col>
              {/* ./col */}
          
          </div>
        </Container>
      </section>
        
      ) : transferType === "pendingWithdraw" ? <PendingWithdrawRequest /> : (
        <WithdrawInPayout transferType={transferType} />
      )}
    </div>
  );
};

export default WithdrawInAccountOrPayout;
