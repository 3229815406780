import axios from "axios";
import jwtDecode from "jwt-decode";
import React, { useState } from "react";
import { FIND_BY_ORDER_NO, FIND_BY_TRANSATION_ID } from "../../utils/constant";
import { MDBDataTable } from "mdbreact";
import swal from "sweetalert";

const PayoutTXNStatus = ({setShowLoader}) => {
  const [selectedOption, setSelectedOption] = useState("Reference Number"); // Initialize with a default option
  const [inputValue, setInputValue] = useState("");
  const [data1, setData1] = useState([]);
  const [error1, setError1] = useState("");
  const [submittedValue, setSubmittedValue] = useState("");

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  
  
  const handleSubmit = async (setData1) => {
    const tokenValue = localStorage.getItem("token");
    const token = tokenValue ? tokenValue.slice(1, -1) : null;
    const decodedToken = jwtDecode(token);
    const { mid } = decodedToken;
  
    const Payload = {
      referenceId: selectedOption=== "Reference Number" ? inputValue : '',
      txnId: selectedOption=== "Transaction ID" ? inputValue : ''
    };
    try {
      setShowLoader(true);
      const response = await axios.post(FIND_BY_TRANSATION_ID, Payload, {
        headers: {
          Authentication: "Bearer " + token,
        },
      });
      if(response.data.statusCode === 200){
        setShowLoader(false);
        setData1(response?.data?.data );
        console.log( "----------",response?.data?.data);
        setInputValue('')
      }else if(response.data.statusCode === 304){
        setShowLoader(false);

        swal({
          title: "Alert!",
          text: "Data Not Found",
          icon: "warning",
        })
      }
       
        
    } catch (error) {
      setError1(true);
      swal({
        title: "Alert!",
        text: "Something Went Wrong",
        icon: "error",
      })
      console.error(error);
    }
    
  };

  const column = [
    // {
    //   label: "Customer Name",
    //   field: "beneficiaryName",
    //   sort: "asc",
    //   width: "auto",
    // },
    {
      label: "Transaction ID",
      field: "txnId",
      sort: "asc",
      width: "850px",
    },
    {
      label: "Transfer Type",
      field: "transferType",
      sort: "asc",
      width: "850px",
    },
    // {
    //   label: "bankReferenceNumber",
    //   field: "bankReferenceNumber",
    //   sort: "asc",
    //   width: "850px",
    // },

    {
      label: " Response",
      field: "providerResponse",
      sort: "asc",
      width: "auto",
    },
     

    {
      label: "Reference ID",
      field: "referenceId",
      sort: "asc",
      width: "auto",
    },
    {
      label: "Status",
      field: "status",
      sort: "asc",
      width: "auto",
    },
     
    
  ];

  const datas = {
    columns: column,
    rows:  [data1],
      
  };

  return (
    <div className="w-100 d-flex flex-column align-items-center align-self-center justify-content-center shadow-lg">
      <div className="container d-flex w-50 px-5 justify-content-evenly align-items-center mt-5">
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            style={{border:"1px solid #000"}}
            name="options"
            value="Reference Number"
            checked={selectedOption === "Reference Number"}
            onChange={handleOptionChange}
          />
          <label className="form-check-label">Reference Number</label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input "
            style={{border:"1px solid #000"}}
            type="radio"
            name="options"
            value="Transaction ID"
            checked={selectedOption === "Transaction ID"}
            onChange={handleOptionChange}
          />
          <label className="form-check-label">Transaction ID</label>
        </div>
      </div>

      {selectedOption && (
        <div className="gap-1 w-50 mt-5 mb-5">
          
          <div className="d-flex align-items-center justify-content-around gap-3">
            <input
              type="text"
              className="form-control mt-3"
              placeholder={`Enter ${selectedOption}`}
              value={inputValue}
              onChange={handleInputChange}
            />

            <button className="btn btn-primary mt-3" onClick={()=>handleSubmit(setData1)}>
              Submit
            </button>
          </div>
        </div>
      )}
      
      <div className="w-100 container">
        <MDBDataTable
          className=" dataTable overflow-x-scroll"
          data={datas}
          footer="none"
          striped
          bordered
          exportToCSV
          responsive
          theadColor="#333"

          // small
        />
      </div>
        
    </div>
  );
};

export default PayoutTXNStatus;