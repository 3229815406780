import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import { Card, Col, Row, Container } from "react-bootstrap";
import jwtDecode from "jwt-decode";
import { NavLink } from "react-router-dom";
import WithdrawInAccountOrPayout from "./WithdrawInAccountOrPayout";
import BalanceComponent from "./Balance";

const WithdrawAmount = () => {
  const [activeButton, setactiveButton] = useState(1);
  const [getReq, setGetReq] = useState(false);

  const handleToolsButtonClick = (buttonNumber) => {
    // setReportType(type);
    setactiveButton(buttonNumber);
  };

  return (
    <>
      <section className="dashboard-header-sec merchant-tool-sec">
        <Container>
          <div className="ds-header-sec">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-4 ds-header-card">
                  <Card.Body>
                    {/* <Card.Title className="h3">Reports</Card.Title>  */}
                    <div className="merchant-tab-btn d-flex justify-content-between align-items-center">
                      <ul className="report-link nav-report-btn">
                        <li
                          className={activeButton === 1 ? "active" : ""}
                          onClick={() => handleToolsButtonClick(1)}
                        >
                          To Account
                        </li>
                        <li
                          className={activeButton === 2 ? "active" : ""}
                          onClick={() => handleToolsButtonClick(2)}
                        >
                          To Payout
                        </li>

                        <li
                          className={activeButton === 3 ? "active" : ""}
                          onClick={() => handleToolsButtonClick(3)}
                        >
                          Pending Withdraws
                        </li>
                      </ul>
                      <div>
                        <BalanceComponent
                          isShowWalletBalance="true"
                          isShowIMPSOrUPI="false"
                          getReq={getReq}
                        />
                      </div>
                    </div>
                  </Card.Body>
                </Card>
                {/* ./card */}
              </Col>

              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-4">
                  <Card.Body>
                    <div className="merchant-report-view w-100">
                      <div>
                        <WithdrawInAccountOrPayout
                          activeButton={activeButton}
                         
                          getReq={getReq}
                          setGetReq={setGetReq}
                        />
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default WithdrawAmount;
