import { createSlice } from "@reduxjs/toolkit";

const reportSlice = createSlice({
  name: "report search",
  initialState: {
    startDate: "",
    amount:0,
    date:"",
    endAmount:0,
    greaterAmount:0,
    lessAmount:0,
    payerVPA:"",
    startAmount:0,
    transectionStatus:"",
    endDate: "",
    email: "",
    mobile: "",
    orderNumber: "",
    transactionId: "",
    searchValue: "",
    custRefNo: "",
    custLocation: "",
    bankLocation: "",
    paymentType: ""
  },
  reducers: {
    setStartDateValue: (state, action) => {
      state.startDate = action.payload;
    },
    setAmountSearchValue: (state, action) => {
      state.amount = action.payload;
    },
    setDateSearchValue: (state, action) => {
      state.date = action.payload;
    },
    setEndAmountSearchValue: (state, action) => {
      state.endAmount = action.payload;
    },
    setGreaterAmountSearchValue: (state, action) => {
      state.greaterAmount = action.payload;
    },
    setLessAmountSearchValue: (state, action) => {
      state.lessAmount = action.payload;
    },
    setPayerVPASearchValue: (state, action) => {
      state.payerVPA = action.payload;
    },
    setStartAmountSearchValue: (state, action) => {
      state.startAmount = action.payload;
    },
    
    setTransectionStatusSearchValue: (state, action) => {
      state.transectionStatus = action.payload;
    },
    //
    setEndDateValue: (state, action) => {
      state.endDate = action.payload;
    },
    setEmailValue: (state, action) => {
      state.email = action.payload;
    },
    setMobileValue: (state, action) => {
      state.mobile = action.payload;
    },
    setOrderNumberValue: (state, action) => {
      state.orderNumber = action.payload;
    },
    setTransactionIdValue: (state, action) => {
      state.transactionId = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setCustRefNoSearchValue: (state, action) => {
      state.custRefNo = action.payload;
    },
    setCustLocationSearchValue: (state, action) => {
      state.custLocation = action.payload;
    },
    setBankLocationSearchValue: (state, action) => {
      state.bankLocation = action.payload;
    },
    setPaymentTypeSearchValue: (state, action) => {
      state.paymentType = action.payload;
    },
  },
});

export const {
  setStartDateValue,
  setAmountSearchValue,
  setDateSearchValue,
  setEndAmountSearchValue,
  setGreaterAmountSearchValue,
  setLessAmountSearchValue,
  setPayerVPASearchValue,
  setStartAmountSearchValue,
  setTransectionStatusSearchValue,
  setEndDateValue,
  setEmailValue,
  setMobileValue,
  setOrderNumberValue,
  setTransactionIdValue,
  setSearchValue,
  setCustRefNoSearchValue,
  setCustLocationSearchValue,
  setBankLocationSearchValue,
  setPaymentTypeSearchValue
} = reportSlice.actions;

export default reportSlice.reducer;
