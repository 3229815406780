import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { getPendingWithdrawRequestByMid } from "../../services/apiService";
import { MDBDataTable } from "mdbreact";
import { columnForPendingWithdrawTable, columnForWithdrawTable } from "../../utils/constant";

const PendingWithdrawRequest = () => {
  const [apiResponse, setApiResponse] = useState([]);

  const tokenValue = localStorage.getItem("token");
  const token = tokenValue ? tokenValue.slice(1, -1) : null;
  const decodedToken = jwtDecode(token);
  const { mid } = decodedToken;

  const fetchDataForWithdraw = async (mid, token) => {
    try {
      //   setShowLoader(true);
      const response = await getPendingWithdrawRequestByMid(mid, token);
      //   setShowLoader(false);
      const finalres = response.data.map((item) => {
        return {
          // withdrawalId: item.withdrawalId !== null ? item.withdrawalId : "NA",
        
          // mid: item.mid === null || item.virtualAccountType === "" ? "NA": item.virtualAccountType  ,

          amount: item.amount === null || item.amount === "" ? "" : item.amount,
          transferType:
            item.transferType === null || item.transferType === ""
              ? ""
              : item.transferType,
          virtualAccountType:
            item.virtualAccountType === null || item.virtualAccountType === ""
              ? ""
              : item.virtualAccountType,
          status:
            item.status === null || item.status === ""
              ? ""
              : item.status.toUpperCase(),
          createTime:
            item.createTime === null || item.createTime === ""
              ? ""
              : item.createTime,
           
          // status: item.status === null || item.status === "" ? "" : item.status,
          createDate:
            item.createDate === null || item.createDate === ""
              ? ""
              : item.createDate,
        };
      });
      // setTableData(finalres);
      setApiResponse(finalres);
      console.log("finalres withdraw", finalres);
    } catch (error) {
      //   setError("No Data Found");
      // setTableData([]);
      setApiResponse([]);
    }
  };

  const data = {
    columns: columnForPendingWithdrawTable,
    rows: apiResponse
  };

  useEffect(() => {
    fetchDataForWithdraw(mid, token);
  }, []);

  return (
    <>
      <MDBDataTable
        className=" dataTable overflow-x-scroll"
        footer="none"
        data={data}
        striped
        bordered
        entries={50}
        exportToCSV
        responsive
        theadColor="#333"
        // small
      />
    </>
  );
};

export default PendingWithdrawRequest;
