import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
// import "./MerchantInfoForm.css";
// import logo from "../../assets/images/Rectangle 13.png";
import { useDispatch, useSelector } from "react-redux";
import {
  updateFirstName,
  updateLastName,
  updateBusinessType,
  updateBusinessTypeId,
  updateCompanyName,
  updateCompanyPanNumber,
  updateBusinessAs,
  updateWebsite,
  updateBusinessCategory,
  updateBusinessCategoryId,
  updateGstNumber,
  updateOtherBusinessCategory,
  updateGstVerified,
  updateCompanyPanNumberVerified,
} from "../../store/slices/onBoardSlice";
import { Link, useNavigate } from "react-router-dom";
import ResponsiveAppBar from "./ResponsiveAppBar";

import "../../stylesheet/MerchantForm.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { SWIPELINC_API } from "../../utils/constant";
import styles from "./AdharForm.module.css";

const MerchantInfoForm = () => {
  const navigate = useNavigate();
  
  const [businessCategories, setBusinessCategories] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupPan, setShowPopupPan] = useState(false);
  const [showPopupGst, setShowPopupGst] = useState(false);
  // const [companyPan, setShowCompanyPan] = useState();
  const [gstverifiedValue, setGstverifiedValue] = useState(false);
  const [panverifiedValue, setPanverifiedValue] = useState(false);
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [panVerify, setPanVerify] = useState();
  const [gstVerify, setGstVerify] = useState();
  const [showOtpInput, setShowOtpInput] = useState(true);

  const handleClosePopup = () => {
    setShowPopup(false);
  };
  const handleClosePopupPan = () => {
    setShowPopupPan(false);
  };
  const handleClosePopupGst = () => {
    setShowPopupGst(false);
  };
  const dispatch = useDispatch();

  

  

  

  // const initialValues = {
  //     firstName: "",
  //     lastName: "",
  //     businessType: "",
  //     companyName: "",
  //     companyPanNumber: "",
  //     businessAs: "",
  //     website: "",
  //     businessCategory: "",
  //     otherBusinessCategory: "",
  //     gstNumber: "",
  // };
  // const priviousData = useSelector((state) => state.onBoard);
  const isReturning = localStorage.getItem("returningUser") === "true";

  const priviousData = JSON.parse(localStorage.getItem("MerchantInfoForm"));

  //   console.log(priviousData);
  function getInitialValues(isReturning, priviousData) {
    if (isReturning) {
      // Returning user initial values
      return {
        firstName: priviousData?.firstName,
        lastName: priviousData?.lastName,
        businessType: priviousData?.businessType,
        companyName: priviousData?.companyName,
        companyPanNumber: priviousData?.companyPanNumber,
        businessAs: priviousData?.businessAs,
        website: priviousData?.website,
        businessCategory: priviousData?.businessCategory,
        otherBusinessCategory: priviousData?.otherBusinessCategory,
        gstNumber: priviousData?.gstNumber,
      };
    } else {
      // New user initial values
      return {
        firstName: "",
        lastName: "",
        businessType: "",
        companyName: "",
        companyPanNumber: "",
        businessAs: "",
        website: "",
        businessCategory: "",
        otherBusinessCategory: "",
        gstNumber: "",
      };
    }
  }
  const initialValues = getInitialValues(isReturning, priviousData);

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .required("First name is required")
      .matches(/^[A-Za-z]+$/, "First name must contain only letters"),
    lastName: Yup.string()
      .required("Last name is required")
      .matches(/^[A-Za-z]+$/, "Last name must contain only letters"),
    businessType: Yup.string().required("Business type is required"),
    companyName: Yup.string().required("Company name is required"),
    companyPanNumber: Yup.string()
      .required("Company Pan is required")
      .test("valid-pan-number", "Invalid PAN number", (value) => {
        if (!value) return false; // Allow empty PAN number field
        return /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/.test(value);
      }),

    // website: Yup.string()
    //   .required("Website is required")
    //   .matches(
    //     /^(www\.)?[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+$/,
    //     "Website must have a valid format (e.g., www.example.com)"
    //   ),
    businessCategory: Yup.string().required("Business category is required"),
    gstNumber: Yup.string(),
    // .test(
    //   "valid-gst-number",
    //   "Invalid GST number",
    //   (value) => {
    //     if (!value) return true; // Allow empty GST number field
    //     return /^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[0-9]{1}[A-Za-z]{2}$/.test(
    //       value
    //     );
    //   }
    // ),
  });
  const handlePanVerification = async () => {
    if (formik.values.companyPanNumber.length === 10) {
      try {
        const response = await axios.post(
          "https://merchant.lincpay.in/MerchantPanel/merchant/ekyc/panVerification",
          { panNumber: formik.values.companyPanNumber }
        );
        const data = response.data;

        setPanVerify(data);

        if (data.message === "Verified" && data.status === "Success") {
          setPanVerify(data);
          // console.log(data.status);
          setShowPopupPan(true);
          setPanverifiedValue(true);
        }

        if (data.message !== "Verified" || data.status !== "Success") {
          setPanVerify("Invalid Pan Number");
        } else {
          return false;
        }
      } catch (error) {
        console.log("Error verifying PAN number:", error);
        return false;
      }
    }
  };

  // const apiCallCountRef = useRef(0);
  const handleGstVerification = async () => {
    if (formik.values.gstNumber.length === 15) {
      // console.log({ gstNumber: formik.values.gstNumber });
      try {
        const response = await axios.post(
          "https://merchant.lincpay.in/MerchantPanel/merchant/ekyc/gstVerification",
          { gstNumber: formik.values.gstNumber }
        );
        const data = response.data;

        if (data.message === "Verified" && data.status === "Succeess") {
          setGstVerify(data);
          setShowPopupGst(true);
          setGstverifiedValue(true);
        }

        if (data.message !== "Verified" || data.status !== "Succeess") {
          setGstVerify("Invalid gst Number");
        } else {
          setGstVerify(data);
        }
      } catch (error) {
        console.log("Error verifying PAN number:", error);
        return false;
      }
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      // console.log(values);
      // if (
      //   gstVerify &&
      //   gstVerify.message === "Verified" &&
      //   gstVerify.status === "Succeess"
      //   // panVerify &&
      //   // panVerify.message === "Verified" &&
      //   // panVerify.status === "Success"
      // ) {
      dispatch(updateFirstName(values.firstName));
      dispatch(updateLastName(values.lastName));
      dispatch(updateBusinessType(values.businessType));
      dispatch(updateCompanyName(values.companyName));
      dispatch(updateCompanyPanNumber(values.companyPanNumber));
      dispatch(updateBusinessAs(values.businessAs));
      dispatch(updateWebsite(values.website));
      dispatch(updateBusinessCategory(values.businessCategory));
      dispatch(updateGstNumber(values.gstNumber));
      dispatch(updateBusinessTypeId(values.companyTypeId));
      dispatch(updateOtherBusinessCategory(values.otherBusinessCategory));
      dispatch(updateGstVerified(gstverifiedValue));
      dispatch(updateCompanyPanNumberVerified(panverifiedValue));

      // const selectedType = businessTypes.find(
      //   (type) => type.companyType === values.businessType
      // );
      // if (selectedType) {
      //   dispatch(updateBusinessTypeId(selectedType.companyTypeId));
      // }

      // const selectedCategory = businessCategories.find(
      //   (category) => category.businessType === values.businessCategory
      // );
      // if (selectedCategory) {
      //   dispatch(updateBusinessCategoryId(selectedCategory.businessTypeId));
      //   // console.log(selectedCategory.businessTypeId);
      // }

      formik.resetForm();
      // setShowPopup(true);
      navigate("/AdharForm")
      // } else if (
      //   gstVerify &&
      //   gstVerify.message !== "Verified" &&
      //   gstVerify.status !== "Succeess"
      // ) {
      //   setGstVerify("Invalid Gst Number");
      // } else if (
      //   panVerify &&
      //   panVerify.message !== "Verified" &&
      //   panVerify.status !== "Success"
      // ) {
      //   setPanVerify("Invalid Pan Number");
      // }
    },
  });

  const handleBusinessCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    // console.log(selectedCategory);
    if (selectedCategory === "OTHERS") {
      setShowOtherInput(true);
      formik.setFieldValue("otherBusinessCategory", "");
    } else {
      setShowOtherInput(false);
      formik.setFieldValue("businessCategory", selectedCategory);
    }
  };

  const handlenext = () => {

    localStorage.setItem("returningUser", "false");
    sessionStorage.setItem("returningUser", "true");
    localStorage.setItem("MerchantInfoForm", JSON.stringify(formik.values));
    
  };

  return (
    <>
      {/* <MyLoader /> */}
      <ResponsiveAppBar />
     
    </>
  );
};

export default MerchantInfoForm;