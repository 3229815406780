/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./AdhaarVerification.module.css";
import Logo from "../../assets/images/Logo.png";
import { Link, useLocation } from "react-router-dom";
import { SWIPELINC_API } from "../../utils/constant";

const AdhaarVerification = () => {
  const [aadharNumber, setAadharNumber] = useState("");
  const [aadharValue, setAadharValue] = useState();
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [verificationResult, setVerificationResult] = useState("");

  const location = useLocation();
  //   console.log(location.search);
  const queryParams = location.search;
  const idWithPrefix = queryParams.slice(4);
  const id = idWithPrefix;

  //   console.log(id);
  const handleSendOtp = async () => {
    try {
      const response = await axios.post(
        `${SWIPELINC_API}MerchantPanel/merchant/ekyc/aadhaarVerification`,
        { aadhaarNumber: aadharNumber }
      );
      //   let response = {
      //     message: "OTP sent successfully",
      //     status: "Otp sended",
      //     statusCode: 200,
      //     data: "3403189",
      //   };
      if (
        response.data.statusCode === 200 &&
        response.data.message === "Otp Send successfully"
      ) {
        setAadharValue(response.data);
        setOtpSent(true);
        setVerificationResult(""); // Reset the verification result
      }
      //   const refNumber = response.data.data;
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const response = await axios.post(
        `${SWIPELINC_API}MerchantPanel/merchant/ekyc/aadhaarOtpVerification`,
        {
          otp: otp,
          refNumber: aadharValue.data,
        }
      );

      if (
        response.data.message === "Aadhaar verfied" &&
        response.data.status === "Success"
      ) {
        const verificationStatus = response.data;
        setVerificationResult(verificationStatus);
        const apiResponse = await axios.put(
          `${SWIPELINC_API}MerchantPanel/merchant/ekyc/AadhaarUpdate`,
          {
            aadhaarVerified: true,
            registrationId: id,
          }
        );
        // console.log(apiResponse);
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
    }
  };
  //   const aadhaarverify = async () => {
  //     try {
  //       const response = await axios.put(
  //         "https://merchant.lincpay.in/MerchantPanel/merchant/ekyc/AadhaarUpdate",
  //         {
  //           aadhaarVerified: true,
  //           registrationId: "string",
  //         }
  //       );
  //       //   console.log(response);
  //     } catch (error) {}
  //   };
  //   useEffect(() => {
  //     aadhaarverify();
  //   }, [verificationResult.status === "Success"]);
  return (
    <>
      <div className={styles.containerAdhaar}>
        <div className={styles.image_div_aadhar}>
          <div className="col-lg-6">
            <div className="login-left  text-center">
              <div className="login-left-head">
                <img src={Logo} alt="logo" className="Logo" />
                <h1 className="title">Aadhaar Verification</h1>
                <p className="mb-0"></p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.Aadhaar_second_child}>
          <h1 className={styles.AdhaarVerification_link}>
            Adhaar Verification
          </h1>
          <label className={styles.labelAdhaar}>
            Adhar Number:
            <input
              type="text"
              value={aadharNumber}
              onChange={(e) => setAadharNumber(e.target.value)}
              className={styles.inputAdhaar}
            />
          </label>
          {!otpSent ? (
            <button
              onClick={handleSendOtp}
              className={styles.buttonAdhaarVerification}
            >
              Send OTP
            </button>
          ) : (
            <>
              <label className={styles.label}>
                Enter OTP:
                <input
                  type="text"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  className={styles.labelAdhaar}
                />
              </label>
              <button
                className={styles.resend_otp_adhar}
                onClick={handleSendOtp}
              >
                Resend otp
              </button>
              <button
                onClick={handleVerifyOtp}
                className={styles.buttonAdhaarVerification}
              >
                Submit
              </button>
            </>
          )}
          {verificationResult.status === "Success" && (
            <div className="success-popup-container">
              <div className="success-popup">
                <h3>Successful</h3>
                <p>Your Aadhaar has been verified successfully</p>
                <Link to="/" className="submit-button">
                  Okay
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AdhaarVerification;
