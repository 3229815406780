import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { USE_CHARGEBACK_MULTIPLE_FILTER_API as getApiData } from "../../../services/apiService";
import { useDispatch } from "react-redux";
import {
  setEmailValue,
  setMobileValue,
  setTransactionIdValue,
  setOrderNumberValue,
  setStartDateValue,
  setEndDateValue,
  setCustRefNoSearchValue,
  setPayerVPASearchValue,
} from "../../../store/slices/reportSlice";
import jwtDecode from "jwt-decode";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  isEmailIdRegexIsValid,
  isTransactionIdRegexIsValid,
  isUtrNumRegexIsValid,
  isVpaUpiRegexIsValid,
} from "../../../utils/validations";

const ChargebackAdvanceFilter = ({
  onClose,
  setIsAdvanceFilterOpen,
  setCustomApiResponse,
  setShowLoader,
}) => {
  const tokenValue = localStorage.getItem("token");
  const token = tokenValue ? tokenValue.slice(1, -1) : null;
  const decodedToken = jwtDecode(token);
  const { mid } = decodedToken;
  const [searchOption, setSearchOption] = useState("email");
  const [searchValue, setSearchValue] = useState("");
  const [apiResponse, setApiResponse] = useState(null);
  const [show, setShow] = useState(true);
  const [search, setSearch] = useState(false);

  const [searchValueError, setSearchValueError] = useState("");
  const [isSearchValueError, setIsSearchValueError] = useState(false);

  const startDate = useSelector((store) => store.report.startDate);
  const endDate = useSelector((store) => store.report.endDate);
  const storeSearchValue = useSelector((state) => state.report.searchValue);
  const dispatch = useDispatch();

  const handleModalClose = () => {
    setShow(false);
    setIsAdvanceFilterOpen(false);
    //  setSearch(!search)
  };

  const handleClick = (option) => {
    setSearchOption(option);
    setSearchValue("");
  };

  const submitHandler = () => {
    // setSearch(!search)

    getApiData(
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      searchOption === "email" ? searchValue : "",
      searchOption === "mobile" ? searchValue : "",
      searchOption === "payerVPA" ? searchValue : "",
      searchOption === "rrnNo" ? searchValue : "",
      searchOption === "TxnUID" ? searchValue : "",
      searchOption === "UPITxnID" ? searchValue : "",
      
      mid,
      token,
      setCustomApiResponse,
      setShowLoader
      // searchOption === "transactionId" ? searchValue : "",
    );

    handleModalClose();
  };

  const handelSearchData = (e) => {
    if (searchOption === "mobile") {
      const numericInput = /^\d*$/.test(e.target.value);
      if (numericInput) {
        setSearchValue(e.target.value.substring(0, 10)); // Only allow up to 10 digits
        setSearchValueError("");
      } else {
        setSearchValueError("Input must be a number.");
      }
      return;
    }

    setSearchValue(e.target.value);
    setIsSearchValueError(false);
    setSearchValueError("");
    document.getElementById("searchBtn").disabled = false;
  };

  const handelValidations = (e) => {
    if (searchOption === "payerVPA") {
      if (!isVpaUpiRegexIsValid(searchValue)) {
        setIsSearchValueError(true);
        setSearchValueError("Enter Valid VPA.");
        document.getElementById("searchBtn").disabled = true;
      }
      return;
    }


    if (searchOption === "email") {
      if (!isEmailIdRegexIsValid(searchValue)) {
        setIsSearchValueError(true);
        setSearchValueError("Enter Valid Email Id.");
        document.getElementById("searchBtn").disabled = true;
      }
      return;
    }

    
  };

  useEffect(() => {
    setSearchValue("");
  }, [storeSearchValue]);

  useEffect(() => {
    handleClick("email");
  }, []);
  useEffect(() => {
    dispatch(setMobileValue(searchOption === "mobile" ? searchValue : ""));
    dispatch(setEmailValue(searchOption === "email" ? searchValue : ""));
    dispatch(
      setOrderNumberValue(searchOption === "orderNumber" ? searchValue : "")
    );
    dispatch(
      setCustRefNoSearchValue(searchOption === "custRefNo" ? searchValue : "")
    );
    dispatch(
      setPayerVPASearchValue(searchOption === "payerVPA" ? searchValue : "")
    );
  }, [searchOption, searchValue]);
  useEffect(() => {
    dispatch(setStartDateValue(""));
    dispatch(setEndDateValue(""));
  }, []);

  return (
    <div className="advanced-search-popup">
      {/* <div className="topside">

        <div className="filter-list">
          <li>Advanced Filter</li>
        </div>
      </div>

      {searchOption !== "dateFilter" && (
        <>
          <select
            className="advanced-select-section form-control"
            value={searchOption}
            onChange={(e) => setSearchOption(e.target.value)}
          >
            <option value="transactionId">Transaction ID</option>
            <option value="orderNumber">Order Number</option>
            <option value="email">e-Mail</option>
            <option value="mobile">Mobile</option>
          </select>

          <input
            className="advanced-input-box form-control"
            type="text"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === " ") {
                e.preventDefault();
              }
            }}
            placeholder={
              searchOption === "transactionId"
                ? "Enter transaction ID"
                : searchOption === "orderNumber"
                  ? "Enter Order Number"
                  : searchOption === "email"
                    ? "Enter e-Mail"
                    : "Enter Mobile"
            }
          />

          <button className="search-button-reset" onClick={handleButtonReset}>
            Reset
          </button>
        </>
      )}
      {apiResponse && apiResponse.length === 0 && (
        <div className="no-data-message">No data available.</div>
      )} */}

      <Modal show={show} centered>
        <Modal.Header className="bg-dark text-white">
          <Modal.Title> Chargeback Advanced Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="filter-field-area">
            <div className="form-group mb-3">
              <select
                className="form-control"
                value={searchOption}
                onChange={(e) => {
                  setSearchOption(e.target.value);
                  setSearchValue("");
                  setIsSearchValueError(false);
                  setSearchValueError("");
                }}
              >
                {/* <option value="transactionId">Transaction ID</option> */}
                <option type="email" value="email">
                  e-Mail
                </option>
                <option value="mobile">Mobile</option>
                <option value="payerVPA">Payer VPA</option>
                <option value="rrnNo">RRN No</option>
                {/* <option value="TxnUID">Transaction UID</option> */}
                {/* <option value="UPITxnID">UPI Transaction ID</option> */}
              </select>
            </div>
            <div className="form-group mb-2">
              <input
                className="advanced-input-box form-control"
                type={
                  searchOption === "mobile"
                    ? "tel"
                    : "text"
                }
                value={searchValue}
                onChange={handelSearchData}
                onBlur={handelValidations}
                onKeyDown={(e) => {
                  if (e.key === " ") {
                    e.preventDefault();
                  }
                }}
                placeholder={
                     searchOption === "email"
                    ? "Enter e-Mail"
                    : searchOption === "payerVPA"
                    ? "Enter Payer VPA"
                    : searchOption === "rrnNo"
                    ? "Enter RRN No"
                    : searchOption === "TxnUID"
                    ? "Enter Transaction UID"
                    : searchOption==="mobile"? "Enter Mobile":""
                }
              />
              {isSearchValueError ? (
                <span id="searchValErr" className=" text-danger">
                  {`${searchValueError}`}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-danger"
            size="sm"
            className="text-capitlize"
            onClick={handleModalClose}
          >
            Close
          </Button>
          <Button
            id="searchBtn"
            variant="outline-primary"
            size="sm"
            className="text-capitlize"
            onClick={submitHandler}
          >
            <i class="bi bi-search mx-1"></i> Search
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ChargebackAdvanceFilter;