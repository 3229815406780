import React from "react";
 
import { config } from "../utils/helper";
const InactiveServices = () => {

  const {logoPath}=config(process.env.REACT_APP_SERVER_ENVIRONMENT)

  return (
    <> 
     <div className="noservice">
        <div className="shadow border-0 card text-center">
          {/* <div className="card-header">Not Available</div> */}
          <div className="card-body p-3 text-center">
            <img src={logoPath} className="inactive-header mb-4" alt="logo" />
            <p className="text-center">
            <span className="alert alert-danger">
             Service not activated yet
             </span>
            </p>
          </div>
        </div>
      </div> 
    </>
  );
};

export default InactiveServices;
