import React, { useState } from "react";
import { config } from "../utils/helper";
import swal from "sweetalert";
// import "../stylesheet/Login.css";
import "../stylesheet/icomoon.css";
import { Link } from "react-router-dom";
import { SWIPELINC_API } from "../utils/constant";

const QrString = () => {
  const [OrderNumber, setOrderNumber] = useState("");
  const [qrString, setQrString] = useState("");
  const [id, setId] = useState("");

  

  const upiOpener = (qrString) => {
    window.open(qrString, "_blank");
  };
  const handleRegister = async () => {
    const payload = {
      id: id || 0,
      orderNumber: OrderNumber || 0,
    };

    try {
      const response = await fetch(
        `${SWIPELINC_API}MerchantAdminPanel/admin/login/getQRString`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const res = await response.json();

      if (res.statusCode === 200) {
  
        setQrString(res.data);
        
      } else {
        // Show SweetAlert for failed request
        swal({
          title: "Something Went wrong",
          text: "Failed to fetch QR String",
          icon: "error",
        });
      }
    } catch (err) {
      console.error("Error in handleRegister:", err.message);
      swal({
        title: "Something Went wrong",
        text: err.message,
        icon: "error",
      });
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center mt-5">
      <div className="mt-5">
        <div className="form-group align-items-center">
          <div>
            <label className="form-label">Order Number</label>
            <input
              className="form-control"
              value={OrderNumber}
              onChange={(e) => setOrderNumber(e.target.value)}
              placeholder="Enter Order Number"
              placeholderTextColor={"black"}
            />
          </div>
          <button
            className="btn btn-1 btn-primary mt-3"
            onClick={handleRegister}
          >
            Pay From UPI
          </button>
        </div>
        {qrString? upiOpener(qrString): ""}
      </div>
    </div>
  );
};

export default QrString;
