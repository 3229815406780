import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';

const IntentCallback = () => {

    const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const htmlContent = `<html xmlns:v="urn:schemas-microsoft-com:vml"
  xmlns:o="urn:schemas-microsoft-com:office:office"
  xmlns:w="urn:schemas-microsoft-com:office:word"
  xmlns:dt="uuid:C2F41010-65B3-11d1-A29F-00AA00C14882"
  xmlns:m="http://schemas.microsoft.com/office/2004/12/omml"
  xmlns="http://www.w3.org/TR/REC-html40">
  
  <head>
  <meta http-equiv=Content-Type content="text/html; charset=windows-1252">
  <meta name=ProgId content=Word.Document>
  <meta name=Generator content="Microsoft Word 12">
  <meta name=Originator content="Microsoft Word 12">
  <link rel=File-List href="Intent_Callback_V2_files/filelist.xml">
  <!--[if gte mso 9]><xml>
   <o:DocumentProperties>
    <o:Author>Paresh chalke</o:Author>
    <o:LastAuthor>lincpay</o:LastAuthor>
    <o:Revision>3</o:Revision>
    <o:TotalTime>0</o:TotalTime>
    <o:Created>2023-12-26T12:36:00Z</o:Created>
    <o:LastSaved>2023-12-26T12:36:00Z</o:LastSaved>
    <o:Pages>1</o:Pages>
    <o:Words>199</o:Words>
    <o:Characters>1140</o:Characters>
    <o:Lines>9</o:Lines>
    <o:Paragraphs>2</o:Paragraphs>
    <o:CharactersWithSpaces>1337</o:CharactersWithSpaces>
    <o:Version>12.00</o:Version>
   </o:DocumentProperties>
   <o:CustomDocumentProperties>
    <o:Created dt:dt="date">2023-08-04</o:Created>
    <o:Creator dt:dt="string">Microsoft® Word 2019</o:Creator>
    <o:LastSaved dt:dt="date">2023-12-26</o:LastSaved>
    <o:Producer dt:dt="string">Microsoft® Word 2019</o:Producer>
   </o:CustomDocumentProperties>
  </xml><![endif]-->
  <link rel=themeData href="Intent_Callback_V2_files/themedata.thmx">
  <link rel=colorSchemeMapping
  href="Intent_Callback_V2_files/colorschememapping.xml">
  <!--[if gte mso 9]><xml>
   <w:WordDocument>
    <w:SpellingState>Clean</w:SpellingState>
    <w:GrammarState>Clean</w:GrammarState>
    <w:TrackMoves>false</w:TrackMoves>
    <w:TrackFormatting/>
    <w:PunctuationKerning/>
    <w:DrawingGridHorizontalSpacing>5.5 pt</w:DrawingGridHorizontalSpacing>
    <w:DisplayHorizontalDrawingGridEvery>2</w:DisplayHorizontalDrawingGridEvery>
    <w:ValidateAgainstSchemas/>
    <w:SaveIfXMLInvalid>false</w:SaveIfXMLInvalid>
    <w:IgnoreMixedContent>false</w:IgnoreMixedContent>
    <w:AlwaysShowPlaceholderText>false</w:AlwaysShowPlaceholderText>
    <w:DoNotPromoteQF/>
    <w:LidThemeOther>EN-US</w:LidThemeOther>
    <w:LidThemeAsian>X-NONE</w:LidThemeAsian>
    <w:LidThemeComplexScript>X-NONE</w:LidThemeComplexScript>
    <w:Compatibility>
     <w:ULTrailSpace/>
     <w:ShapeLayoutLikeWW8/>
     <w:BreakWrappedTables/>
     <w:SnapToGridInCell/>
     <w:WrapTextWithPunct/>
     <w:UseAsianBreakRules/>
     <w:DontGrowAutofit/>
     <w:SplitPgBreakAndParaMark/>
     <w:DontVertAlignCellWithSp/>
     <w:DontBreakConstrainedForcedTables/>
     <w:DontVertAlignInTxbx/>
     <w:Word11KerningPairs/>
     <w:CachedColBalance/>
    </w:Compatibility>
    <w:DoNotOptimizeForBrowser/>
    <m:mathPr>
     <m:mathFont m:val="Cambria Math"/>
     <m:brkBin m:val="before"/>
     <m:brkBinSub m:val="--"/>
     <m:smallFrac m:val="off"/>
     <m:dispDef/>
     <m:lMargin m:val="0"/>
     <m:rMargin m:val="0"/>
     <m:defJc m:val="centerGroup"/>
     <m:wrapIndent m:val="1440"/>
     <m:intLim m:val="subSup"/>
     <m:naryLim m:val="undOvr"/>
    </m:mathPr></w:WordDocument>
  </xml><![endif]--><!--[if gte mso 9]><xml>
   <w:LatentStyles DefLockedState="false" DefUnhideWhenUsed="true"
    DefSemiHidden="true" DefQFormat="false" DefPriority="99"
    LatentStyleCount="267">
    <w:LsdException Locked="false" Priority="0" SemiHidden="false"
     UnhideWhenUsed="false" QFormat="true" Name="Normal"/>
    <w:LsdException Locked="false" Priority="9" SemiHidden="false"
     UnhideWhenUsed="false" QFormat="true" Name="heading 1"/>
    <w:LsdException Locked="false" Priority="9" QFormat="true" Name="heading 2"/>
    <w:LsdException Locked="false" Priority="9" QFormat="true" Name="heading 3"/>
    <w:LsdException Locked="false" Priority="9" QFormat="true" Name="heading 4"/>
    <w:LsdException Locked="false" Priority="9" QFormat="true" Name="heading 5"/>
    <w:LsdException Locked="false" Priority="9" QFormat="true" Name="heading 6"/>
    <w:LsdException Locked="false" Priority="9" QFormat="true" Name="heading 7"/>
    <w:LsdException Locked="false" Priority="9" QFormat="true" Name="heading 8"/>
    <w:LsdException Locked="false" Priority="9" QFormat="true" Name="heading 9"/>
    <w:LsdException Locked="false" Priority="39" Name="toc 1"/>
    <w:LsdException Locked="false" Priority="39" Name="toc 2"/>
    <w:LsdException Locked="false" Priority="39" Name="toc 3"/>
    <w:LsdException Locked="false" Priority="39" Name="toc 4"/>
    <w:LsdException Locked="false" Priority="39" Name="toc 5"/>
    <w:LsdException Locked="false" Priority="39" Name="toc 6"/>
    <w:LsdException Locked="false" Priority="39" Name="toc 7"/>
    <w:LsdException Locked="false" Priority="39" Name="toc 8"/>
    <w:LsdException Locked="false" Priority="39" Name="toc 9"/>
    <w:LsdException Locked="false" Priority="35" QFormat="true" Name="caption"/>
    <w:LsdException Locked="false" Priority="10" SemiHidden="false"
     UnhideWhenUsed="false" QFormat="true" Name="Title"/>
    <w:LsdException Locked="false" Priority="1" Name="Default Paragraph Font"/>
    <w:LsdException Locked="false" Priority="11" SemiHidden="false"
     UnhideWhenUsed="false" QFormat="true" Name="Subtitle"/>
    <w:LsdException Locked="false" Priority="22" SemiHidden="false"
     UnhideWhenUsed="false" QFormat="true" Name="Strong"/>
    <w:LsdException Locked="false" Priority="20" SemiHidden="false"
     UnhideWhenUsed="false" QFormat="true" Name="Emphasis"/>
    <w:LsdException Locked="false" Priority="59" SemiHidden="false"
     UnhideWhenUsed="false" Name="Table Grid"/>
    <w:LsdException Locked="false" UnhideWhenUsed="false" Name="Placeholder Text"/>
    <w:LsdException Locked="false" Priority="1" SemiHidden="false"
     UnhideWhenUsed="false" QFormat="true" Name="No Spacing"/>
    <w:LsdException Locked="false" Priority="60" SemiHidden="false"
     UnhideWhenUsed="false" Name="Light Shading"/>
    <w:LsdException Locked="false" Priority="61" SemiHidden="false"
     UnhideWhenUsed="false" Name="Light List"/>
    <w:LsdException Locked="false" Priority="62" SemiHidden="false"
     UnhideWhenUsed="false" Name="Light Grid"/>
    <w:LsdException Locked="false" Priority="63" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Shading 1"/>
    <w:LsdException Locked="false" Priority="64" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Shading 2"/>
    <w:LsdException Locked="false" Priority="65" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium List 1"/>
    <w:LsdException Locked="false" Priority="66" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium List 2"/>
    <w:LsdException Locked="false" Priority="67" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Grid 1"/>
    <w:LsdException Locked="false" Priority="68" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Grid 2"/>
    <w:LsdException Locked="false" Priority="69" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Grid 3"/>
    <w:LsdException Locked="false" Priority="70" SemiHidden="false"
     UnhideWhenUsed="false" Name="Dark List"/>
    <w:LsdException Locked="false" Priority="71" SemiHidden="false"
     UnhideWhenUsed="false" Name="Colorful Shading"/>
    <w:LsdException Locked="false" Priority="72" SemiHidden="false"
     UnhideWhenUsed="false" Name="Colorful List"/>
    <w:LsdException Locked="false" Priority="73" SemiHidden="false"
     UnhideWhenUsed="false" Name="Colorful Grid"/>
    <w:LsdException Locked="false" Priority="60" SemiHidden="false"
     UnhideWhenUsed="false" Name="Light Shading Accent 1"/>
    <w:LsdException Locked="false" Priority="61" SemiHidden="false"
     UnhideWhenUsed="false" Name="Light List Accent 1"/>
    <w:LsdException Locked="false" Priority="62" SemiHidden="false"
     UnhideWhenUsed="false" Name="Light Grid Accent 1"/>
    <w:LsdException Locked="false" Priority="63" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Shading 1 Accent 1"/>
    <w:LsdException Locked="false" Priority="64" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Shading 2 Accent 1"/>
    <w:LsdException Locked="false" Priority="65" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium List 1 Accent 1"/>
    <w:LsdException Locked="false" UnhideWhenUsed="false" Name="Revision"/>
    <w:LsdException Locked="false" Priority="34" SemiHidden="false"
     UnhideWhenUsed="false" QFormat="true" Name="List Paragraph"/>
    <w:LsdException Locked="false" Priority="29" SemiHidden="false"
     UnhideWhenUsed="false" QFormat="true" Name="Quote"/>
    <w:LsdException Locked="false" Priority="30" SemiHidden="false"
     UnhideWhenUsed="false" QFormat="true" Name="Intense Quote"/>
    <w:LsdException Locked="false" Priority="66" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium List 2 Accent 1"/>
    <w:LsdException Locked="false" Priority="67" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Grid 1 Accent 1"/>
    <w:LsdException Locked="false" Priority="68" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Grid 2 Accent 1"/>
    <w:LsdException Locked="false" Priority="69" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Grid 3 Accent 1"/>
    <w:LsdException Locked="false" Priority="70" SemiHidden="false"
     UnhideWhenUsed="false" Name="Dark List Accent 1"/>
    <w:LsdException Locked="false" Priority="71" SemiHidden="false"
     UnhideWhenUsed="false" Name="Colorful Shading Accent 1"/>
    <w:LsdException Locked="false" Priority="72" SemiHidden="false"
     UnhideWhenUsed="false" Name="Colorful List Accent 1"/>
    <w:LsdException Locked="false" Priority="73" SemiHidden="false"
     UnhideWhenUsed="false" Name="Colorful Grid Accent 1"/>
    <w:LsdException Locked="false" Priority="60" SemiHidden="false"
     UnhideWhenUsed="false" Name="Light Shading Accent 2"/>
    <w:LsdException Locked="false" Priority="61" SemiHidden="false"
     UnhideWhenUsed="false" Name="Light List Accent 2"/>
    <w:LsdException Locked="false" Priority="62" SemiHidden="false"
     UnhideWhenUsed="false" Name="Light Grid Accent 2"/>
    <w:LsdException Locked="false" Priority="63" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Shading 1 Accent 2"/>
    <w:LsdException Locked="false" Priority="64" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Shading 2 Accent 2"/>
    <w:LsdException Locked="false" Priority="65" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium List 1 Accent 2"/>
    <w:LsdException Locked="false" Priority="66" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium List 2 Accent 2"/>
    <w:LsdException Locked="false" Priority="67" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Grid 1 Accent 2"/>
    <w:LsdException Locked="false" Priority="68" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Grid 2 Accent 2"/>
    <w:LsdException Locked="false" Priority="69" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Grid 3 Accent 2"/>
    <w:LsdException Locked="false" Priority="70" SemiHidden="false"
     UnhideWhenUsed="false" Name="Dark List Accent 2"/>
    <w:LsdException Locked="false" Priority="71" SemiHidden="false"
     UnhideWhenUsed="false" Name="Colorful Shading Accent 2"/>
    <w:LsdException Locked="false" Priority="72" SemiHidden="false"
     UnhideWhenUsed="false" Name="Colorful List Accent 2"/>
    <w:LsdException Locked="false" Priority="73" SemiHidden="false"
     UnhideWhenUsed="false" Name="Colorful Grid Accent 2"/>
    <w:LsdException Locked="false" Priority="60" SemiHidden="false"
     UnhideWhenUsed="false" Name="Light Shading Accent 3"/>
    <w:LsdException Locked="false" Priority="61" SemiHidden="false"
     UnhideWhenUsed="false" Name="Light List Accent 3"/>
    <w:LsdException Locked="false" Priority="62" SemiHidden="false"
     UnhideWhenUsed="false" Name="Light Grid Accent 3"/>
    <w:LsdException Locked="false" Priority="63" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Shading 1 Accent 3"/>
    <w:LsdException Locked="false" Priority="64" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Shading 2 Accent 3"/>
    <w:LsdException Locked="false" Priority="65" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium List 1 Accent 3"/>
    <w:LsdException Locked="false" Priority="66" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium List 2 Accent 3"/>
    <w:LsdException Locked="false" Priority="67" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Grid 1 Accent 3"/>
    <w:LsdException Locked="false" Priority="68" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Grid 2 Accent 3"/>
    <w:LsdException Locked="false" Priority="69" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Grid 3 Accent 3"/>
    <w:LsdException Locked="false" Priority="70" SemiHidden="false"
     UnhideWhenUsed="false" Name="Dark List Accent 3"/>
    <w:LsdException Locked="false" Priority="71" SemiHidden="false"
     UnhideWhenUsed="false" Name="Colorful Shading Accent 3"/>
    <w:LsdException Locked="false" Priority="72" SemiHidden="false"
     UnhideWhenUsed="false" Name="Colorful List Accent 3"/>
    <w:LsdException Locked="false" Priority="73" SemiHidden="false"
     UnhideWhenUsed="false" Name="Colorful Grid Accent 3"/>
    <w:LsdException Locked="false" Priority="60" SemiHidden="false"
     UnhideWhenUsed="false" Name="Light Shading Accent 4"/>
    <w:LsdException Locked="false" Priority="61" SemiHidden="false"
     UnhideWhenUsed="false" Name="Light List Accent 4"/>
    <w:LsdException Locked="false" Priority="62" SemiHidden="false"
     UnhideWhenUsed="false" Name="Light Grid Accent 4"/>
    <w:LsdException Locked="false" Priority="63" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Shading 1 Accent 4"/>
    <w:LsdException Locked="false" Priority="64" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Shading 2 Accent 4"/>
    <w:LsdException Locked="false" Priority="65" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium List 1 Accent 4"/>
    <w:LsdException Locked="false" Priority="66" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium List 2 Accent 4"/>
    <w:LsdException Locked="false" Priority="67" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Grid 1 Accent 4"/>
    <w:LsdException Locked="false" Priority="68" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Grid 2 Accent 4"/>
    <w:LsdException Locked="false" Priority="69" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Grid 3 Accent 4"/>
    <w:LsdException Locked="false" Priority="70" SemiHidden="false"
     UnhideWhenUsed="false" Name="Dark List Accent 4"/>
    <w:LsdException Locked="false" Priority="71" SemiHidden="false"
     UnhideWhenUsed="false" Name="Colorful Shading Accent 4"/>
    <w:LsdException Locked="false" Priority="72" SemiHidden="false"
     UnhideWhenUsed="false" Name="Colorful List Accent 4"/>
    <w:LsdException Locked="false" Priority="73" SemiHidden="false"
     UnhideWhenUsed="false" Name="Colorful Grid Accent 4"/>
    <w:LsdException Locked="false" Priority="60" SemiHidden="false"
     UnhideWhenUsed="false" Name="Light Shading Accent 5"/>
    <w:LsdException Locked="false" Priority="61" SemiHidden="false"
     UnhideWhenUsed="false" Name="Light List Accent 5"/>
    <w:LsdException Locked="false" Priority="62" SemiHidden="false"
     UnhideWhenUsed="false" Name="Light Grid Accent 5"/>
    <w:LsdException Locked="false" Priority="63" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Shading 1 Accent 5"/>
    <w:LsdException Locked="false" Priority="64" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Shading 2 Accent 5"/>
    <w:LsdException Locked="false" Priority="65" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium List 1 Accent 5"/>
    <w:LsdException Locked="false" Priority="66" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium List 2 Accent 5"/>
    <w:LsdException Locked="false" Priority="67" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Grid 1 Accent 5"/>
    <w:LsdException Locked="false" Priority="68" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Grid 2 Accent 5"/>
    <w:LsdException Locked="false" Priority="69" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Grid 3 Accent 5"/>
    <w:LsdException Locked="false" Priority="70" SemiHidden="false"
     UnhideWhenUsed="false" Name="Dark List Accent 5"/>
    <w:LsdException Locked="false" Priority="71" SemiHidden="false"
     UnhideWhenUsed="false" Name="Colorful Shading Accent 5"/>
    <w:LsdException Locked="false" Priority="72" SemiHidden="false"
     UnhideWhenUsed="false" Name="Colorful List Accent 5"/>
    <w:LsdException Locked="false" Priority="73" SemiHidden="false"
     UnhideWhenUsed="false" Name="Colorful Grid Accent 5"/>
    <w:LsdException Locked="false" Priority="60" SemiHidden="false"
     UnhideWhenUsed="false" Name="Light Shading Accent 6"/>
    <w:LsdException Locked="false" Priority="61" SemiHidden="false"
     UnhideWhenUsed="false" Name="Light List Accent 6"/>
    <w:LsdException Locked="false" Priority="62" SemiHidden="false"
     UnhideWhenUsed="false" Name="Light Grid Accent 6"/>
    <w:LsdException Locked="false" Priority="63" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Shading 1 Accent 6"/>
    <w:LsdException Locked="false" Priority="64" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Shading 2 Accent 6"/>
    <w:LsdException Locked="false" Priority="65" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium List 1 Accent 6"/>
    <w:LsdException Locked="false" Priority="66" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium List 2 Accent 6"/>
    <w:LsdException Locked="false" Priority="67" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Grid 1 Accent 6"/>
    <w:LsdException Locked="false" Priority="68" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Grid 2 Accent 6"/>
    <w:LsdException Locked="false" Priority="69" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Grid 3 Accent 6"/>
    <w:LsdException Locked="false" Priority="70" SemiHidden="false"
     UnhideWhenUsed="false" Name="Dark List Accent 6"/>
    <w:LsdException Locked="false" Priority="71" SemiHidden="false"
     UnhideWhenUsed="false" Name="Colorful Shading Accent 6"/>
    <w:LsdException Locked="false" Priority="72" SemiHidden="false"
     UnhideWhenUsed="false" Name="Colorful List Accent 6"/>
    <w:LsdException Locked="false" Priority="73" SemiHidden="false"
     UnhideWhenUsed="false" Name="Colorful Grid Accent 6"/>
    <w:LsdException Locked="false" Priority="19" SemiHidden="false"
     UnhideWhenUsed="false" QFormat="true" Name="Subtle Emphasis"/>
    <w:LsdException Locked="false" Priority="21" SemiHidden="false"
     UnhideWhenUsed="false" QFormat="true" Name="Intense Emphasis"/>
    <w:LsdException Locked="false" Priority="31" SemiHidden="false"
     UnhideWhenUsed="false" QFormat="true" Name="Subtle Reference"/>
    <w:LsdException Locked="false" Priority="32" SemiHidden="false"
     UnhideWhenUsed="false" QFormat="true" Name="Intense Reference"/>
    <w:LsdException Locked="false" Priority="33" SemiHidden="false"
     UnhideWhenUsed="false" QFormat="true" Name="Book Title"/>
    <w:LsdException Locked="false" Priority="37" Name="Bibliography"/>
    <w:LsdException Locked="false" Priority="39" QFormat="true" Name="TOC Heading"/>
   </w:LatentStyles>
  </xml><![endif]-->
  <style>
  <!--
   /* Font Definitions */
   @font-face
      {font-family:"Cambria Math";
      panose-1:2 4 5 3 5 4 6 3 2 4;
      mso-font-charset:0;
      mso-generic-font-family:roman;
      mso-font-pitch:variable;
      mso-font-signature:-536869121 1107305727 33554432 0 415 0;}
  @font-face
      {font-family:Calibri;
      panose-1:2 15 5 2 2 2 4 3 2 4;
      mso-font-charset:0;
      mso-generic-font-family:swiss;
      mso-font-pitch:variable;
      mso-font-signature:-469750017 -1073732485 9 0 511 0;}
   /* Style Definitions */
   p.MsoNormal, li.MsoNormal, div.MsoNormal
      {mso-style-priority:1;
      mso-style-unhide:no;
      mso-style-qformat:yes;
      mso-style-parent:"";
      margin:0cm;
      margin-bottom:.0001pt;
      mso-pagination:none;
      text-autospace:none;
      font-size:11.0pt;
      font-family:"Calibri","sans-serif";
      mso-fareast-font-family:Calibri;}
  p.MsoTitle, li.MsoTitle, div.MsoTitle
      {mso-style-priority:1;
      mso-style-unhide:no;
      mso-style-qformat:yes;
      margin-top:.1pt;
      margin-right:0cm;
      margin-bottom:0cm;
      margin-left:.95pt;
      margin-bottom:.0001pt;
      text-align:center;
      mso-pagination:none;
      text-autospace:none;
      font-size:18.0pt;
      font-family:"Calibri","sans-serif";
      mso-fareast-font-family:Calibri;
      font-weight:bold;}
  p.MsoBodyText, li.MsoBodyText, div.MsoBodyText
      {mso-style-priority:1;
      mso-style-unhide:no;
      mso-style-qformat:yes;
      margin-top:1.1pt;
      margin-right:0cm;
      margin-bottom:0cm;
      margin-left:10.05pt;
      margin-bottom:.0001pt;
      mso-pagination:none;
      text-autospace:none;
      font-size:11.0pt;
      font-family:"Calibri","sans-serif";
      mso-fareast-font-family:Calibri;}
  p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
      {mso-style-priority:1;
      mso-style-unhide:no;
      mso-style-qformat:yes;
      margin:0cm;
      margin-bottom:.0001pt;
      mso-pagination:none;
      text-autospace:none;
      font-size:11.0pt;
      font-family:"Calibri","sans-serif";
      mso-fareast-font-family:Calibri;}
  p.TableParagraph, li.TableParagraph, div.TableParagraph
      {mso-style-name:"Table Paragraph";
      mso-style-priority:1;
      mso-style-unhide:no;
      mso-style-qformat:yes;
      margin:0cm;
      margin-bottom:.0001pt;
      mso-pagination:none;
      text-autospace:none;
      font-size:11.0pt;
      font-family:"Calibri","sans-serif";
      mso-fareast-font-family:Calibri;}
  span.SpellE
      {mso-style-name:"";
      mso-spl-e:yes;}
  span.GramE
      {mso-style-name:"";
      mso-gram-e:yes;}
  .MsoChpDefault
      {mso-style-type:export-only;
      mso-default-props:yes;
      mso-ascii-font-family:Calibri;
      mso-ascii-theme-font:minor-latin;
      mso-fareast-font-family:Calibri;
      mso-fareast-theme-font:minor-latin;
      mso-hansi-font-family:Calibri;
      mso-hansi-theme-font:minor-latin;
      mso-bidi-font-family:"Times New Roman";
      mso-bidi-theme-font:minor-bidi;}
  .MsoPapDefault
      {mso-style-type:export-only;
      mso-pagination:none;
      text-autospace:none;}
  @page Section1
      {size:595.5pt 842.0pt;
      margin:71.0pt 68.0pt 14.0pt 67.0pt;
      mso-header-margin:36.0pt;
      mso-footer-margin:36.0pt;
      mso-paper-source:0;}
  div.Section1
      {page:Section1;}
  -->
  </style>
  <!--[if gte mso 10]>
  <style>
   /* Style Definitions */
   table.MsoNormalTable
      {mso-style-name:"Table Normal";
      mso-tstyle-rowband-size:0;
      mso-tstyle-colband-size:0;
      mso-style-noshow:yes;
      mso-style-priority:99;
      mso-style-qformat:yes;
      mso-style-parent:"";
      mso-padding-alt:0cm 5.4pt 0cm 5.4pt;
      mso-para-margin:0cm;
      mso-para-margin-bottom:.0001pt;
      mso-pagination:none;
      text-autospace:none;
      font-size:11.0pt;
      font-family:"Calibri","sans-serif";
      mso-ascii-font-family:Calibri;
      mso-ascii-theme-font:minor-latin;
      mso-hansi-font-family:Calibri;
      mso-hansi-theme-font:minor-latin;
      mso-bidi-font-family:"Times New Roman";
      mso-bidi-theme-font:minor-bidi;}
  </style>
  <![endif]--><!--[if gte mso 9]><xml>
   <o:shapedefaults v:ext="edit" spidmax="2050"/>
  </xml><![endif]--><!--[if gte mso 9]><xml>
   <o:shapelayout v:ext="edit">
    <o:idmap v:ext="edit" data="1"/>
   </o:shapelayout></xml><![endif]-->
  </head>
  
  <body lang=EN-US style='tab-interval:36.0pt'>
  
  <div class=Section1>
  
  <p class=MsoTitle><span class=SpellE><span style='font-size:13.0pt'>IntentCallbackAPI</span></span><span
  style='font-size:13.0pt;letter-spacing:-.1pt'> Document</span><span
  style='font-size:13.0pt'><o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:19.5pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:0cm;margin-bottom:.0001pt'><b style='mso-bidi-font-weight:normal'><span
  style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></b></p>
  
  <p class=MsoNormal style='margin-top:0cm;margin-right:2.5pt;margin-bottom:0cm;
  margin-left:5.0pt;margin-bottom:.0001pt;line-height:107%'><b style='mso-bidi-font-weight:
  normal'><span style='font-size:13.0pt;line-height:107%'>TargetURL/API<span
  class=GramE>:<span style='font-weight:normal'>ThereturnURLshouldbesharedbythemerchant,andonthisURL,the</span></span></span></b><span
  style='font-size:13.0pt;line-height:107%'> merchant will receive the callback
  response. Once we receive the response from the payment server, we will send it
  to the merchant.<o:p></o:p></span></p>
  
  <p class=MsoNormal style='margin-top:14.35pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:5.0pt;margin-bottom:.0001pt'><span class=SpellE><b
  style='mso-bidi-font-weight:normal'><span style='font-size:13.0pt'>Response<span
  style='letter-spacing:-.1pt'>Json</span></span></b></span><b style='mso-bidi-font-weight:
  normal'><span style='font-size:13.0pt;letter-spacing:-.1pt'>:</span></b><b
  style='mso-bidi-font-weight:normal'><span style='font-size:13.0pt'><o:p></o:p></span></b></p>
  
  <p class=MsoNormal style='margin-top:1.15pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:5.0pt;margin-bottom:.0001pt'><span style='font-size:13.0pt;
  letter-spacing:-.5pt'>[</span><span style='font-size:13.0pt'><o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:1.1pt;margin-right:5.45pt;margin-bottom:
  0cm;margin-left:5.0pt;margin-bottom:.0001pt;text-align:justify;line-height:
  107%'><span class=GramE><span style='font-size:13.0pt;line-height:107%;
  letter-spacing:-.1pt'>data=</span></span><span style='font-size:13.0pt;
  line-height:107%;letter-spacing:-.1pt'>Bon6NhrN0qHKCtiBf1G4Vhd3agrDW8VTbXYDoe9mNbiZ+6wTv9B13aiU90N3GWW39A/bJH5Vu
  Bsw70HQ0mSkA6Iar88rEqUjrWpXNl0uiKKSlYkpTYpOi08RZ82z847BDIscNFMmULvWyPuiPI+qhk3uifSf
  59fu1Jr9lWCsT1WtfI3KKDs9R2m/sWvULcv8n9NB1DO94kk5P2ZbIF5ONXE+1Qce7Y+A2LRun9qUNnsr
  hw8c5kueP3D0a0rk2Lu5JEB2X+y/ARE01xkDhYyxzSPmi9kFgrhdELiQ4xaCqn8OIu0YwfvOV4Q7vLXdPD
  LV3GSyJ1ts/<span class=SpellE>BRvzP</span>/L/<span class=SpellE>uBjWQ</span>==,</span><span
  style='font-size:13.0pt;line-height:107%'><o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:0cm'><span class=GramE><span
  style='font-size:13.0pt;letter-spacing:-.1pt'>mid=</span></span><span
  style='font-size:13.0pt;letter-spacing:-.1pt'>SWIPEPROD100007,</span><span
  style='font-size:13.0pt'><o:p></o:p></span></p>
  
  <p class=MsoBodyText><span class=GramE><span style='font-size:13.0pt;
  letter-spacing:-.1pt'>signature=</span></span><span style='font-size:13.0pt;
  letter-spacing:-.1pt'>782923988</span><span style='font-size:13.0pt'><o:p></o:p></span></p>
  
  <p class=MsoNormal style='margin-top:.95pt;margin-right:0cm;margin-bottom:0cm;
  margin-left:5.0pt;margin-bottom:.0001pt'><span style='font-size:13.0pt;
  letter-spacing:-.5pt'>]</span><span style='font-size:13.0pt'><o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:2.2pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:0cm;margin-bottom:.0001pt'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:0cm;margin-right:2.5pt;margin-bottom:
  0cm;margin-left:5.0pt;margin-bottom:.0001pt;line-height:107%'><b
  style='mso-bidi-font-weight:normal'><span style='font-size:13.0pt;line-height:
  107%'>Data<span class=GramE>:<span style='font-weight:normal'>It’sencryptedvalue,forchecktheresponsepleasedecryptitbyusingyourencryptionkeyand</span></span></span></b><span
  style='font-size:13.0pt;line-height:107%'> same algorithm which you using
  during sent request to payment server.<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:1.0pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:0cm;margin-bottom:.0001pt'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  <p class=MsoNormal style='margin-left:5.0pt'><span class=SpellE><b
  style='mso-bidi-font-weight:normal'><span style='font-size:13.0pt'>Afterdecryption<span
  class=GramE>:<span style='font-weight:normal'>Afterdecryptionresponsedatawill<span
  style='letter-spacing:-.25pt'>be</span></span></span></span></b></span><span
  style='font-size:13.0pt;letter-spacing:-.25pt'>-</span><span style='font-size:
  13.0pt'><o:p></o:p></span></p>
  
  <p class=MsoNormal style='margin-top:1.1pt;margin-right:0cm;margin-bottom:0cm;
  margin-left:5.0pt;margin-bottom:.0001pt'><span style='font-size:13.0pt;
  letter-spacing:-.5pt'>{</span><span style='font-size:13.0pt'><o:p></o:p></span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'>&quot;<span class=GramE>amount</span>&quot;:<span
  style='letter-spacing:-.1pt'>&quot;100&quot;,</span><o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:1.0pt'><span style='font-size:13.0pt'>&quot;<span
  class=SpellE><span class=GramE>orderNo</span></span>&quot;:<span
  style='letter-spacing:-.1pt'>&quot;ORD23072214392200916&quot;,</span><o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-right:319.4pt;line-height:107%'><span
  style='font-size:13.0pt;line-height:107%'>&quot;<span class=SpellE><span
  class=GramE>txnStatus</span></span>&quot;: &quot;success&quot;, &quot;<span
  class=SpellE>txnRespCode</span>&quot;:
  &quot;200&quot;,&quot;mid&quot;:&quot;SWIPEPROD100007&quot;,<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:0cm;line-height:13.35pt;mso-line-height-rule:
  exactly'><span style='font-size:13.0pt'>&quot;<span class=GramE>currency</span>&quot;:<span
  style='letter-spacing:-.1pt'>&quot;INR&quot;,</span><o:p></o:p></span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'>&quot;<span class=SpellE><span
  class=GramE>txnDate</span></span>&quot;:&quot;2023-07-22<span style='letter-spacing:
  -.1pt'>14:39:20.796&quot;,</span><o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:1.05pt'><span style='font-size:13.0pt'>&quot;<span
  class=SpellE><span class=GramE>customerIfsc</span></span>&quot;:<span
  style='letter-spacing:-.1pt'>&quot;none&quot;,</span><o:p></o:p></span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'>&quot;<span class=SpellE><span
  class=GramE>txnId</span></span>&quot;:<span style='letter-spacing:-.1pt'>&quot;47209036589869018322&quot;,</span><o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:1.0pt;margin-right:319.4pt;margin-bottom:
  0cm;margin-left:10.05pt;margin-bottom:.0001pt;line-height:107%'><span
  style='font-size:13.0pt;line-height:107%'>&quot;<span class=SpellE>paymentType</span>&quot;:&quot;UPI&quot;,
  <span style='letter-spacing:-.1pt'>&quot;udf3&quot;:&quot;&quot;,</span><o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:0cm'><span style='font-size:13.0pt;
  letter-spacing:-.1pt'>&quot;udf4&quot;:&quot;&quot;,</span><span
  style='font-size:13.0pt'><o:p></o:p></span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt;letter-spacing:-.1pt'>&quot;udf1&quot;:&quot;&quot;,</span><span
  style='font-size:13.0pt'><o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:1.0pt'><span style='font-size:13.0pt;
  letter-spacing:-.1pt'>&quot;udf2&quot;:&quot;&quot;</span><span
  style='font-size:13.0pt'><o:p></o:p></span></p>
  
  <p class=MsoNormal style='margin-top:1.1pt;margin-right:0cm;margin-bottom:0cm;
  margin-left:5.0pt;margin-bottom:.0001pt'><span style='font-size:13.0pt;
  letter-spacing:-.5pt'>}</span><span style='font-size:13.0pt'><o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:2.2pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:0cm;margin-bottom:.0001pt'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:0cm;margin-right:2.5pt;margin-bottom:
  0cm;margin-left:5.0pt;margin-bottom:.0001pt;line-height:106%'><b
  style='mso-bidi-font-weight:normal'><span style='font-size:13.0pt;line-height:
  106%'>Signature/Checksum<span class=GramE>:<span style='font-weight:normal'>Signatureischecksumvaluethatisgeneratetocheckthedataintegrity.For</span></span></span></b><span
  style='font-size:13.0pt;line-height:106%'> generate checksum/signature please
  use response field at given sequence-<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:.2pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:5.0pt;margin-bottom:.0001pt'><span class=SpellE><span
  style='font-size:13.0pt'>Mid+txnId+orderNo</span></span><span style='font-size:
  13.0pt'>+<span style='letter-spacing:-.1pt'> <span class=SpellE>txnStatus</span></span><o:p></o:p></span></p>
  
  </div>
  
  </body>
  
  </html>
  `

  return (
    <section className="dashboard-header-sec merchant-settings-sec mt-3 rounded-top">
      <Container>
        <div className="ds-header-sec">
          <Row>
            <div className="d-flex justify-content-end">
              <button className="btn btn-success mb-3" onClick={goBack}>
                {" "}
                &#8592; Back
              </button>
            </div>

            <Col xs={12} lg={12} md={12}>
              <Card className="shadow border-0 mb-4 ds-header-card">
                <Card.Body>
                  <div
                    className="merchant-tab-btn  p-3  "
                    dangerouslySetInnerHTML={{ __html: htmlContent }}
                  ></div>
                </Card.Body>
              </Card>
              {/* ./card */}
            </Col>
          </Row>
          <div className="d-flex justify-content-end">
            <button className="btn btn-success mb-3" onClick={goBack}>
              {" "}
              &#8592; Back
            </button>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default IntentCallback