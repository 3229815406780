import React, { useState } from "react";
import { Col, Card, Button } from "react-bootstrap";

import axios from "axios";
import jwtDecode from "jwt-decode";
import "../../assets/css/downArrow.css";
import { SWIPELINC_API } from "../../utils/constant";
import {
  isEmailIdRegexIsValid,
  isVpaUpiRegexIsValid,
} from "../../utils/validations";
import swal from "sweetalert";

const Filter = ({
  active,
  setOpenPopUp,
  setShow,
  setPayoutClose,
  setApiResponse,
  apiResponse,
}) => {
  const [searchOption, setSearchOption] = useState("email");
  // const [apiResponse, setApiResponse] = useState("")

  const [searchValue, setSearchValue] = useState("");

  const [searchValueError, setSearchValueError] = useState("");
  const [isSearchValueError, setIsSearchValueError] = useState(false);

  const tokenValue = localStorage.getItem("token");
  const [errorText, setErrorText] = useState("");

  const token = tokenValue ? tokenValue.slice(1, -1) : null;
  const decodedToken = jwtDecode(token);
  const { mid } = decodedToken;
  // console.log(active, mid);

  // console.log(searchOption, setApiResponse, searchValue)

  const handleModalClose = () => {
    setShow(false);
    setOpenPopUp(false);
  };

  function getSearchFunctionText(active) {
    let searchFunctionText;

    if (active === "Customer") {
      searchFunctionText = "findCustomerInPayin";
    } else if (active === "Vendor") {
      searchFunctionText = "getAllVender";
    }

    return searchFunctionText;
  }

  const payload = {
    mid,
    email: searchOption === "email" ? searchValue.trim() : "",
    mobile: searchOption === "mobile" ? searchValue.trim() : "",
    vpa: searchOption === "payerVPA" ? searchValue.trim() : "",
  };

  const findAllVendors = async (payload, token) => {
    try {
      console.log(payload, token);
      const response = await axios.post(
        `${SWIPELINC_API}MerchantPanel/merchant/vendor/${getSearchFunctionText(
          active
        )}`,
        payload,
        {
          headers: {
            Authentication: "Bearer " + token,
          },
        }
      );
      if (
        response.data.statusCode === 304 ||
        response.data.Data === "No Record Found"
      ) {
        setErrorText("No records found");
      } else if(response.data.statusCode === 600) {
        setErrorText(response.data.message);
        return
      }else if(response.data.statusCode === 200) {
        
        return response 
      }else {
        setErrorText(response.data.message);
        return
      }
      // console.log(response.data.data)
      return response.data;
    } catch (error) {
      throw setErrorText("Something Went Wrong");
    }
  };

  const submitHandler = () => {
    // findAllVendors(payload,token)

    findAllVendors(payload, token)
      .then((responseData) => {
        if (responseData.statusCode === 304) {
          setErrorText("No record found");
        } else if(responseData.data.statusCode === 200){
          setApiResponse(responseData.data.data);
          setErrorText(responseData.data.message);
     
          setPayoutClose(false);
        }

        
      })
      .catch((error) => {
        console.log(error);
        setErrorText("Something Went Wrong");
        //  setErrorText("No Data Found")
      });

    // handleModalClose();
  };

  const handleInputChange = (e) => {
    const newInputValue = e.target.value;
    setIsSearchValueError(false);
    setSearchValueError("");

    document.getElementById("searchBtn").disabled = false;
    if (newInputValue.includes(" ")) {
      setErrorText("Input cannot contain spaces.");
      return;
    }

    if (searchOption === "mobile") {
      const numericInput = /^\d*$/.test(newInputValue);
      if (numericInput) {
        setSearchValue(newInputValue.substring(0, 10)); // Only allow up to 10 digits
        setErrorText("");
      } else {
        setErrorText("Input must be a number.");
      }
    } else if (searchOption === "email" || searchOption === "payerVPA") {
      setSearchValue(newInputValue);
      setErrorText("");
    }
  };

  const handelValidations = (e) => {
    if (searchOption === "payerVPA") {
      if (!isVpaUpiRegexIsValid(searchValue)) {
        setIsSearchValueError(true);
        setSearchValueError("Enter Valid VPA.");
        document.getElementById("searchBtn").disabled = true;
      }
      return;
    }

    if (searchOption === "email") {
      if (!isEmailIdRegexIsValid(searchValue)) {
        setIsSearchValueError(true);
        setSearchValueError("Enter Valid Email Id.");
        document.getElementById("searchBtn").disabled = true;
      }
      return;
    }
  };

  return (
    <Card.Body>
      <Col>
        <div>
          <div className="w-100 bg-black text-white fs-5 p-2 pl-4 px-4">
            <lable>Select Parameter</lable>
          </div>
          <div className="filter-field-area">
            <div className="form-group mb-3">
              <select
                className="form-control"
                value={searchOption}
                onChange={(e) => {
                  setSearchOption(e.target.value);
                  setSearchValue("");
                  setSearchValueError("");
                  setErrorText("");
                }}
              >
                <option className="justify-content-between" value="email">
                  e-Mail
                </option>
                <option value="mobile">Mobile</option>
                <option value="payerVPA">VPA / UPI ID </option>
              </select>
            </div>
            <div className="form-group mb-2">
              <input
                type={searchOption === "mobile" ? "tel" : "text"}
                className="advanced-input-box form-control"
                value={searchValue}
                onChange={(e) => handleInputChange(e)}
                onBlur={handelValidations}
                placeholder={
                  searchOption === "email"
                    ? "Enter e-Mail"
                    : searchOption === "payerVPA"
                    ? "Enter Payer VPA"
                    : searchOption === "mobile"
                    ? "Enter Mobile"
                    : ""
                }
              />
              {searchValueError !== "" ? (
                <span id="searchValErr" className=" text-danger">
                  {`${searchValueError}`}
                </span>
              ) : (
                ""
              )}
              {errorText !== "" ? (
                <p style={{ color: "red" }}>{errorText}</p>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </Col>
      <Col>
        <div className="w-100 d-flex flex-end">
          <Button
            variant="outline-danger"
            size="sm"
            className="text-capitlize"
            onClick={handleModalClose}
          >
            Close
          </Button>
          <Button
            variant="outline-primary mx-3"
            size="sm"
            id="searchBtn"
            className="text-capitlize"
            onClick={submitHandler}
          >
            <i className="bi bi-search mx-1"></i> Search
          </Button>
        </div>
      </Col>
    </Card.Body>
  );
};

export default Filter;
