import React from 'react'
import depositIcon from "../../assets/images/icons/deposit.svg";
import totalTransactionIcon from "../../assets/images/icons/Transactions-amount.svg";
import successCount from "../../assets/images/icons/paymen.svg";
import refundCount from "../../assets/images/icons/refund.svg";
import { FaRupeeSign } from 'react-icons/fa';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

 function DashboardBox({data}){
  
    function YesterdayDate() {
      // Get today's date
      const today = new Date();

      // Subtract one day (in milliseconds)
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);

      // Format the result
      const options = { year: "numeric", month: "long", day: "numeric" };
      const formattedYesterday = yesterday.toLocaleDateString(undefined, options);

      return <span>{formattedYesterday}</span>;
  }

    function getRupeeSignIfTotalAmount(data) {
        if (data.name === "Total Amount (1st Apr 2022 - 31 Mar 2024)" || data.name === "Total Amount" || data.name === "Total Amount Till") {
          return true;
        } 
       
        else  {
          return false;
        }
      }
  return (
    <Card className="shadow-cst p-2 border-0 mb-3 dashboard-boxs">
    <Card.Body>
        <div className="ds-card-icon">
            <img src={data.icon} alt="deposite" className="ds-icon" />
        </div>
        <button type="button" className="dashboard-card-btn btn-inherit-p stretched-link">
            <div className="ds-card-dt">
                <h2 className="ds-count-value" style={{fontSize:"24px"}}>{getRupeeSignIfTotalAmount(data)?<i class="bi bi-currency-rupee"></i>:''}{ data.value  }</h2>
                <h3 className="ds-label-name" > {data.name }  
                 <span className="date-dash d-block">{data.Active?YesterdayDate():''}</span></h3>
               
            </div>
        </button>
    </Card.Body>
</Card>
  )
}

export default DashboardBox