import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import { Card, Col, Row, Container } from "react-bootstrap";
import jwtDecode from "jwt-decode";
import { fetchVenderByMid } from "../../services/apiService";
import Papa from "papaparse";
import { NavLink } from "react-router-dom";
import { FiDownload } from "react-icons/fi";
function RegisteredVendor({ setactiveButton, setShowLoader }) {
  const [venderData, setVenderData] = useState([]);
  const [venderDataForCSV, setVenderDataForCSV] = useState([]);
  const [fetchData, setFetchData] = useState(true);

  const fetchVenderByMids = async () => {
    const tokenValue = localStorage.getItem("token");
    const token = tokenValue ? tokenValue.slice(1, -1) : null;
    const decodedToken = jwtDecode(token);
    const { mid } = decodedToken;
    setShowLoader(true);
    let res = await fetchVenderByMid(mid, token);
    setShowLoader(false);
    if (res.data === null) {
      setVenderData([]);
      setVenderDataForCSV([])
    } else {

      console.log("e",res.data);

      setVenderData(res?.data);
      setVenderDataForCSV(res?.data)
    }
  };

  venderData.map((entry) => {
    entry["viewDetails"] = (
      <NavLink
        to={`/Vender-Details/${entry.vendorId}`}
        className="nav-link text-center"
        activeClassName="active-link"
      >
        {" "}
        <button className="border border-1 p-2 rounded-3  text-light bg-primary p-0 m-0 btn-sm">
          {" "}
          View Details
        </button>
      </NavLink>
    );
    return entry;
  });

  useEffect(() => {
    if (fetchData) {
      fetchVenderByMids();
      setFetchData(false);
    }
  }, []);

  const column = [
    {
      label: "Vendor Name",
      field: "fullName",
      sort: "asc",
      width: "auto",
    },
    {
      label: "e-Mail",
      field: "email",
      sort: "asc",
      width: "auto",
    },
    {
      label: " Mobile Number",
      field: "mobile",
      sort: "asc",
      width: "850px",
    },
    {
      label: " Category",
      field: "category",
      sort: "asc",
      width: "850px",
    },
    {
      label: "Status",
      field: "vendorStatus",
      sort: "asc",
      width: "850px",
    },
    {
      label: "Action",
      field: "viewDetails",
      sort: "asc",
      width: "850px",
    },
  ];

  function handleDownloadExcel() {
    try {
      // Assuming you have access to the originalData
      const apiData = venderDataForCSV;

      // Extract the header labels from the data (adjust this based on your data structure)
      const headerLabels = Object.keys(apiData[0]).slice(0, -1);

      // Extract the data from the originalData
      const apiRows = apiData.map((item) => Object.values(item).slice(0, -1));

      // Combine header with data
      const dataWithHeader = [headerLabels, ...apiRows];

      // Convert the data with header to CSV format using PapaParse
      const csv = Papa.unparse(dataWithHeader);

      // Create a Blob object from the CSV data
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "MyVenders.csv"; // Change the file name as needed
      link.style.display = "none";

      document.body.appendChild(link);
      link.click();

      // Clean up by removing the temporary element
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  return (
    <>
      <section className="dashboard-header-sec merchant-tool-sec">
        <Container>
          <div className="ds-header-sec">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-4 ds-header-card">
                  <Card.Body>
                    <Col>
                      <MDBDataTable
                        className=" dataTable overflow-x-scroll"
                        data={{
                          columns: column,
                          rows: venderData,
                        }}
                        footer="none"
                        striped
                        bordered
                        entries={50}
                        exportToCSV
                        responsive
                        theadColor="#333"
                      />
                    </Col>
                  </Card.Body>
                </Card>
                {/* ./card */}
              </Col>
            </Row>
            <button
              className="advanced-btn btn btn-primary text-capitlize mt-2"
              onClick={handleDownloadExcel}
            >
              <FiDownload className="d-none" />
              <i class="bi bi-arrow-down-circle"></i> Download CSV
            </button>
          </div>
        </Container>
      </section>
    </>
  );
}

export default RegisteredVendor;