import { MDBDataTable } from "mdbreact";
import React, { useState } from "react";
import CallbackFile from "../../assets/templateFiles/callback.csv";
import PayoutCallback from "../../assets/templateFiles/payoutCallback.csv";
import { Card, Col, Container, Row } from "react-bootstrap";
import {
  getKeyPressed,
  stopSpaceInput,
  stopSpecialCharacterInput,
} from "../../utils/validations";
import {
  saveMissingCallBack,
  uploadCallbackCSV,
  uploadPayoutTxnCallbackCSV,
} from "../../services/apiService";
import jwtDecode from "jwt-decode";
import swal from "sweetalert";

const TransactionCallback = ({ reportType }) => {
  const [orderNumber, setOrderNumber] = useState("");
  const [pressedKeyName, setPressedKeyName] = useState("");
  const [showUploadButton, setShowUploadButton] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [failureMessage, setFailureMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [txnCallbackType, setTxnCallbackType] = useState("OrderNum");
  const tokenValue = localStorage.getItem("token");
  const token = tokenValue ? tokenValue.slice(1, -1) : null;
  const decodedToken = jwtDecode(token);
  const { mid } = decodedToken;

  const handelInputData = (e) => {
    if (
      stopSpecialCharacterInput(pressedKeyName) ||
      stopSpaceInput(pressedKeyName)
    ) {
      return;
    }

    document.getElementById("ordNumErr").classList.add("d-none");
    document.getElementById("submitBtnErr").disabled = false;
    setOrderNumber(e.target.value);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    if (orderNumber === "") {
      document.getElementById("ordNumErr").classList.remove("d-none");
      document.getElementById("submitBtnErr").disabled = true;
      return;
    }

    let res = await saveMissingCallBack(mid, token, orderNumber);
    // console.log("res ----", res.statusCode);

    if (res.statusCode === 200) {
      swal({
        title: "Success!",
        text: res.message,
        icon: "success",
      });
      setOrderNumber("");
    } else if (res.statusCode === 304) {
      swal({
        title: "Alert!",
        text: res.message,
        icon: "error",
      });
    }
  };

  const handleFileUpload = (event) => {
    setSelectedFile(event.target.files[0]);
    document.getElementById("csvUploadBtn").disabled = false;
    setShowMessage(false);
    setFailureMessage("");
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      setFailureMessage("Please select a file to upload.");
      setShowMessage(true);
      document.getElementById("csvUploadBtn").disabled = true;
      return;
    }

    console.log(selectedFile);
    const formdata = new FormData();

    formdata.append("file", selectedFile);

    if (reportType != "payoutTxnCallBack") {
      let res = await uploadCallbackCSV(selectedFile, mid, token);
      console.log("res---", res);
      if (res?.statusCode === 422) {
        swal({
          title: "Alert!",
          text: JSON.stringify(res?.data),
          icon: "error",
        });
      }

      if (res?.statusCode === 600) {
        swal({
          title: "Alert!",
          text: res?.message,
          icon: "error",
        });
      }

      if (res?.statusCode === 200) {
        swal({
          title: "Success!",
          text: res?.status,
          icon: "success",
        });
      }
    } else {
      let res = await uploadPayoutTxnCallbackCSV(selectedFile, mid, token);
      console.log("res---", res);
      if (res?.statusCode === 422) {
        swal({
          title: "Alert!",
          text: JSON.stringify(res?.data),
          icon: "error",
        });
      }

      if (res?.statusCode === 600) {
        swal({
          title: "Alert!",
          text: res?.message,
          icon: "error",
        });
      }

      if (res?.statusCode === 200) {
        swal({
          title: "Success!",
          text: res?.status,
          icon: "success",
        });
      }
    }
  };
  console.log(reportType);
  return (
    <>
      <section className="lincpay-dashboard-sec merchant-admin-info-form aadharform-sec">
        <Container>
          {reportType === "payoutTxnCallBack" ? (
            ""
          ) : (
            <p className="text-danger mt-2">
              *Please add Order Number for missing callback response
            </p>
          )}
          <Row className="d-flex justify-content-center">
            {reportType === "payoutTxnCallBack" ? (
              ""
            ) : (
              <div className="w-50 d-flex justify-content-evenly gap-5 mt-5   ">
                <div
                  className={`${
                    txnCallbackType === "OrderNum"
                      ? "rounded-5 text-light bg-dark "
                      : ""
                  }d-flex justify-content-evenly align-items-center gap-3 p-3 py-2`}
                >
                  <input
                    type="radio"
                    className="form-check-input mb-1 "
                    name="bankDetails"
                    checked={txnCallbackType === "OrderNum" ? true : false}
                    onChange={() => setTxnCallbackType("OrderNum")}
                  />
                  <label htmlFor="detailsRadio">
                    <span>Order Number </span>
                  </label>
                </div>
                <div
                  className={`${
                    txnCallbackType === "CSV"
                      ? "rounded-5 text-light bg-dark "
                      : ""
                  }d-flex justify-content-evenly align-items-center gap-3 p-3 py-2`}
                >
                  <input
                    type="radio"
                    className="form-check-input mb-1 "
                    name="bankDetails"
                    checked={txnCallbackType === "CSV" ? true : false}
                    onChange={() => setTxnCallbackType("CSV")}
                  />
                  <label htmlFor="detailsRadio">
                    <span> CSV File </span>
                  </label>
                </div>
              </div>
            )}
          </Row>
          <div className="cart-view-sec mt-4">
            <Col xs={12} lg={12} md={12}>
              {/* Tab content start here */}
              <Card className="shadow-cst border-0 mb-3 chart-boxes merchant-info-form-sec">
                <Card.Body>
                  <div className="merchant-form-info d-flex  flex-column  align-items-center">
                    {txnCallbackType === "OrderNum" &&
                    reportType != "payoutTxnCallBack" ? (
                      <Col xs={12} lg={4} md={6}>
                        <div className="form-group">
                          <label
                            htmlFor="companyPanNumber"
                            className="form-label"
                          >
                            Enter Order No
                            <sup className="text-danger">*</sup>
                          </label>
                          <div className="input-field-validate">
                            <input
                              type="text"
                              value={orderNumber}
                              onKeyDown={(e) =>
                                setPressedKeyName(getKeyPressed(e))
                              }
                              onChange={handelInputData}
                              className="form-control"
                            />

                            <div className="login-btn-sec">
                              <button
                                id="submitBtnErr"
                                type="button"
                                className="btn btn-primary"
                                onClick={handleSearch}
                              >
                                Submit <i class="bi bi-arrow-right"></i>
                              </button>
                            </div>
                          </div>
                          <span id="ordNumErr" className=" text-danger d-none">
                            *Please Fill Transaction Order Number <br />
                          </span>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}

                    {txnCallbackType === "CSV" ||
                    reportType === "payoutTxnCallBack" ? (
                      <Col xs={12} lg={4} md={6} className="">
                        <div className="form-group">
                          <div className="d-flex justify-content-between">
                            <label
                              htmlFor="companyPanNumber"
                              className="form-label"
                            >
                              {reportType==="payoutTxnCallBack"?"Upload missing payout Txn callback CSV file.":" Upload missing callback CSV file with Order ID:"}
                            </label>
                          </div>
                          <div className="input-field-validate">
                            <input
                              type="file"
                              className="form-control"
                              onChange={handleFileUpload}
                            />

                            <div className="login-btn-sec">
                              <button
                                className="btn btn-primary"
                                onClick={handleUpload}
                                id="csvUploadBtn"
                              >
                                Upload
                              </button>
                            </div>
                          </div>
                          {showMessage ? (
                            <span id="ordNumErr" className=" text-danger">
                              *Please Select CSV File. <br />
                            </span>
                          ) : (
                            ""
                          )}
                          {reportType === "payoutTxnCallBack" ? <div className="button-toolbar mb-2 mt-2 d-flex justify-content-end">
                            <a
                              className="btn btn-outline-dark"
                              title="Click here to download Callback file template."
                              href={PayoutCallback}
                              download
                            >
                              <span style={{ fontSize: "12px " }}>
                                Download Template
                              </span>
                            </a>
                          </div> : ( <div className="button-toolbar mb-2 mt-2 d-flex justify-content-end">
                            <a
                              className="btn btn-outline-dark"
                              title="Click here to download Callback file template."
                              href={CallbackFile}
                              download
                            >
                              <span style={{ fontSize: "12px " }}>
                                Download Template
                              </span>
                            </a>
                          </div>)}
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}

                    {/* <canvas id="income" width="auto" height="auto"></canvas> */}
                  </div>
                </Card.Body>
              </Card>
              {/* Tab content end here */}
            </Col>
            {/* ./col */}
          </div>
        </Container>
      </section>
    </>
  );
};

export default TransactionCallback;
