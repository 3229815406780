import React, { useState } from "react";
import axios from "axios";
import styles from "./PasswordChangeForm.module.css";
import jwtDecode from "jwt-decode";
import { Link } from "react-router-dom";
import ResponsiveAppBar from "../onbord/ResponsiveAppBar";
import { SWIPELINC_API } from "../../utils/constant";

const PasswordChangeForm = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "currentPassword") setCurrentPassword(value);
    if (name === "newPassword") setNewPassword(value);
    if (name === "confirmNewPassword") setConfirmNewPassword(value);
  };
  const togglePasswordVisibility = (inputName) => {
    if (inputName === "currentPassword") {
      setShowCurrentPassword(!showCurrentPassword);
    } else if (inputName === "newPassword") {
      setShowNewPassword(!showNewPassword);
    } else if (inputName === "confirmNewPassword") {
      setShowConfirmNewPassword(!showConfirmNewPassword);
    }
  };
  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
    return passwordRegex.test(password);
  };
  const tokenValue = localStorage.getItem("token");
  const token = tokenValue ? tokenValue.slice(1, -1) : null;
  const decodedToken = jwtDecode(token);
  const { mid } = decodedToken;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validatePassword(newPassword)) {
      setError(
        "New password must contain at least 8 characters, 1 capital letter, 1 special character, and 1 numeric value."
      );
      setSuccess("");
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setError("New password and confirm new password do not match.");
      setSuccess("");
      return;
    }
    try {
      const payload = {
        currentPassword: currentPassword,
        mid: mid,
        newPassword: newPassword,
      };

      // Make the PUT request to the API endpoint
      const response = await axios.put(
        `${SWIPELINC_API}MerchantPanel/merchant/onboard/changePassword`,
        payload
      );

      if (response.data.status === "Success") {
        setSuccess("Password changed successfully!");
        // setError("");
      } else {
        setError(response.data.message);
        setSuccess("");
      }
    } catch (error) {
      setError("An error occurred while changing the password.");
      setSuccess("");
    }
  };

  return (
    <>
      <ResponsiveAppBar />
      <h3 className={styles.change_password_heading}>Change password</h3>
      <div className={styles.container_change_pass}>
        <form onSubmit={handleSubmit}>
          <div className={styles.inputContainer}>
            <label
              htmlFor="currentPassword"
              className={styles.change_password_label}
            >
              Current Password
            </label>
            {/* <div className={styles.inputWithIcon}> */}
            <input
              type={showCurrentPassword ? "text" : "password"}
              id="currentPassword"
              className={styles.change_password_input}
              name="currentPassword"
              value={currentPassword}
              onChange={handleChange}
              required
            />
            <i
              id="password_show_icon"
              className={`password-toggle far ${
                showCurrentPassword ? "fa-eye-slash" : "fa-eye"
              }`}
              onClick={() => togglePasswordVisibility("currentPassword")}
            ></i>
          </div>
          {/* </div> */}
          <div className={styles.inputContainer}>
            <label
              htmlFor="newPassword"
              className={styles.change_password_label}
            >
              New Password
            </label>
            <input
              type={showNewPassword ? "text" : "password"}
              id="newPassword"
              className={styles.change_password_input}
              name="newPassword"
              value={newPassword}
              onChange={handleChange}
              required
            />{" "}
            <i
              className={`password-toggle far ${
                showNewPassword ? "fa-eye-slash" : "fa-eye"
              }`}
              onClick={() => togglePasswordVisibility("newPassword")}
            ></i>
          </div>
          <div className={styles.inputContainer}>
            <label
              htmlFor="confirmNewPassword"
              className={styles.change_password_label}
            >
              Confirm New Password
            </label>
            <input
              type={showNewPassword ? "text" : "password"}
              id="confirmNewPassword"
              className={styles.change_password_input}
              name="confirmNewPassword"
              value={confirmNewPassword}
              onChange={handleChange}
              required
            />
            <i
              className={`password-toggle far ${
                showConfirmNewPassword ? "fa-eye-slash" : "fa-eye"
              }`}
              onClick={() => togglePasswordVisibility("confirmNewPassword")}
            ></i>
          </div>
          {error && <div className={styles.error}>{error}</div>}
          {/* {success && <div className={styles.success}>{success}</div>}
           */}
          {success && (
            <div className="popup_container">
              <div className="popup">
                <h3>Password Changed Successfully</h3>
                <p>{success}</p>
                <Link to="/" className="close_button">
                  Okay
                </Link>
              </div>
            </div>
          )}
          <button className={styles.change_password_btn} type="submit">
            Change Password
          </button>
        </form>
      </div>
    </>
  );
};

export default PasswordChangeForm;
