import jwtDecode from "jwt-decode";
import { MDBDataTable } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, NavLink, Row } from "react-bootstrap";
import { fetchVenderByMid, fetchVenderByStatusWithMids } from "../../services/apiService";
import { FiDownload } from "react-icons/fi";
import { Link } from "react-router-dom";

const PendingVenders = () => {

    const [venderData, setVenderData] = useState([]);

    const VenderByStatusWithMid = async () => {
        const tokenValue = localStorage.getItem("token");
        const token = tokenValue ? tokenValue.slice(1, -1) : null;
        const decodedToken = jwtDecode(token);
        const { mid } = decodedToken;

        // setShowLoader(true);
        let res = await fetchVenderByStatusWithMids("Submitted" , mid, token);
        // setShowLoader(false);
        if (res.data === null) {
          setVenderData([]);
        } else {
          setVenderData(res?.data);
        }
      };

      venderData.map((entry) => {
        entry["viewDetails"] = (
          <Link
            to={`/Vender-Details/${entry.vendorId}`}
            className="nav-link text-center"
            activeClassName="active-link"
          >
            
            <button className="border border-1 p-2 rounded-3  text-light bg-primary p-0 m-0 btn-sm">
              
              View Details
            </button>
          </Link>
        );
        return entry;
      });

    const column = [
        {
          label: "Vendor Name",
          field: "fullName",
          sort: "asc",
          width: "auto",
        },
        {
          label: "e-Mail",
          field: "email",
          sort: "asc",
          width: "auto",
        },
        {
          label: " Mobile Number",
          field: "mobile",
          sort: "asc",
          width: "850px",
        },
        {
          label: " Category",
          field: "category",
          sort: "asc",
          width: "850px",
        },
        {
          label: "Status",
          field: "vendorStatus",
          sort: "asc",
          width: "850px",
        },
        {
          label: "Action",
          field: "viewDetails",
          sort: "asc",
          width: "850px",
        },
      ];

      useEffect(() => {
        VenderByStatusWithMid();
      }, []);

  return (
    <>
      <section className="dashboard-header-sec merchant-tool-sec">
        <Container>
          <div className="ds-header-sec">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-4 ds-header-card">
                  <Card.Body>
                    <Col>
                      <MDBDataTable
                        className=" dataTable overflow-x-scroll"
                        data={{
                          columns: column,
                          rows: venderData,
                        }}
                        footer="none"
                        striped
                        bordered
                        entries={50}
                        exportToCSV
                        responsive
                        theadColor="#333"
                      />
                    </Col>
                  </Card.Body>
                </Card>
                {/* ./card */}
              </Col>
            </Row>
            
          </div>
        </Container>
      </section>
    </>
  );
};

export default PendingVenders;
