import React, { useEffect, useState } from "react";
import { Col, Row, Card } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import { viewPayoutRequest } from "../../services/apiService";
import jwtDecode from "jwt-decode";
import BalanceComponent from "./Balance";

const ViewRequestPayoutWithVenderCustomer = ({setShowLoader}) => {
  const [active, setActive] = useState("customer");
  const [activeButton, setActiveButton] = useState(1);
  const [apiResponse, setApiResponse] = useState([]);
  const [errorText, setErrorText] = useState("");
  const tokenValue = localStorage.getItem("token");
  const token = tokenValue ? tokenValue.slice(1, -1) : null;
  const decodedToken = jwtDecode(token);
  const { mid } = decodedToken;

  const handleToolsButtonClick = (reportType, activeButton) => {
    setActiveButton(activeButton);
  };

  const payload = {
    mid: mid,
    payoutFor: `${activeButton === 1 ? "customer" : "vendor"}`,
  };

  const call = async (payload, token, setApiResponse) => {
    try {
      setShowLoader(true)
      const res = await viewPayoutRequest(payload, token);
      // console.log(res);

      if (res.statusCode === 200) {
        setShowLoader(false)
        setApiResponse(res.data);
        setErrorText("");
      } else {
        setErrorText("Internet Disconnected");
        setShowLoader(false)
      }
    } catch (error) {
      setErrorText("Sometthing went wrong");
      // Handle the error here, e.g., display an error message to the user.
    }
  };

  useEffect(() => {
    call(payload, token, setApiResponse);
  }, [activeButton]);

  // const category=(activeButton)=>{
  //   if(activeButton===1 ){
  //       return  {
  //         label: "Category",
  //         field: "category",
  //         sort: "disabled",
  //       }
  //     }else{
  //       return {}
  //     }
  // }

  // const categoryRow= category(activeButton);
 

  const column = [
    {
      label: "Created Date",
      field: "createdDate",
      sort: "asc",
      width: "auto",
    },
    {
      label: "Created Time",
      field: "createdTime",
      sort: "asc",
      width: "auto",
    },
    {
      label: "Vendor Name",
      field: "fullName",
      sort: "disabled",
    },
    ...(activeButton === 2
      ? [
          {
            label: "Category",
            field: "category",
            sort: "asc",
          },
        ]
      : []),
    {
      label: "Mobile",
      field: "mobileNo",
      sort: "asc",
      width: "auto",
    },
    {
      label: "VPA / UPI ID",
      field: "upi",
      sort: "asc",
      width: "auto",
    },
    {
      label: "e-Mail ID",
      field: "email",
      sort: "asc",
      width: "850px",
    },

    {
      label: "Reference Id",
      field: "referenceId",
      sort: "asc",
      width: "auto",
    },
    {
      label: "Transfer Type",
      field: "transferType",
      sort: "asc",
      width: "auto",
    },
    {
      label: "Transaction Id",
      field: "txnId",
      sort: "asc",
      width: "auto",
    },

    {
      label: "Amount",
      field: "amount",
      sort: "asc",
      width: "auto",
    },
    {
      label: "Request Status",
      field: "payoutStatus",
      sort: "disabled",
      width: "400px",
    },
    {
      label: "Transaction Status",
      field: "txnStatus",
      sort: "disabled",
      width: "400px",
    },
  ];
 
  const row = apiResponse.map((item) => {
    return {
      createdDate: item.createdDate !== null ? item.createdDate : "",
      createdTime: item.createdTime !== null ? item.createdTime : "",
      amount: item.amount !== null ? item.amount : "",
  transferType: item.transferType !== null ? item.transferType : "",
  referenceId: item.referenceId !== null ? item.referenceId : "",
  txnId: item.txnId !== null ? item.txnId : "",
  category: item.category !== null ? item.category : "",
  email: item.email !== null ? item.email : "",
  fullName: item.fullName !== null ? item.fullName : "",
  mobileNo: item.mobileNo !== null ? item.mobileNo : "",
  payoutFor: item.payoutFor !== null ? item.payoutFor : "",
  upi: item.upi !== null ? item.upi : "",
  payoutStatus: item.payoutStatus === "reject" ? "REJECTED" : (item.payoutStatus !== null ? item.payoutStatus.toUpperCase() : ""),
  txnStatus: (item.txnStatus === null || item.txnStatus === '' ) ? "" : item.txnStatus === "panding" ? "PENDING" : item.txnStatus.toUpperCase(),
    };
  });
  // console.log(row);
  const data2 = {
    columns: column,
    rows: row,
  };

  return (
    <Row>
      <Col xs={12} lg={12} md={12}>
        <Card className="shadow border-0 mb-4 ds-header-card">
          <Card.Body className="border border-3">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-4 ds-header-card">
                  <Card.Body>
                    {/* <Card.Title className="h3">Reports</Card.Title>  */}
                    <div className="merchant-tab-btn">
                      <ul className="report-link nav-report-btn">
                        <li
                          className={activeButton === 1 ? "active" : ""}
                          onClick={() => handleToolsButtonClick("customer", 1)}
                        >
                          Customer
                        </li>
                        <li
                          className={activeButton === 2 ? "active" : ""}
                          onClick={() => handleToolsButtonClick("vendor", 2)}
                        >
                          Vendor
                        </li>
                      </ul>
                      
                    </div>
                  </Card.Body>
                </Card>
                {/* ./card */}
              </Col>
            </Row>

            <Col className="d-flex align-items-center flex-column justify-center">
              {errorText === "" ? (
                <MDBDataTable
                  className="w-100 dataTable overflow-x-scroll"
                  data={data2}
                  footer="none"
                  striped
                  bordered
                  entries={50}
                  exportToCSV
                  responsive
                  theadColor="#333"
                  // small
                />
               ) : (
              //   <div className="w-20 d-flex flex-column  bg-secondary align-items-center p-5 rounded-4 justify-content-around">
              //     <div className="d-flex">
              //       <div className="mx-3 w-50">
              //         <i class="bi bi-emoji-frown fs-1"></i>
              //       </div>
              //       <span className="text-red align-self-center">
              //         {errorText}
              //       </span>
              //     </div>
              //   </div>
              // 
        <div className="no-data-message fs-5 w-100 bg-light fw-semibold rounded-3 px-5 pl-5 p-2 d-flex align-items-center justify-content-center"> No data available. </div>
        )}
            </Col>
          </Card.Body>
        </Card>
        {/* ./card */}
      </Col>
    </Row>
  );
};

export default ViewRequestPayoutWithVenderCustomer;