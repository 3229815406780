import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaRupeeSign } from "react-icons/fa";
import Chart from "../charts/Chart";
import jwtDecode from "jwt-decode";
import {
  FIND_PAYOUT_BALANCE,
  GET_DASHBOARD_AMOUNT_API,
  GET_DASHBOARD_API,
} from "../../utils/constant";
import { GET_DASHBOARD_API_TILLLASTDAY } from "../../utils/constant";
import Linechart from "../charts/MultiLineChart";
import { useDispatch, useSelector } from "react-redux";
// Icon import
import DashboardBox from "./DashboardBox";
import depositIcon from "../../assets/images/icons/deposit.svg";
import totalTransactionIcon from "../../assets/images/icons/Transactions-amount.svg";
import successCount from "../../assets/images/icons/paymen.svg";
import refundCount from "../../assets/images/icons/refund.svg";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import "../../stylesheet/Dashboard.css";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [data1, setData1] = useState([]);
  const [dataAmount, setDataAmount] = useState([]);
  const [payoutBalance, setPayoutBalance] = useState('');
  const [error1, setError1] = useState(false);
  // const storedMid = localStorage.getItem("mid");
  // const slicedMid = storedMid ? storedMid.slice(1, -1) : null;
  // const tokenValue = localStorage.getItem("token");

  // console.log(mid);

  //redux fetched data
  const dispatch = useDispatch();

  // Retrieve token from localStorage
  const token = localStorage.getItem("token");

  // Ensure token and mid are available
  const decodedToken = jwtDecode(token);
  const mid = decodedToken?.mid; // Use optional chaining to avoid errors

  const getData1 = async () => {
    const tokenValue = localStorage.getItem("token");
    const token = tokenValue ? tokenValue.slice(1, -1) : null;
    const decodedToken = jwtDecode(token);
    const { mid } = decodedToken;
    try {
      const response = await axios.get(GET_DASHBOARD_API_TILLLASTDAY + mid, {
        headers: {
          Authentication: "Bearer " + token,
        },
      });
      setData1(response.data);
      // console.log(response.data)
    } catch (error) {
      setError1(true);
      // console.error(error);
    }
  };

  const getData = async () => {
    const tokenValue = localStorage.getItem("token");
    const token = tokenValue ? tokenValue.slice(1, -1) : null;
    const decodedToken = jwtDecode(token);
    const { mid } = decodedToken;
    try {
      const response = await axios.get(GET_DASHBOARD_API + mid, {
        headers: {
          Authentication: "Bearer " + token,
        },
      });
      setData(response.data);
      // console.log(response.data)
    } catch (error) {
      setError(true);
      // console.error(error);
    }
  };

  const getDataAmount = async () => {
    const tokenValue = localStorage.getItem("token");
    const token = tokenValue ? tokenValue.slice(1, -1) : null;
    const decodedToken = jwtDecode(token);
    const { mid } = decodedToken;
    try {
      const response = await axios.get(GET_DASHBOARD_AMOUNT_API + mid, {
        headers: {
          Authentication: "Bearer " + token,
        },
      });
      setDataAmount(response.data.responseData);
      // console.log(response.data)
    } catch (error) {
      // console.error(error);
    }
  };
  // const getDataPayoutBalance = async () => {
  //   const tokenValue = localStorage.getItem("token");
  //   const token = tokenValue ? tokenValue.slice(1, -1) : null;
  //   const decodedToken = jwtDecode(token);
  //   const { mid } = decodedToken;
  //   try {
  //     const response = await axios.get( FIND_PAYOUT_BALANCE  + mid, {
  //       headers: {
  //         Authentication: "Bearer " + token,
  //       },
  //     });
  //     setPayoutBalance(response.data.data[0]);
  //     console.log(payoutBalance)
  //   } catch (error) {
  //     // console.error(error);
  //   }
  // };

  console.log(dataAmount);
  useEffect(() => {
    getData();
    getData1();
    // getDataPayoutBalance();
    getDataAmount();
  }, []); //eslint-disable-line

  if (error) {
    return (
      <div>
        <h1 className="Error-msg">Error occurred while fetching data.</h1>
      </div>
    );
  }

  const BoxArrayUp = [
    {
      name: "Total Amount",
      value: data?.responseData?.totalAmt ? data?.responseData?.totalAmt : "0",
      icon: depositIcon,
    },
    {
      name: "Total Transactions",
      value: data?.responseData?.totalCount
        ? data?.responseData?.totalCount
        : "0",
      icon: depositIcon,
    },
  ];

  const BoxArray = [
    {
      name: "Total Settled Amount",
      value: dataAmount?.settlmentAmout ? dataAmount.settlmentAmout : "0",
      icon: depositIcon,
    },
    {
      name: "Total Deposit Amount",
      // value:  "0",
      value: dataAmount?.securityDepositAmount
        ? dataAmount.securityDepositAmount
        : "0",

      icon: depositIcon,
    },
    {
      name: "Total Commission Earned Amount",
      value: dataAmount?.totalCommisionEarnedAmount
        ? dataAmount.totalCommisionEarnedAmount
        : "0",
      icon: depositIcon,
    },
    {
      name: "Total Chargeback Amount",
      value: dataAmount?.chragebackAmount ? dataAmount.chragebackAmount : "0",
      icon: depositIcon,
    },
    {
      name: "Total Hold Amount",
      value: dataAmount?.holdAmount ? dataAmount.holdAmount : "0",
      icon: depositIcon,
    },
    {
      name: "Total Withdrawal Amount",
      value: dataAmount?.withdrawalAmount ? dataAmount.withdrawalAmount : "0",
      icon: depositIcon,
    },

    {
      name: "Total Payout Amount",
      value: dataAmount?.payAmount ? dataAmount.payAmount : "0",
      icon: depositIcon,
    },
    {
      name: "Total Wallet Balance",
      // value: "0",
      value: dataAmount?.totalwalletBalance
        ? dataAmount.totalwalletBalance
        : "0",
      icon: depositIcon,
    },
    {
      name: "Gurantee Amount",
      value: dataAmount?.guranteeAmount ? dataAmount.guranteeAmount : "0",
      icon: depositIcon,
    },
    {
      name: "Available Credit Amount",
      value: dataAmount?.dropCreditAmount ? dataAmount.dropCreditAmount : "0",
      icon: depositIcon,
    },
    // {
    //   name: "Total Payout Balance",
    //   value: "0",
    //   icon: depositIcon,
    // },
  ];

  const boxComponents = BoxArray.map((entry, index) => (
    <Col xs={5} lg={2} md={3} key={index}>
      <DashboardBox data={entry} />
    </Col>
  ));
  const boxComponentsUp = BoxArrayUp.map((entry, index) => (
    <Col xs={5} lg={2} md={3} key={index}>
      <DashboardBox data={entry} />
    </Col>
  ));

  return (
    <>
      <section className="lincpay-dashboard-sec">
        <Container>
          <div className="ds-header-sec h-">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-4 ds-header-card">
                  <Card.Body>
                    {/* <Card.Title className="h3">Dashboard</Card.Title> */}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>

          {/* <div className="dasboard-view-count-boxes">
                        <Row>
                            
                        </Row>
                    </div> */}
          <div className="dasboard-view-count-boxes">
            <Row>
              {boxComponentsUp}
              {boxComponents}
            </Row>
          </div>
          <div className="cart-view-sec mt-4">
            <Row>
              <Col xs={12} lg={6} md={12}>
                {/* Tab content start here */}
                <Card className="shadow-cst border-0 mb-3 chart-boxes">
                  <Card.Body>
                    <Card.Title className="h3">
                      Transaction Graph (Last 30 Days)
                    </Card.Title>
                    <div className="card-chart-box">
                      <Linechart />
                    </div>
                    {/* <canvas id="income" width="auto" height="auto"></canvas> */}
                  </Card.Body>
                </Card>
                {/* Tab content end here */}
              </Col>
              {/* ./col */}
              <Col xs={12} lg={6} md={12}>
                {/* Tab content start here */}
                <Card className="shadow-cst border-0 mb-3 chart-boxes">
                  <Card.Body>
                    <Card.Title className="h3">
                      Mode of Payment (Last 30 Days)
                    </Card.Title>
                    <div className="card-chart-box">
                      <Chart />
                    </div>
                    {/* <canvas id="income1" width="auto" height="auto"></canvas> */}
                  </Card.Body>
                </Card>
                {/* Tab content end here */}
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
}

export default Dashboard;
