import React, { useEffect, useState } from "react";
import { fetchReconceliationDate } from "../../services/apiService";

const ReconcialiationDate = () => {
  const [reconciationDate, setReconciationDate] = useState("");

  const tokenValue = localStorage.getItem("token");
  const token = tokenValue ? tokenValue.slice(1, -1) : "";

  const fetchData = async () => {
    try {
      const response = await fetchReconceliationDate(token);

      console.log("response", response.statusCode);

      if (response.statusCode === 200) {
        setReconciationDate(response.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchData(token);
  }, []);
  // const Date=convertDate(reconciationDate)

  return (
    <div className="w-50 d-flex p-4 border border-2 bolder-grey mb-2 rounded-2">
      <div className="fs-5">Last Reconciliation Date :-</div>
      <div className="fs-5 mx-2">{reconciationDate}</div>
    </div>
  );
};

export default ReconcialiationDate;
