import React from "react";
import { MDBDataTable } from "mdbreact";
import { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
// import { useNavigate, useParams } from 'react-router-dom';
import { SWIPELINC_API } from "../../utils/constant";
import axios from "axios";
import jwtDecode from "jwt-decode";
const CreditReport = () => {
  const [data, setData] = useState();
  const [activeButton, setActiveButton] = useState(1);
  // const [activeButton, setActiveButton] = useState(1);
  // const token = localStorage.getItem("token");
  // const [showTable, setShowTable] = useState(false);
  const tokenValue = localStorage.getItem("token");
  const token = tokenValue ? tokenValue.slice(1, -1) : null;
  const decodedToken = jwtDecode(token);
  const { mid } = decodedToken;

  function getSearchFunctionText(activeButton) {
    let searchFunctionText;

    if (activeButton === 1) {
      searchFunctionText = "getAll";
    } else if (activeButton === 2) {
      searchFunctionText = "getAllDebit";
    }

    return searchFunctionText;
  }

  const GetDataForCreditReport = (token) => {
    axios
      .get(
        `${SWIPELINC_API}MerchantPanel/${getSearchFunctionText(activeButton)}`,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          setData(response.data.data);
        } else {
          setData([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // setShowTable(false);
      });
  };
  useEffect(() => {
    setData([]);
    GetDataForCreditReport(token);
  }, [activeButton]);

  const handleToolsButtonClick = (activeButton) => {
    setActiveButton(activeButton);
  };
  const columnsForDebit = [
    { label: "Date", field: "createDate", width: "auto" },
    { label: "Time", field: "createTime", width: "auto" },
    // { label: "Merchant Name", field: "fullName", width: "auto" },
    // { label: "Merchant Id", field: "mid", width: "auto" },
    { label: "Amount", field: "orignalAmount", width: "auto" },
    // { label: "Service Charges", field: "deductedAmount", width: "auto" },

    // {
    //   label: "Charges In Percentage",
    //   field: "serviceChargePercentage",
    //   width: "auto",
    // },
    // { label: "Charges Amount Value", field: "settlmentTime", width: "auto" },
    {
      label: "Remark",
      field: "txnType",
      width: "auto",
    },
  ];
  const columnsForCredit = [
    { label: "Date", field: "createDate", width: "auto" },
    { label: "Time", field: "createTime", width: "auto" },
    // { label: "Merchant Name", field: "fullName", width: "auto" },
    // { label: "Merchant Id", field: "mid", width: "auto" },
    { label: "Amount", field: "orignalAmount", width: "auto" },
    { label: "Service Charges", field: "deductedAmount", width: "auto" },

    {
      label: "Charges In Percentage",
      field: "chargeInPercentage",
      width: "auto",
    },
    // { label: "Charges Amount Value", field: "settlmentTime", width: "auto" },
    {
      label: "Charges For Services",
      field: "txnType",
      width: "auto",
    },
  ];

  const column = (activeButton, columnsForCredit, columnsForDebit) => {
    if (activeButton === 1) {
      return columnsForCredit;
    } else if (activeButton === 2) {
      return columnsForDebit;
    }
  };
  const mappedData = data?.map((item) => {
    const mappedItem = {};
    column(activeButton, columnsForCredit, columnsForDebit).forEach(
      (column) => {
        mappedItem[column.field] =
          item[column.field] !== null ? item[column.field] : "-";
      }
    );
    return mappedItem;
  });

  const calculateTotalAmountForSuccess = (data) => {
    return data.reduce( (total, item) => total + parseFloat(item.orignalAmount), 0 )
  };

  const totalAmountForSuccess = calculateTotalAmountForSuccess(mappedData?.length ? mappedData : []).toFixed(2);

  return (
    <>
      <Container>
        <Row>
          <Col>
            <div className="merchant-tab-btn">
              <ul className="report-link nav-report-btn">
                <li
                  className={activeButton === 1 ? "active" : ""}
                  onClick={() => handleToolsButtonClick(1)}
                >
                  Credit Transactions
                </li>
                <li
                  className={activeButton === 2 ? "active" : ""}
                  onClick={() => handleToolsButtonClick(2)}
                >
                  Credit Added
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={12} md={12}>
            <Card className="shadow border-0 my-4">
              <Card.Body>
                <MDBDataTable
                  className="dataTable"
                  striped
                  bordered
                  small
                  data={{
                    columns: column(
                      activeButton,
                      columnsForCredit,
                      columnsForDebit
                    ),
                    rows: mappedData,
                  }}
                  searching={true}
                  responsive
                  sorting={true}
                  hover
                  footer="none"
                />

                <div className="d-flex align-items-center justify-content-end mt-3 ">

                  <div className="d-flex align-items-center justify-content-end w-25 gap-4 border boeder-1 p-2 ">
                    <lable className="fs-4 w-50">Total Amount</lable>
                    <span className="fs-4 fw-bold w-50 border boeder-2 rounded-2 p-1">
                      {totalAmountForSuccess}
                    </span>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CreditReport;