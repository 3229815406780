import React, { useState } from "react";
import { Col,Row,Container,Button,Card } from "react-bootstrap";
import RegisterPayout from "./RegisterPayout";
import PayoutTXNStatus from "./PayoutTXNStatus";
import ViewRequestPayout from "../reports/ViewRequestPayout";
import ViewRequestPayoutWithVenderCustomer from "./ViewRequestPayoutWithVenderCustomer";
const PayoutSection = ({setShowLoader}) => {
  const [activeButton, setactiveButton] = useState(1);
  const [reportType, setReportType] = useState("payoutThrough");

  const handleToolsButtonClick = (type, buttonNumber) => {
    setReportType(type);
    setactiveButton(buttonNumber);
  };

  return (
    <>
      <section className="dashboard-header-sec merchant-tool-sec">
        <Container>
          <div className="ds-header-sec">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-4 ds-header-card">
                  <Card.Body>
                    {/* <Card.Title className="h3">Reports</Card.Title>  */}
                    <div className="merchant-tab-btn">
                      <ul className="report-link nav-report-btn">
                        <li
                          className={activeButton === 1 ? "active" : ""}
                          onClick={() =>
                            handleToolsButtonClick("payoutThrough", 1)
                          }
                        >
                          Payout Through
                        </li>
                        <li
                          className={activeButton === 2 ? "active" : ""}
                          onClick={() =>
                            handleToolsButtonClick("viewRequests", 2)
                          }
                        >
                          View Payout Requests
                        </li>
                        <li
                          className={activeButton === 3 ? "active" : ""}
                          onClick={() =>
                            handleToolsButtonClick("TXNStatus", 3)
                          }
                        >
                          Payout TXN Status
                        </li>
                      </ul>
                    </div>
                  </Card.Body>
                </Card>
                {/* ./card */}
              </Col>

              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-4">
                  <Card.Body>
                    <div className="merchant-report-view w-100">
                      <div>
                        {reportType === "payoutThrough" ? (
                          <RegisterPayout setShowLoader={setShowLoader}  />
                        ) : reportType === "TXNStatus" ? (
                          <PayoutTXNStatus setShowLoader={setShowLoader} />
                        ) :  reportType === "viewRequests" ? (
                          <ViewRequestPayoutWithVenderCustomer setShowLoader={setShowLoader}  />
                        ) :  ""}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default PayoutSection;