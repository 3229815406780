import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchVenderDetailsByVenderId } from "../../../services/apiService";

const VenderDetails = () => {
  const [data, setData] = useState({
    accountNumber: "",
    bankName: "",
    branchName: "",
    category: "",
    email: "",
    fullName: "",
    ifscCode: "",
    mobile: "",
    parentMid: "",
    updateDate: "",
    upi: "",
    vendorId: "",
    vendorStatus: "",
  });
  const { venderId } = useParams();
  const [fetchData, setFetchData] = useState(true);
  const navigate = useNavigate();

  const fetchVenderByVenderId = async () => {
    const tokenValue = localStorage.getItem("token");
    const token = tokenValue ? tokenValue.slice(1, -1) : null;
    let res = await fetchVenderDetailsByVenderId(venderId, token);
    setData(res.data);
    console.log(res.data);
    console.log("date",new Date(res.data.updateDate));
  };

  // const columns = [
  //   { label: "MID", field: "vendorId", width: "auto" },
  //   { label: "Vender Name", field: "fullName", width: "auto" },
  //   { label: "Mobile Number", field: "mobile", width: "auto" },
  //   { label: "e-Mail", field: "email", width: "auto" },
  //   { label: "View Detail", field: "viewDetails", width: "auto" },
  // ];

  useEffect(() => {
    if (fetchData) fetchVenderByVenderId();
    setFetchData(false);
  }, [data]);

  console.log(venderId);

  return (
    <>
      <main
        className="dashboardPage d-flex align-items-center flex-column text-ca"
        style={{ marginLeft: "0%" }}
      >
        <div className="d-flex align-items-center flex-column shadow-lg rounded-5 p-5 mt-5 w-75">
          <div className="excel-uploader mt-2">
            <h2 className="heading text-decoration-none">
              <span> Vendor Details</span>
            </h2>
          </div>
          <div className="w-75">
            <div className="d-flex px-5 border justify-content-between ">
              <div className="border-end  w-50 p-2">
                <b>
                  <span>Vendor ID</span>
                </b>
              </div>
              <div className=" w-50 p-2">
                <span className="mx-5">{data.vendorId === "" ? "N/A" : data.vendorId}</span>
              </div>
            </div>
            <div className="d-flex px-5 border justify-content-between ">
              <div className="border-end  w-50 p-2">
                <b>
                  <span>Full Name</span>
                </b>
              </div>
              <div className=" w-50 p-2">
                <span className="mx-5">{data.fullName === "" ? "N/A" : data.fullName}</span>
              </div>
            </div>

            <div className="d-flex px-5 border justify-content-between ">
              <div className="border-end  w-50 p-2">
                <b>
                  <span>e-Mail ID</span>
                </b>
              </div>
              <div className=" w-50 p-2">
                <span className="mx-5">{data.email === "" ? "N/A" : data.email}</span>
              </div>
            </div>
            <div className="d-flex px-5 border justify-content-between ">
              <div className="border-end  w-50 p-2">
                <b>
                  <span>Mobile</span>
                </b>
              </div>
              <div className=" w-50 p-2">
                <span className="mx-5">{data.mobile === "" ? "N/A" : data.mobile}</span>
              </div>
            </div>
            <div className="d-flex px-5 border justify-content-between ">
              <div className="border-end  w-50 p-2">
                <b>
                  <span>Aadhaar Card</span>
                </b>
              </div>
              <div className=" w-50 p-2">
                <span className="mx-5">{data.aadhaarNo === "" ? "N/A" : data.aadhaarNo}</span>
              </div>
            </div>
            <div className="d-flex px-5 border justify-content-between ">
              <div className="border-end  w-50 p-2">
                <b>
                  <span>PAN Number</span>
                </b>
              </div>
              <div className=" w-50 p-2">
                <span className="mx-5">{data.panNumber === "" ? "N/A" : data.panNumber}</span>
              </div>
            </div>
            <div className="d-flex px-5 border justify-content-between ">
              <div className="border-end  w-50 p-2">
                <b>
                  <span>Bank Name</span>
                </b>
              </div>
              <div className=" w-50 p-2">
                <span className="mx-5">
                  {data.bankName === "" ? "N/A" : data.bankName}
                </span>
              </div>
            </div>
            <div className="d-flex px-5 border justify-content-between ">
              <div className="border-end  w-50 p-2">
                <b>
                  <span>Account Number</span>
                </b>
              </div>
              <div className=" w-50 p-2">
                <span className="mx-5">
                  {data.accountNumber === "" ? "N/A" : data.accountNumber}
                </span>
              </div>
            </div>
            <div className="d-flex px-5 border justify-content-between ">
              <div className="border-end  w-50 p-2">
                <b>
                  <span>IFSC Code</span>
                </b>
              </div>
              <div className=" w-50 p-2">
                <span className="mx-5">
                  {data.ifscCode === "" ? "N/A" : data.ifscCode}
                </span>
              </div>
            </div>
            <div className="d-flex px-5 border justify-content-between ">
              <div className="border-end  w-50 p-2">
                <b>
                  <span>Branch Name</span>
                </b>
              </div>
              <div className=" w-50 p-2">
                <span className="mx-5">
                  {data.branchName === "" ? "N/A" : data.branchName}
                </span>
              </div>
            </div>
            <div className="d-flex px-5 border justify-content-between ">
              <div className="border-end  w-50 p-2">
                <b>
                  <span>UPI</span>
                </b>
              </div>
              <div className=" w-50 p-2">
                <span className="mx-5">
                  {data.upi === "" ? "N/A" : data.upi}
                </span>
              </div>
            </div>
            <div className="d-flex px-5 border justify-content-between ">
              <div className="border-end  w-50 p-2">
                <b>
                  <span>Approval</span>
                </b>
              </div>
              <div className=" w-50 p-2">
                <span className={`mx-5 ${data.vendorStatus==="Approved" ? "text-success" : data.vendorStatus==="Rejected" ? "text-danger": "text-primary"}`}> <b>{data.vendorStatus===''?"N/A":data.vendorStatus}</b> </span>
              </div>
            </div>
            <div className="d-flex px-5 border justify-content-between ">
              <div className="border-end  w-50 p-2">
                <b>
                  <span>Approve/Reject Date</span>
                </b>
              </div>
              <div className=" w-50 p-2">
                <span className="mx-5"> {data.updateDate === "" ? "N/A" : new Date(data.updateDate).getDate()+ "/" +(new Date(data.updateDate).getMonth()+1)+ "/"+ new Date(data.updateDate).getFullYear()}</span>
              </div>
            </div>
            <div className="d-flex px-5 border justify-content-between ">
              <div className="border-end  w-50 p-2">
                <b>
                  <span>Approve/Reject Time</span>
                </b>
              </div>
              <div className=" w-50 p-2">
                <span className="mx-5"> {data.updateDate === "" ? "N/A" : new Date(data.updateDate).getHours()+":"+new Date(data.updateDate).getMinutes()+":"+new Date(data.updateDate).getSeconds()}</span>
              </div>
            </div>
            <div className="d-flex px-5 border justify-content-between ">
              <div className="border-end  w-50 p-2">
                <b>
                  <span>Rejection Remark</span>
                </b>
              </div>
              <div className=" w-50 p-2">
                <span className="mx-5">
                {data.remark === "" || data.remark===null? "N/A" : data.remark}</span>
              </div>
            </div>

            <div className="d-flex justify-content-end mt-3"> 
            <div className="Advanced-btn">
              <button
                className={`advanced-btn btn btn-primary  text-capitlize active `}
                onClick={()=> { navigate("/Reports");}}
              >
               &#x2190; Go back
              </button>
            </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default VenderDetails;
