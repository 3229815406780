import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import "./CompanyAddForm.css";

import {
  updateCompanyRegisteredAddress,
  updateCompanyAddressPincode,
  updateCompanyState,
  updateCompanyCity,
  updateCommunicationAddress,
  updateCommunicationAddressPincode,
  updateCommunicationAddressState,
  updateCommunicationAddressCity,
  updateAuthorizedAddress,
  updateAuthorizedAddressPincode,
  updateAuthorizedAddressState,
  updateAuthorizedAddressCity,
  updateCompanyRegisteredDistrict,
  updateCommunicationAddressDistrict,
  updateAuthorizedAddressDistrict,
} from "../../store/slices/onBoardSlice";
import { Link, useNavigate } from "react-router-dom";
import ResponsiveAppBar from "./ResponsiveAppBar";
import { SWIPELINC_API } from "../../utils/constant";
import swal from "sweetalert";
import "../../stylesheet/CompanyAddForms.css";
import "../../stylesheet/MerchantForm.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import MyLoader from "../../common/MyLoader";
import {
  getKeyPressed,
  stopAlphabetInput,
  stopNumberCharInput,
  stopSpecialCharacterInput,
} from "../../utils/validations";
const initialState = {
  companyRegisteredAddress: "",
  companyRegisteredPincode: "",
  companyRegisteredState: "",
  companyRegisteredCity: "",
  companyRegisteredDistrict: "",
  isCommunicationAddSameAsRegistered: false,
  principleCommunicationAddress: "",
  principleCommunicationPincode: "",
  principleCommunicationState: "",
  principlCommunicationeCity: "",
  principlCommunicationeDistrict: "",
  isAuthorizedSignatoryAddSameAsComm: false,
  authorizedSignatoryAddress: "",
  authorizedSignatoryPincode: "",
  authorizedSignatoryState: "",
  authorizedSignatoryCity: "",
  authorizedSignatoryDistrict: "",
  typeOfServices: [],
  selectedPaymentTypeIds: [],
  fetchedRegisteredAddPinCodeValues: "",
  fetchedCommunicationAddPinCodeValues: "",
  fetchedAuthorizedAddPinCodeValues: "",
  // typeOfServices: "",
};

const CompanyAddForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [pressedKeyName, setPressedKeyName] = useState("");

  if (!localStorage.getItem("registrationId")) {
    navigate("/EmailForm");
  }

  const {
    otherBusinessCategory,
    businessCategory,
    businessAs,
    panCardNumberVerifyAf,
    panCardNumberAf,
    authorizedPersonNameAf,
    companyName,
    appUrl,
    districtAf,
    stateAf,
    pincodeAf,
    cityAf,
    companyNameAf,
    gstVerified,
    gstNumber,
    businessType,
    companyPanNumberVerified,
    companyPanNumber,
    website,
    firstName,
    lastName,
    aadharNumberAf,
    aadharNumberVerifyAf,
    companyRegisteredAddress,
    companyAddressPincode,
    companyState,
    companyCity,
    companyDistrict,
    communicationAddress,
    communicationAddressPincode,
    communicationAddressState,
    communicationAddressCity,
    communicationAddressDistrict,
    authorizedAddress,
    authorizedAddressPincode,
    authorizedAddressState,
    authorizedAddressCity,
    authorizedAddressDistrict,
    registrationTypeId,
    businessTypeId,
  } = useSelector((state) => state.onBoard);

  useEffect(() => {
    fetchPaymentTypes();
  }, []);

  const getInitialValues = () => {
    const isReturning = localStorage.getItem("isReturning");

    if (isReturning) {
      return {
        ...initialState,
        companyRegisteredAddress: companyRegisteredAddress,
        companyRegisteredPincode: companyAddressPincode,
        companyRegisteredCity: companyCity,
        companyRegisteredDistrict: companyDistrict,
        companyRegisteredState: companyState,
        principleCommunicationAddress: communicationAddress,
        principleCommunicationPincode: communicationAddressPincode,
        principlCommunicationeCity: communicationAddressCity,
        principleCommunicationState: communicationAddressState,
        principlCommunicationeDistrict: communicationAddressDistrict,
        authorizedSignatoryAddress: authorizedAddress,
        authorizedSignatoryPincode: authorizedAddressPincode,
        authorizedSignatoryState: authorizedAddressState,
        authorizedSignatoryCity: authorizedAddressCity,
        authorizedSignatoryDistrict: authorizedAddressDistrict,
      };
    } else {
      // alert("getting from store");
      return initialState;
    }
  };

  const [formData, setFormData] = useState(getInitialValues);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [selectedPaymentTypeId, setSelectedPaymentTypeId] = useState();
  const [fetchedRegisteredAddPinCodes, setFetchedRegisteredAddPinCodes] =
    useState([]);
  const [fetchedCommunicationAddPinCodes, setFetchedCommunicationAddPinCodes] =
    useState([]);
  const [fetchedAuthorizedAddPinCodes, setFetchedAuthorizedAddPinCodes] =
    useState([]);

  const [paymentTypes, setPaymentTypes] = useState([]);

  const [showPopup, setShowPopup] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const setFetchedPinCodesValue = (value, name) => {
    // setFetchedPinCodes()
    // alert("Fetching Pin Code Values")
    // alert(value + ":" + name);
    if (name === "fetchedRegisteredAddPinCodeValues") {
      let data = fetchedRegisteredAddPinCodes.filter(
        (elem) => elem.areaName === value
      );
      // console.log("data", data);
      if (data && data.length > 0) {
        setFormData({
          ...formData,
          companyRegisteredState: data[0]?.stateName,
          companyRegisteredCity: data[0]?.cityName,
          companyRegisteredDistrict: data[0]?.areaName,
          fetchedRegisteredAddPinCodeValues: value,
        });
      } else {
        setFormData({
          ...formData,
          companyRegisteredState: "",
          companyRegisteredCity: "",
          companyRegisteredDistrict: "",
          fetchedRegisteredAddPinCodeValues: "",
        });
      }
    }

    if (name === "fetchedCommunicationAddPinCodeValues") {
      let data = fetchedCommunicationAddPinCodes.filter(
        (elem) => elem.areaName === value
      );
      if (data && data.length > 0) {
        setFormData({
          ...formData,
          principleCommunicationState: data[0]?.stateName,
          principlCommunicationeCity: data[0]?.cityName,
          principlCommunicationeDistrict: data[0]?.areaName,
          fetchedCommunicationAddPinCodeValues: value,
        });
      } else {
        setFormData({
          ...formData,
          principleCommunicationState: "",
          principlCommunicationeCity: "",
          principlCommunicationeDistrict: "",
          fetchedCommunicationAddPinCodeValues: "",
        });
      }
    }

    if (name === "fetchedAuthorizedAddPinCodeValues") {
      let data = fetchedAuthorizedAddPinCodes.filter(
        (elem) => elem.areaName === value
      );

      console.log("data:", data);

      if (data && data.length > 0) {
        setFormData({
          ...formData,
          authorizedSignatoryState: data[0]?.stateName,
          authorizedSignatoryCity: data[0]?.cityName,
          authorizedSignatoryDistrict: data[0]?.areaName,
          fetchedAuthorizedAddPinCodeValues: value,
        });
      } else {
        setFormData({
          ...formData,
          authorizedSignatoryState: "",
          authorizedSignatoryCity: "",
          authorizedSignatoryDistrict: "",
          fetchedAuthorizedAddPinCodeValues: "",
        });
      }
    }
  };

  const handelSameCommunicationAddress = () => {
    if (formData.isCommunicationAddSameAsRegistered === false) {
      setFormData({
        ...formData,
        principleCommunicationAddress: formData.companyRegisteredAddress,
        principleCommunicationPincode: formData.companyRegisteredPincode,
        principleCommunicationState: formData.companyRegisteredState,
        principlCommunicationeCity: formData.companyRegisteredCity,
        principlCommunicationeDistrict: formData.companyRegisteredDistrict,
      });
    }

    if (formData.isCommunicationAddSameAsRegistered === true) {
      setFormData({
        ...formData,
        principleCommunicationAddress: "",
        principleCommunicationPincode: "",
        principleCommunicationState: "",
        principlCommunicationeCity: "",
        principlCommunicationeDistrict: "",
      });
    }
  };

  const handelSameAuthorityAddress = () => {
    if (formData.isAuthorizedSignatoryAddSameAsComm === false) {
      setFormData({
        ...formData,
        authorizedSignatoryAddress: formData.principleCommunicationAddress,
        authorizedSignatoryPincode: formData.principleCommunicationPincode,
        authorizedSignatoryState: formData.principleCommunicationState,
        authorizedSignatoryCity: formData.principlCommunicationeCity,
        authorizedSignatoryDistrict: formData.principlCommunicationeDistrict,
      });
    }

    if (formData.isAuthorizedSignatoryAddSameAsComm === true) {
      setFormData({
        ...formData,
        authorizedSignatoryAddress: "",
        authorizedSignatoryPincode: "",
        authorizedSignatoryState: "",
        authorizedSignatoryCity: "",
        authorizedSignatoryDistrict: "",
      });
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const errors = validateForm();
  //   if (Object.keys(errors).length === 0) {
  //     // Dispatch actions to update Redux store
  //     dispatch(
  //       updateCompanyRegisteredAddress(formData.companyRegisteredAddress)
  //     );
  //     dispatch(updateCompanyAddressPincode(formData.companyPincode));
  //     dispatch(updateCompanyState(formData.companyState));
  //     dispatch(updateCompanyCity(formData.companyCity));
  //     dispatch(
  //       updateCommunicationAddress(formData.principleCommunicationAddress)
  //     );
  //     dispatch(updateCommunicationAddressPincode(formData.principlePincode));
  //     dispatch(updateCommunicationAddressState(formData.principleState));
  //     dispatch(updateCommunicationAddressCity(formData.principleCity));
  //     dispatch(updateTypeOfServices(formData.typeOfServices));
  //     const selectedPaymentType = paymentTypes.find(
  //       (paymentType) => paymentType.paymentservice === formData.typeOfServices
  //     );
  //     if (selectedPaymentType) {
  //       dispatch(updateTypeOfServicesId(selectedPaymentType.paymentTypeId));
  //     }
  //     const selectedPaymentTypeIdArray = selectedPaymentType
  //       ? [selectedPaymentType.paymentTypeId]
  //       : [];
  //     // console.log(formData.selectedPaymentTypeIds);
  //     const payload = {
  //     //   aadhaarNumber: aadharNumberAf,
  //     //   aadhaarVerified: aadharNumberVerifyAf,
  //     //   authorizedPersonFirstName: firstName,
  //     //   authorizedPersonLastName: lastName,
  //     //   authorizedPersonName: authorizedPersonNameAf,
  //     //   authorizedPersonPan: panCardNumberAf,
  //     //   authorizedPersonPanVerified: panCardNumberVerifyAf,
  //     //   businessAs: businessAs,
  //     //   businessTypeId: businessCategoryId ? businessCategoryId : 0,
  //     //   businessTypeName: otherBusinessCategory,
  //     //   communicationAddress: formData.principleCommunicationAddress
  //     //     ? formData.principleCommunicationAddress
  //     //     : formData.companyRegisteredAddress,
  //     //   communicationAddressCity: formData.principleCity
  //     //     ? formData.principleCity
  //     //     : formData.companyCity,
  //     //   communicationAddressDistrict: formData.principleDistrict
  //     //     ? formData.principleDistrict
  //     //     : formData.companyDistrict,
  //     //   communicationAddressPinCode: formData.principlePincode
  //     //     ? formData.principlePincode
  //     //     : formData.companyPincode,
  //     //   communicationAddressState: formData.principleState
  //     //     ? formData.principleState
  //     //     : formData.companyState,
  //     //   companyAddress: formData.companyRegisteredAddress,
  //     //   companyAddressCity: formData.companyCity,
  //     //   companyAddressDistrict: formData.companyDistrict,
  //     //   companyAddressPinCode: formData.companyPincode,
  //     //   companyAddressState: formData.companyState,
  //     //   companyName: companyName,
  //     //   companyPanNumber: companyPanNumber,
  //     //   companyPanVerified: companyPanNumberVerified,
  //     //   companyTypeId: businessTypeId,
  //     //   gstNumber: gstNumber,
  //     //   gstVerified: gstVerified,
  //     //   // paymentTypeId: selectedPaymentTypeIdArray,
  //     //   paymentTypeId: formData.selectedPaymentTypeIds,
  //     //   newCompanyAddress: companyNameAf,
  //     //   referralNo: formData.hasReferralCode ? formData.referralNo : "",
  //     //   registeredOfficeAddress: companyNameAf,
  //     //   registeredOfficeAddressCity: cityAf,
  //     //   registeredOfficeAddressPinCode: pincodeAf,
  //     //   registeredOfficeAddressState: stateAf,
  //     //   registeredOfficeAddressDistrict: districtAf,
  //     //   registrationId: localStorage.getItem("registrationId"),
  //     //   // registrationId: 9777,
  //     //   websiteUrl: website,
  //     // };
  //     // console.log(payload);

  //     try {
  //       // Make the API request
  //       const response = await axios.put(
  //         // "https://api.swipelinc.com/MerchantPanel/merchant/onboard/update",
  //         `${SWIPELINC_API}MerchantPanel/merchant/onboard/update`,
  //         payload
  //       );
  //       // console.log("API response:", response.data);

  //       if (response.data.statusCode === 200) {
  //         // Clear form values
  //         setFormData(initialState);
  //         setIsFormSubmitted(true);
  //         setShowPopup(true);
  //         localStorage.removeItem("AadharForm");
  //         localStorage.removeItem("MerchantInfoForm");
  //         localStorage.removeItem("returningUser");
  //         sessionStorage.removeItem("returningUser");
  //         localStorage.removeItem("registrationId");
  //         swal({
  //           title: "Success!",
  //           text: "Your Application has been submitted Successfully !",
  //           icon: "success",
  //         }).then(function () {
  //           window.location = "https://swipelinc.com/";
  //         });
  //       } else if (response.data.statusCode === 600) {
  //         swal({
  //           title: "ALERT!",
  //           text: response?.data?.message,
  //           icon: "error",
  //         });
  //       }
  //     } catch (error) {
  //       // console.log("Error submitting form:", error);
  //     }
  //   } else {
  //     // Set form errors
  //     setFormErrors(errors);
  //   }
  // };

  // const validatePincode = (pincode) => {
  //   const pincodeRegex = /^\d{6}$/;
  //   return pincodeRegex.test(pincode);
  // };

  // const validateForm = () => {
  //   const errors = {};

  //   if (formData.companyRegisteredAddress.trim() === "") {
  //     errors.companyRegisteredAddress =
  //       "Company Registered Address is required.";
  //   }

  //   if (formData.companyPincode.trim() === "") {
  //     errors.companyPincode = "Company Pincode is required.";
  //   } else if (!validatePincode(formData.companyPincode)) {
  //     errors.companyPincode = "Invalid Company Pincode.";
  //   }

  //   if (formData.companyState.trim() === "") {
  //     errors.companyState = "Company State is required.";
  //   }

  //   if (formData.companyCity.trim() === "") {
  //     errors.companyCity = "Company City is required.";
  //   }

  //   if (!formData.registeredAddressSame) {
  //     if (formData.principleCommunicationAddress.trim() === "") {
  //       errors.principleCommunicationAddress =
  //         "Principle Communication Address is required.";
  //     }

  //     if (formData.principlePincode.trim() === "") {
  //       errors.principlePincode = "Principle Pincode is required.";
  //     } else if (!validatePincode(formData.principlePincode)) {
  //       errors.principlePincode = "Invalid Principle Pincode.";
  //     }

  //     if (formData.principleState.trim() === "") {
  //       errors.principleState = "Principle State is required.";
  //     }

  //     if (formData.principleCity.trim() === "") {
  //       errors.principleCity = "Principle City is required.";
  //     }
  //   }

  //   // Check if any type of service is selected
  //   if (formData.typeOfServices.length === 0) {
  //     errors.typeOfServices = "Type of Services is required.";
  //   }

  //   return errors;
  // };

  const fetchCityStateByPincode = async (pincode, name) => {
    try {
      setShowLoader(true);
      const response = await axios.get(
        // `https://api.swipelinc.com/MerchantPanel/merchant/address/getCityStateByPinCode/${pincode}`
        `${SWIPELINC_API}MerchantPanel/merchant/address/getCityStateByPinCode/${pincode}`
      );
      const data = response.data.data;
      setShowLoader(false);
      if (name === "companyRegisteredPincode") {
        setFetchedRegisteredAddPinCodes(data);
      } else if (name === "principleCommunicationPincode") {
        setFetchedCommunicationAddPinCodes(data);
      } else if (name === "authorizedSignatoryPincode") {
        setFetchedAuthorizedAddPinCodes(data);
      }

      // if (data) {
      //   setFormData((prevFormData) => ({
      //     ...prevFormData,
      //     companyRegisteredState: data[0].stateName,
      //     companyRegisteredCity: data[0].cityName,
      //     companyRegisteredDistrict: data[0].districtName,
      //   }));
      // } else {
      //   setFormData((prevFormData) => ({
      //     ...prevFormData,
      //     companyRegisteredState: "",
      //     companyRegisteredCity: "",
      //     companyRegisteredDistrict: "",
      //   }));
      // }
    } catch (error) {
      // console.log("Error fetching city and state:", error);
    }
  };

  // const fetchCityStateByPincodePrinciple = async (pincode) => {
  //   try {
  //     const response = await axios.get(
  //       // `https://api.swipelinc.com/MerchantPanel/merchant/address/getCityStateByPinCode/${pincode}`
  //       `${SWIPELINC_API}MerchantPanel/merchant/address/getCityStateByPinCode/${pincode}`
  //     );
  //     const data = response.data.data;
  //     if (data) {
  //       setFormData((prevFormData) => ({
  //         ...prevFormData,
  //         principleState: data.stateName,
  //         principleCity: data.cityName,
  //         principleDistrict: data.districtName,
  //       }));
  //     } else {
  //       setFormData((prevFormData) => ({
  //         ...prevFormData,
  //         principleState: "",
  //         principleCity: "",
  //         principleDistrict: "",
  //       }));
  //     }
  //   } catch (error) {
  //     // console.log("Error fetching principle city and state:", error);
  //   }
  // };

  const fetchPaymentTypes = async () => {
    try {
      const response = await axios.get(
        // "https://api.swipelinc.com/MerchantPanel/merchant/paymentType/getALLPaymentType"
        `${SWIPELINC_API}MerchantPanel/merchant/paymentType/getALLPaymentType`
      );
      const data = response.data.data;
      // console.log(data);
      setPaymentTypes(data);
    } catch (error) {
      // console.log("Error fetching payment types:", error);
    }
  };

  const handleTypeOfServicesChange = (event) => {
    const { value, checked } = event.target;
    let updatedTypeOfServices;

    if (checked) {
      // Add the selected value to the array
      updatedTypeOfServices = [...formData.typeOfServices, value];
    } else {
      // Remove the unselected value from the array
      updatedTypeOfServices = formData.typeOfServices.filter(
        (service) => service !== value
      );
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      typeOfServices: updatedTypeOfServices,
    }));

    const selectedPaymentTypeIds = paymentTypes
      .filter((paymentType) =>
        updatedTypeOfServices.includes(paymentType.paymentservice)
      )
      .map((selectedPaymentType) => selectedPaymentType.paymentTypeId);

    setFormData((prevFormData) => ({
      ...prevFormData,
      selectedPaymentTypeIds,
    }));
  };

  const handleBackBtn = () => {
    dispatch(updateCompanyRegisteredAddress(formData.companyRegisteredAddress));
    dispatch(updateCompanyAddressPincode(formData.companyRegisteredPincode));
    dispatch(updateCompanyState(formData.companyRegisteredState));
    dispatch(updateCompanyCity(formData.companyRegisteredCity));
    dispatch(
      updateCompanyRegisteredDistrict(formData.companyRegisteredDistrict)
    );
    dispatch(
      updateCommunicationAddress(formData.principleCommunicationAddress)
    );
    dispatch(
      updateCommunicationAddressPincode(formData.principleCommunicationPincode)
    );
    dispatch(
      updateCommunicationAddressState(formData.principleCommunicationState)
    );
    dispatch(
      updateCommunicationAddressCity(formData.principlCommunicationeCity)
    );
    dispatch(
      updateCommunicationAddressDistrict(
        formData.principlCommunicationeDistrict
      )
    );
    dispatch(updateAuthorizedAddress(formData.authorizedSignatoryAddress));
    dispatch(
      updateAuthorizedAddressPincode(formData.authorizedSignatoryPincode)
    );
    dispatch(updateAuthorizedAddressState(formData.authorizedSignatoryState));
    dispatch(updateAuthorizedAddressCity(formData.authorizedSignatoryCity));
    dispatch(
      updateAuthorizedAddressDistrict(formData.authorizedSignatoryDistrict)
    );
    navigate("/AdharForm");
    localStorage.setItem("isReturning", true);
  };

  const handelPasteEvent = (event, name) => {
    let pastedText = (event.clipboardData || window.clipboardData).getData(
      "text"
    );

    const addressFieldLength = 200;
    const districtLength = 50;
    const StateLength = 50;
    const cityLength = 50;

    console.log(formData.companyRegisteredAddress.length + pastedText.length);

    if (
      (name === "companyRegisteredAddress" &&
        formData.companyRegisteredAddress.length + pastedText.length >
          addressFieldLength) ||
      (name === "authorizedSignatoryAddress" &&
        formData.authorizedSignatoryAddress.length + pastedText.length >
          addressFieldLength) ||
      (name === "principleCommunicationAddress" &&
        formData.principleCommunicationAddress.length + pastedText.length >
          addressFieldLength)
    ) {
      swal({
        title: "Alert!",
        text: `Address must be lesser than ${addressFieldLength} characters`,
        icon: "error",
      });
      return;
    }

    if (
      (name === "companyRegisteredDistrict" &&
        formData.companyRegisteredDistrict.length + pastedText.length >
          districtLength) ||
      (name === "principlCommunicationeDistrict" &&
        formData.principlCommunicationeDistrict.length + pastedText.length >
          districtLength) ||
      (name === "authorizedSignatoryDistrict" &&
        formData.authorizedSignatoryDistrict.length + pastedText.length >
          districtLength)
    ) {
      swal({
        title: "Alert!",
        text: `Locatity must be lesser than ${districtLength} characters`,
        icon: "error",
      });
      return;
    }

    if (
      (name === "companyRegisteredState" &&
        formData.companyRegisteredState.length + pastedText.length >
          StateLength) ||
      (name === "principleCommunicationState" &&
        formData.principleCommunicationState.length + pastedText.length >
          StateLength) ||
      (name === "authorizedSignatoryState" &&
        formData.authorizedSignatoryState.length + pastedText.length >
          StateLength)
    ) {
      swal({
        title: "Alert!",
        text: `State must be lesser than ${StateLength} characters`,
        icon: "error",
      });
      return;
    }

    if (
      (name === "companyRegisteredCity" && pastedText.length > cityLength) ||
      (name === "principlCommunicationeCity" &&
        pastedText.length > cityLength) ||
      (name === "authorizedSignatoryCity" && pastedText.length > cityLength)
    ) {
      swal({
        title: "Alert!",
        text: `City must be lesser than ${cityLength} characters`,
        icon: "error",
      });
      return;
    }
  };

  const handleInputChange = async (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === "checkbox" ? checked : value;

    if (
      pressedKeyName === "Backspace" &&
      (name === "companyRegisteredAddress" ||
        name === "companyRegisteredPincode" ||
        name === "companyRegisteredDistrict" ||
        name === "companyRegisteredState" ||
        name === "companyRegisteredCity" ||
        name === "principleCommunicationAddress" ||
        name === "principleCommunicationPincode" ||
        name === "principlCommunicationeDistrict" ||
        name === "principleCommunicationState" ||
        name === "principlCommunicationeCity" ||
        name === "authorizedSignatoryAddress" ||
        name === "authorizedSignatoryPincode" ||
        name === "authorizedSignatoryDistrict" ||
        name === "authorizedSignatoryState" ||
        name === "authorizedSignatoryCity")
    ) {
      setFormData({ ...formData, [name]: value?.toUpperCase() });
    }

    // stop alphabets and special char input in pincode
    if (
      (stopAlphabetInput(pressedKeyName) ||
        stopSpecialCharacterInput(pressedKeyName)) &&
      (name === "companyRegisteredPincode" ||
        name === "principleCommunicationPincode" ||
        name === "authorizedSignatoryPincode")
    ) {
      return;
    }

    // stop special char input and number input and allow alphabets INPUT in
    // LOCALITY - STATE - CITY
    if (
      (stopNumberCharInput(pressedKeyName) ||
        stopSpecialCharacterInput(pressedKeyName)) &&
      (name === "companyRegisteredDistrict" ||
        name === "companyRegisteredState" ||
        name === "companyRegisteredCity" ||
        name === "principlCommunicationeDistrict" ||
        name === "principleCommunicationState" ||
        name === "principlCommunicationeCity" ||
        name === "authorizedSignatoryDistrict" ||
        name === "authorizedSignatoryState" ||
        name === "authorizedSignatoryCity")
    )
      return;

    const addressFieldLength = 200;
    const pincodeFieldLength = 6;
    const districtLength = 50;
    const StateLength = 50;
    const cityLength = 50;

    // handel VALIDATION ABOUT LENGTH CONSTRAINTS
    if (
      (formData.companyRegisteredAddress.length >= addressFieldLength &&
        name === "companyRegisteredAddress") ||
      (formData.principleCommunicationAddress.length >= addressFieldLength &&
        name === "principleCommunicationAddress") ||
      (formData.authorizedSignatoryAddress.length >= addressFieldLength &&
        name === "authorizedSignatoryAddress") ||
      (formData.companyRegisteredPincode.length >= pincodeFieldLength &&
        name === "companyRegisteredPincode") ||
      (formData.principleCommunicationPincode.length >= pincodeFieldLength &&
        name === "principleCommunicationPincode") ||
      (formData.authorizedSignatoryPincode.length >= pincodeFieldLength &&
        name === "authorizedSignatoryPincode") ||
      (formData.companyRegisteredDistrict.length >= districtLength &&
        name === "companyRegisteredDistrict") ||
      (formData.principlCommunicationeDistrict.length >= districtLength &&
        name === "principlCommunicationeDistrict") ||
      (formData.authorizedSignatoryDistrict.length >= districtLength &&
        name === "authorizedSignatoryDistrict") ||
      (formData.companyRegisteredState.length >= StateLength &&
        name === "companyRegisteredState") ||
      (formData.principleCommunicationState.length >= StateLength &&
        name === "principleCommunicationState") ||
      (formData.authorizedSignatoryState.length >= StateLength &&
        name === "authorizedSignatoryState") ||
      (formData.companyRegisteredCity.length >= cityLength &&
        name === "companyRegisteredCity") ||
      (formData.principlCommunicationeCity.length >= cityLength &&
        name === "principlCommunicationeCity") ||
      (formData.authorizedSignatoryCity.length >= cityLength &&
        name === "authorizedSignatoryCity")
    ) {
      return;
    }

    if (name === "typeOfServices") {
      // Get the selected payment type ID from the selected option
      const selectedPaymentType = paymentTypes.find(
        (paymentType) => paymentType.paymentservice === fieldValue
      );
      if (selectedPaymentType) {
        setSelectedPaymentTypeId(selectedPaymentType.paymentTypeId);
      }
    }

    if (name === "isCommunicationAddSameAsRegistered" || name === "isAuthorizedSignatoryAddSameAsComm" || name === "hasReferralCode") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: fieldValue,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: fieldValue?.toUpperCase(),
      }));
    }

    if (name === "companyRegisteredPincode") {
      if (value.length === 6) {
        fetchCityStateByPincode(value, name);
      }
      // else {
      //   setFormData((prevFormData) => ({
      //     ...prevFormData,
      //     companyRegisteredState: "",
      //     companyRegisteredCity: "",
      //     companyRegisteredDistrict: "",
      //   }));
      // }
    }

    if (name === "principleCommunicationPincode") {
      if (value.length === 6) {
        fetchCityStateByPincode(value, name);
      } else {
        // setFormData((prevFormData) => ({
        //   ...prevFormData,
        //   principleCommunicationState: "",
        //   principlCommunicationeCity: "",
        //   principlCommunicationeDistrict: "",
        // }));
      }
    }

    if (name === "authorizedSignatoryPincode") {
      // alert("fetch 3 pin")
      if (value.length === 6) {
        fetchCityStateByPincode(value, name);
      } else {
        // setFormData({
        //   ...formData,
        //   authorizedSignatoryState: "",
        //   authorizedSignatoryCity: "",
        //   authorizedSignatoryDistrict: "",
        //   fetchedAuthorizedAddPinCodeValues: "",
        // });
      }
    }
    if (
      name === "fetchedCommunicationAddPinCodeValues" ||
      name === "fetchedRegisteredAddPinCodeValues" ||
      name === "fetchedAuthorizedAddPinCodeValues"
    ) {
      setFetchedPinCodesValue(e.target.value, name);
    }
  };

  const checkLengthValidation = () => {
    if (formData.companyRegisteredAddress.length > 200) {
      swal({
        title: "Alert!",
        text: "Company Registered Address must be lesser than 200 characters",
        icon: "error",
      });
      return;
    }

    console.log(formData.companyRegisteredPincode.length, "length");

    if (formData.companyRegisteredCity.length > 50) {
      swal({
        title: "Alert!",
        text: "Company Registered City must be lesser than 50 characters",
        icon: "error",
      });
      return;
    }
  };

  const handelFormSubmit = async (e) => {
    e.preventDefault();

    if (formData.companyRegisteredAddress === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Regisered Address*",
        icon: "error",
      });
      return;
    }

    if (formData.companyRegisteredPincode === "") {
      swal({
        title: "Alert!",
        text: " Please Enter Regisered Address Pincode *",
        icon: "error",
      });
      return;
    }

    if (formData.companyRegisteredDistrict === "") {
      swal({
        title: "Alert!",
        text: " Please Enter Locality*",
        icon: "error",
      });
      return;
    }

    if (formData.companyRegisteredState === "") {
      swal({
        title: "Alert!",
        text: "Please Enter State*",
        icon: "error",
      });
      return;
    }

    if (formData.companyRegisteredCity === "") {
      swal({
        title: "Alert!",
        text: "Please Enter City*",
        icon: "error",
      });
      return;
    }

    if (formData.principleCommunicationAddress === "") {
      swal({
        title: "Alert!",
        text: " Please Enter Communication Address*",
        icon: "error",
      });
      return;
    }

    if (formData.principleCommunicationPincode === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Regisered Address Pincode*",
        icon: "error",
      });
      return;
    }

    if (formData.principlCommunicationeDistrict === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Locality*",
        icon: "error",
      });
      return;
    }

    if (formData.principleCommunicationState === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Communication State*",
        icon: "error",
      });
      return;
    }

    if (formData.principlCommunicationeCity === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Communication City*",
        icon: "error",
      });
      return;
    }

    if (formData.authorizedSignatoryAddress === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Authorized Signatory Address*",
        icon: "error",
      });
      return;
    }

    if (formData.authorizedSignatoryPincode === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Authorized Signatory  PinCode*",
        icon: "error",
      });
      return;
    }

    if (formData.authorizedSignatoryDistrict === "") {
      swal({
        title: "Alert!",
        text: " Please Enter Authorized Signatory Locality*",
        icon: "error",
      });
      return;
    }

    if (formData.authorizedSignatoryState === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Authorized Signatory State*",
        icon: "error",
      });
      return;
    }

    if (formData.authorizedSignatoryCity === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Authorized Signatory City*",
        icon: "error",
      });
      return;
    }

    if (formData.companyRegisteredPincode.length > 6) {
      swal({
        title: "Alert!",
        text: "Company Registered Pincode must be lesser than 6 characters",
        icon: "error",
      });
      return;
    }

    checkLengthValidation();

    if (formData.typeOfServices.length === 0) {
      swal({
        title: "Alert!",
        text: "Please Choose Services You Want !",
        icon: "error",
      });
      return;
    }

    const payload = {
      aadhaarNumber: aadharNumberAf,
      aadhaarVerified: aadharNumberVerifyAf,
      authorizedPersonFirstName: firstName,
      authorizedPersonLastName: lastName,
      authorizedPersonName: firstName + " " + lastName,
      authorizedPersonPan: panCardNumberAf,
      authorizedPersonPanVerified: panCardNumberVerifyAf,
      businessAs: businessAs,
      businessTypeId: businessCategory,
      businessTypeName: otherBusinessCategory,
      communicationAddress: formData.principleCommunicationAddress,
      communicationAddressCity: formData.principlCommunicationeCity,
      communicationAddressAreaName: formData.principlCommunicationeDistrict,
      communicationAddressPinCode: formData.principleCommunicationPincode,
      communicationAddressState: formData.principleCommunicationState,
      companyAddress: formData.companyRegisteredAddress,
      companyAddressCity: formData.companyRegisteredCity,
      companyAddressAreaName: formData.companyRegisteredDistrict,
      companyAddressPinCode: formData.companyRegisteredPincode,
      companyAddressState: formData.companyRegisteredState,
      aapUrl: appUrl,
      companyName: companyName,
      companyPanNumber: companyPanNumber,
      companyPanVerified: companyPanNumberVerified,
      companyTypeId: businessType,
      gstNumber: gstNumber,
      gstVerified: gstVerified,
      // paymentTypeId: selectedPaymentTypeIdArray,
      paymentTypeId: formData.selectedPaymentTypeIds,
      newCompanyAddress: companyNameAf,
      referralNo: formData.hasReferralCode ? formData.referralNo : "",
      registeredOfficeAddress: formData.authorizedSignatoryAddress,
      registeredOfficeAddressCity: formData.authorizedSignatoryCity,
      registeredOfficeAddressPinCode: formData.authorizedSignatoryPincode,
      registeredOfficeAddressState: formData.authorizedSignatoryState,
      registeredOfficeAddressAreaName: formData.authorizedSignatoryDistrict,
      registrationId: localStorage.getItem("registrationId"),
      // registrationId: 9777,
      websiteUrl: website,

      registrationTypeId: registrationTypeId,
    };

    // console.log(payload);

    try {
      // Make the API request
      setShowLoader(true);
      const response = await axios.put(
        // "https://api.swipelinc.com/MerchantPanel/merchant/onboard/update",
        `${SWIPELINC_API}MerchantPanel/merchant/onboard/update`,
        payload
      );
      setShowLoader(false);
      // console.log("API response:", response.data);
      if (response.data.statusCode === 200) {
        // Clear form values
        setFormData(initialState);
        localStorage.removeItem("isReturning");
        setIsFormSubmitted(true);
        // setShowPopup(true);
        localStorage.removeItem("AadharForm");
        localStorage.removeItem("MerchantInfoForm");
        localStorage.removeItem("returningUser");
        sessionStorage.removeItem("returningUser");
        localStorage.removeItem("registrationId");
        swal({
          title: "Success!",
          text: "Your Application has been submitted Successfully !",
          icon: "success",
        }).then(function () {
          window.location = "https://swipelinc.com/";
        });
      } else if (response.data.statusCode === 600) {
        setShowLoader(false);
        swal({
          title: "ALERT!",
          text: response?.data?.message,
          icon: "error",
        });
      } else {
        // alert("helo")
        setShowLoader(false);
        swal({
          title: "ALERT!",
          text: response?.data?.message,
          icon: "error",
        });
      }
    } catch (error) {
      // console.log("Error submitting form:", error);
    }
  };
  return (
    <>
      {showLoader ? <MyLoader /> : ""}
      <ResponsiveAppBar />

      <section className="lincpay-dashboard-sec merchant-admin-info-form company-add-form">
        <Container>
          {/* <div className="ds-header-sec h-">
                        <Row>
                            <Col xs={12} lg={12} md={12}>
                                <Card className="shadow border-0 mb-4 ds-header-card">
                                    <Card.Body>
                                        <Card.Title className="h3">Dashboard</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>  */}
          <div className="cart-view-sec mt-4">
            <Row>
              <Col xs={12} lg={12} md={12}>
                {/* Tab content start here */}
                <Card className="shadow-cst border-0 mb-3 chart-boxes merchant-info-form-sec">
                  <Card.Body>
                    <Card.Title className="h3">
                      Company Address Details
                    </Card.Title>
                    <div className="merchant-form-info">
                      <form
                        className="form-section-form-group"
                        // onSubmit={handleSubmit}
                      >
                        {/* <img src={logo} alt="main-logo" className="main_logo_company" /> */}

                        <Row>
                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label className="form-label">
                                Company Registered Address
                                <sup className="text-danger">*</sup>
                              </label>
                              <input
                                type="text"
                                name="companyRegisteredAddress"
                                className="form-control"
                                value={formData.companyRegisteredAddress}
                                onPaste={(e) =>
                                  handelPasteEvent(
                                    e,
                                    "companyRegisteredAddress"
                                  )
                                }
                                onKeyDown={(e) =>
                                  setPressedKeyName(getKeyPressed(e))
                                }
                                onChange={handleInputChange}
                                required
                              />
                              <div className="d-flex justify-content-end">
                                <span className="text-danger">
                                  {`Remaining Characters ${
                                    200 -
                                    formData.companyRegisteredAddress.length
                                  } / 200`}{" "}
                                </span>
                              </div>
                            </div>
                            {/* ./form-group */}
                          </Col>
                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label className="form-label">
                                Pincode<sup className="text-danger">*</sup>
                              </label>
                              <input
                                type="text"
                                name="companyRegisteredPincode"
                                onKeyDown={(e) => {
                                  if (e.key === " ") {
                                    e.preventDefault();
                                  }
                                  setPressedKeyName(getKeyPressed(e));
                                }}
                                className="form-control"
                                value={formData.companyRegisteredPincode}
                                onChange={handleInputChange}
                                required
                                pattern="\d{6}"
                              />
                            </div>
                          </Col>

                          {fetchedRegisteredAddPinCodes &&
                          fetchedRegisteredAddPinCodes.length > 0 ? (
                            <Col xs={12} lg={4} md={6}>
                              <div className="form-group">
                                <label className="form-label">
                                  Please Select Your Locality
                                  <sup className="text-danger">*</sup>
                                </label>
                                <select
                                  // id="businessType"
                                  name="fetchedRegisteredAddPinCodeValues"
                                  value={
                                    formData.fetchedRegisteredAddPinCodeValues
                                  }
                                  onChange={handleInputChange}
                                >
                                  <option value="">
                                    Select
                                    <span className="star">*</span>
                                  </option>
                                  {fetchedRegisteredAddPinCodes.map(
                                    (type, index) => (
                                      <option
                                        key={type.areaName}
                                        value={type.areaName}
                                      >
                                        {type.areaName}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label className="form-label">
                                Locality
                                <sup className="text-danger">*</sup>{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="companyRegisteredDistrict"
                                value={formData.companyRegisteredDistrict}
                                onPaste={(e) =>
                                  handelPasteEvent(
                                    e,
                                    "companyRegisteredDistrict"
                                  )
                                }
                                onKeyDown={(e) =>
                                  setPressedKeyName(getKeyPressed(e))
                                }
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                          </Col>

                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label className="form-label">
                                State
                                <sup className="text-danger">*</sup>
                              </label>
                              <input
                                type="text"
                                name="companyRegisteredState"
                                value={formData.companyRegisteredState}
                                onPaste={(e) =>
                                  handelPasteEvent(e, "companyRegisteredState")
                                }
                                onKeyDown={(e) =>
                                  setPressedKeyName(getKeyPressed(e))
                                }
                                onChange={handleInputChange}
                                className="form-control"
                                required
                              />
                            </div>
                          </Col>

                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label className="form-label">
                                City
                                <sup className="text-danger">*</sup>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="companyRegisteredCity"
                                value={formData.companyRegisteredCity}
                                onPaste={(e) =>
                                  handelPasteEvent(e, "companyRegisteredCity")
                                }
                                onKeyDown={(e) =>
                                  setPressedKeyName(getKeyPressed(e))
                                }
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                          </Col>

                          <Col xs={12} lg={12} md={12}>
                            <div className="form-group">
                              {/* <label htmlFor="gstNumber" className="form-label">
        GST Number
      </label> */}
                              <div className="input-field-validate check-box-decleration bg-light p-1">
                                <input
                                  type="checkbox"
                                  id="registeredAddressToggle"
                                  className="form-check-input"
                                  name="isCommunicationAddSameAsRegistered"
                                  checked={
                                    formData.isCommunicationAddSameAsRegistered
                                  }
                                  onChange={handleInputChange}
                                  onClick={handelSameCommunicationAddress}
                                />
                                <label
                                  className="form-label"
                                  htmlFor="registeredAddressToggle"
                                >
                                  Same as Company Registered Address
                                </label>
                                {/* <span className="toggle-text">Same as Company Address</span> */}
                              </div>
                            </div>
                          </Col>

                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label className="form-label">
                                Company Communication Address
                                <sup className="text-danger">*</sup>{" "}
                              </label>
                              <input
                                type="text"
                                name="principleCommunicationAddress"
                                className="form-control"
                                value={formData.principleCommunicationAddress}
                                onPaste={(e) =>
                                  handelPasteEvent(
                                    e,
                                    "principleCommunicationAddress"
                                  )
                                }
                                onKeyDown={(e) => {
                                  setPressedKeyName(getKeyPressed(e));
                                }}
                                onChange={
                                  formData.isCommunicationAddSameAsRegistered
                                    ? () => {}
                                    : handleInputChange
                                }
                                required={!formData.registeredAddressSame}
                              />
                              <div className="d-flex justify-content-end">
                                <span className="text-danger">
                                  {`Remaining Characters ${
                                    200 -
                                    formData.principleCommunicationAddress
                                      .length
                                  } / 200`}{" "}
                                </span>
                              </div>
                            </div>
                          </Col>

                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label className="form-label">
                                Pincode
                                <sup className="text-danger">*</sup>{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                onKeyDown={(e) => {
                                  if (e.key === " ") {
                                    e.preventDefault();
                                  }
                                  setPressedKeyName(getKeyPressed(e));
                                }}
                                name="principleCommunicationPincode"
                                value={formData.principleCommunicationPincode}
                                onChange={
                                  formData.isCommunicationAddSameAsRegistered
                                    ? () => {}
                                    : handleInputChange
                                }
                                required={!formData.registeredAddressSame}
                                pattern="\d{6}"
                              />
                            </div>
                          </Col>
                          {fetchedCommunicationAddPinCodes &&
                          fetchedCommunicationAddPinCodes.length > 0 ? (
                            <Col xs={12} lg={4} md={6}>
                              <div className="form-group">
                                <label className="form-label">
                                  Please Select Your Locality
                                  <sup className="text-danger">*</sup>
                                </label>
                                <select
                                  // id="businessType"
                                  name="fetchedCommunicationAddPinCodeValues"
                                  value={
                                    formData.fetchedCommunicationAddPinCodeValues
                                  }
                                  onChange={
                                    formData.isCommunicationAddSameAsRegistered
                                      ? () => {}
                                      : handleInputChange
                                  }
                                >
                                  <option value="">
                                    Select
                                    <span className="star">*</span>
                                  </option>
                                  {fetchedCommunicationAddPinCodes.map(
                                    (type, index) => (
                                      <option
                                        key={type.areaName}
                                        value={type.areaName}
                                      >
                                        {type.areaName}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label className="form-label">
                                Locality
                                <sup className="text-danger">*</sup>{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="principlCommunicationeDistrict"
                                value={formData.principlCommunicationeDistrict}
                                onPaste={(e) =>
                                  handelPasteEvent(
                                    e,
                                    "principlCommunicationeDistrict"
                                  )
                                }
                                onKeyDown={(e) => {
                                  setPressedKeyName(getKeyPressed(e));
                                }}
                                onChange={
                                  formData.isCommunicationAddSameAsRegistered
                                    ? () => {}
                                    : handleInputChange
                                }
                                required
                              />
                            </div>
                          </Col>

                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label className="form-label">
                                State
                                <sup className="text-danger">*</sup>{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="principleCommunicationState"
                                value={formData.principleCommunicationState}
                                onPaste={(e) =>
                                  handelPasteEvent(
                                    e,
                                    "principleCommunicationState"
                                  )
                                }
                                onKeyDown={(e) =>
                                  setPressedKeyName(getKeyPressed(e))
                                }
                                onChange={
                                  formData.isCommunicationAddSameAsRegistered
                                    ? () => {}
                                    : handleInputChange
                                }
                                required={!formData.registeredAddressSame}
                              />
                            </div>
                          </Col>

                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label className="form-label">
                                City
                                <sup className="text-danger">*</sup>{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="principlCommunicationeCity"
                                id="companyForm2"
                                value={formData.principlCommunicationeCity}
                                onPaste={(e) =>
                                  handelPasteEvent(
                                    e,
                                    "principlCommunicationeCity"
                                  )
                                }
                                onKeyDown={(e) =>
                                  setPressedKeyName(getKeyPressed(e))
                                }
                                onChange={
                                  formData.isCommunicationAddSameAsRegistered
                                    ? () => {}
                                    : handleInputChange
                                }
                                required={!formData.registeredAddressSame}
                              />
                            </div>
                          </Col>

                          <Col xs={12} lg={12} md={12}>
                            <div className="form-group">
                              {/* <label htmlFor="gstNumber" className="form-label">
        GST Number
      </label> */}
                              <div className="input-field-validate check-box-decleration bg-light p-1">
                                <input
                                  type="checkbox"
                                  id="registeredAddressToggle"
                                  className="form-check-input"
                                  name="isAuthorizedSignatoryAddSameAsComm"
                                  checked={
                                    formData.isAuthorizedSignatoryAddSameAsComm
                                  }
                                  onChange={handleInputChange}
                                  onClick={handelSameAuthorityAddress}
                                />
                                <label
                                  className="form-label"
                                  htmlFor="registeredAddressToggle"
                                >
                                  Same as Communication Address
                                </label>
                                {/* <span className="toggle-text">Same as Company Address</span> */}
                              </div>
                            </div>
                          </Col>

                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label className="form-label">
                                Authorized Signatory Address
                                <sup className="text-danger">*</sup>{" "}
                              </label>
                              <input
                                type="text"
                                name="authorizedSignatoryAddress"
                                className="form-control"
                                value={formData.authorizedSignatoryAddress}
                                onPaste={(e) =>
                                  handelPasteEvent(
                                    e,
                                    "authorizedSignatoryAddress"
                                  )
                                }
                                onKeyDown={(e) => {
                                  setPressedKeyName(getKeyPressed(e));
                                }}
                                onChange={
                                  formData.isAuthorizedSignatoryAddSameAsComm
                                    ? () => {}
                                    : handleInputChange
                                }
                                required={!formData.registeredAddressSame}
                              />
                              <div className="d-flex justify-content-end">
                                <span className="text-danger">
                                  {`Remaining Characters ${
                                    200 -
                                    formData.authorizedSignatoryAddress.length
                                  } / 200`}{" "}
                                </span>
                              </div>
                            </div>
                          </Col>

                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label className="form-label">
                                Pincode
                                <sup className="text-danger">*</sup>{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                onKeyDown={(e) => {
                                  if (e.key === " ") {
                                    e.preventDefault();
                                  }
                                  setPressedKeyName(getKeyPressed(e));
                                }}
                                name="authorizedSignatoryPincode"
                                value={formData.authorizedSignatoryPincode}
                                onChange={
                                  formData.isAuthorizedSignatoryAddSameAsComm
                                    ? () => {}
                                    : handleInputChange
                                }
                                // required={!formData.registeredAddressSame}
                                pattern="\d{6}"
                              />
                            </div>
                          </Col>
                          {fetchedAuthorizedAddPinCodes &&
                          fetchedAuthorizedAddPinCodes.length > 0 ? (
                            <Col xs={12} lg={4} md={6}>
                              <div className="form-group">
                                <label className="form-label">
                                  Please Select Your Locality
                                  <sup className="text-danger">*</sup>
                                </label>
                                <select
                                  // id="businessType"
                                  name="fetchedAuthorizedAddPinCodeValues"
                                  f
                                  value={
                                    formData.fetchedAuthorizedAddPinCodeValues
                                  }
                                  onChange={
                                    formData.isAuthorizedSignatoryAddSameAsComm
                                      ? () => {}
                                      : handleInputChange
                                  }
                                >
                                  <option value="">
                                    Select
                                    <span className="star">*</span>
                                  </option>
                                  {fetchedAuthorizedAddPinCodes.map(
                                    (type, index) => (
                                      <option
                                        key={type.areaName}
                                        value={type.areaName}
                                      >
                                        {type.areaName}
                                      </option>
                                    )
                                  )}
                                </select>
                                {formErrors.companyState && (
                                  <span className="error-message">
                                    {formErrors.companyState}
                                  </span>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label className="form-label">
                                Locality
                                <sup className="text-danger">*</sup>{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="authorizedSignatoryDistrict"
                                value={formData.authorizedSignatoryDistrict}
                                onPaste={(e) =>
                                  handelPasteEvent(
                                    e,
                                    "authorizedSignatoryDistrict"
                                  )
                                }
                                onKeyDown={(e) =>
                                  setPressedKeyName(getKeyPressed(e))
                                }
                                onChange={
                                  formData.isAuthorizedSignatoryAddSameAsComm
                                    ? () => {}
                                    : handleInputChange
                                }
                                required
                              />
                            </div>
                          </Col>

                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label className="form-label">
                                State
                                <sup className="text-danger">*</sup>{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="authorizedSignatoryState"
                                value={formData.authorizedSignatoryState}
                                onPaste={(e) =>
                                  handelPasteEvent(
                                    e,
                                    "authorizedSignatoryState"
                                  )
                                }
                                onKeyDown={(e) =>
                                  setPressedKeyName(getKeyPressed(e))
                                }
                                onChange={
                                  formData.isAuthorizedSignatoryAddSameAsComm
                                    ? () => {}
                                    : handleInputChange
                                }
                                required={!formData.registeredAddressSame}
                              />
                            </div>
                          </Col>

                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label className="form-label">
                                City
                                <sup className="text-danger">*</sup>{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="authorizedSignatoryCity"
                                id="companyForm2"
                                value={formData.authorizedSignatoryCity}
                                onPaste={(e) =>
                                  handelPasteEvent(e, "authorizedSignatoryCity")
                                }
                                onKeyDown={(e) =>
                                  setPressedKeyName(getKeyPressed(e))
                                }
                                onChange={
                                  formData.isAuthorizedSignatoryAddSameAsComm
                                    ? () => {}
                                    : handleInputChange
                                }
                                required={!formData.registeredAddressSame}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Card.Title className="h3 mt-3">
                          Services Offered
                        </Card.Title>
                        <Row>
                          <Col xs={12} lg={12} md={12}>
                            <div className="form-group">
                              <div className="checkbox-select-services">
                                {paymentTypes?.map((paymentType) => (
                                  <div
                                    className="checkservices-type"
                                    key={paymentType.paymentTypeId}
                                  >
                                    <input
                                      type="checkbox"
                                      style={{ border: "1px solid grey" }}
                                      name="typeOfServices"
                                      className="form-check-input"
                                      value={paymentType.paymentservice}
                                      checked={formData.typeOfServices.includes(
                                        paymentType.paymentservice
                                      )}
                                      onChange={handleTypeOfServicesChange}
                                    />
                                    <label className="form-label">
                                      {paymentType.paymentservice}
                                    </label>
                                  </div>
                                ))}
                              </div>
                              {formErrors.typeOfServices && (
                                <span className="error-message">
                                  {formErrors.typeOfServices}
                                </span>
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Col xs={12} lg={12} md={12}>
                          <div className="form-group">
                            <div className="input-field-validate check-box-decleration">
                              <input
                                type="checkbox"
                                style={{ border: "1px solid grey" }}
                                s
                                id="referralCodeToggle1"
                                className="form-check-input"
                                name="hasReferralCode"
                                checked={formData.hasReferralCode}
                                onChange={handleInputChange}
                              />
                              <label
                                className="form-label"
                                htmlFor="referralCodeToggle"
                              >
                                Do you have a referral code?
                              </label>
                              {/* <span className="toggle-text">Same as Company Address</span> */}
                            </div>
                          </div>
                        </Col>

                        {formData.hasReferralCode && (
                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label className="form-label">
                                Referral Code
                              </label>
                              <input
                                type="text"
                                name="referralNo"
                                className="form-control"
                                value={formData.referralNo}
                                onChange={handleInputChange}
                                required={formData.hasReferralCode}
                              />
                            </div>
                          </Col>
                        )}
                      </form>
                      <Col xs={12} lg={12} md={12}>
                        <div className="d-flex justify-content-end">
                          <div className="form-group  tool-bar-btn mb-0 mt-3">
                            <button
                              className="submit_link_info btn btn-primary"
                              onClick={handleBackBtn}
                            >
                              Back
                            </button>

                            <button
                              className="submit-button submit_link_info btn btn-success"
                              onClick={handelFormSubmit}
                              id="submitDetailsBtn"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </Col>
                    </div>
                  </Card.Body>
                </Card>
                {/* Tab content end here */}
              </Col>
              {/* ./col */}
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default CompanyAddForm;
