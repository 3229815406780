import { createSlice } from "@reduxjs/toolkit";

const onBoardSlice = createSlice({
  name: "onBoardSlice",
  initialState: {
    registeredId: "",
    firstName: "",
    lastName: "",
    businessType: "",
    businessTypeId: "",
    businessCategory: "",
    otherBusinessCategory: "",
    businessCategoryId: "",
    companyName: "",
    companyPanNumber: "",
    businessAs: "",
    website: "",
    gstNumber: "",
    companyRegisteredAddress: "",
    companyAddressPincode: "",
    companyState: "",
    companyCity: "",
    companyDistrict: "",
    communicationAddress: "",
    communicationAddressPincode: "",
    communicationAddressState: "",
    communicationAddressCity: "",
    communicationAddressDistrict: "",
    authorizedAddress:"",
    authorizedAddressPincode:"",
    authorizedAddressState:"",
    authorizedAddressCity:"",
    authorizedAddressDistrict:"",
    typeOfServices: "",
    typeOfServicesId: "",
    companyNameAf: "",
    refNumberAf: "",
    pincodeAf: "",
    stateAf: "",
    cityAf: "",
    districtAf: "",
    authorizedPersonNameAf: "",
    aadharNumberAf: "",
    aadharNumberVerifyAf: "",
    panCardNumberAf: "",
    panCardNumberVerifyAf: "",
    registeredIdEmail: "",
    gstVerified: "",
    companyPanNumberVerified: "",
    registrationTypeId: "",
    appUrl: "",
  },
  reducers: {
    updateRegisteredId: (state, action) => {
      state.registeredId = action.payload;
    },
    updateFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    updateLastName: (state, action) => {
      state.lastName = action.payload;
    },
    updateBusinessType: (state, action) => {
      state.businessType = action.payload;
    },
    updateBusinessTypeId: (state, action) => {
      state.businessTypeId = action.payload;
    },
    updateCompanyName: (state, action) => {
      state.companyName = action.payload;
    },
    updateCompanyPanNumber: (state, action) => {
      state.companyPanNumber = action.payload;
    },
    updateBusinessAs: (state, action) => {
      state.businessAs = action.payload;
    },
    updateWebsite: (state, action) => {
      state.website = action.payload;
    },
    updateBusinessCategory: (state, action) => {
      state.businessCategory = action.payload;
    },
    updateOtherBusinessCategory: (state, action) => {
      state.otherBusinessCategory = action.payload;
    },
    updateBusinessCategoryId: (state, action) => {
      state.businessCategoryId = action.payload;
    },
    updateGstNumber: (state, action) => {
      state.gstNumber = action.payload;
    },
    updateCompanyRegisteredAddress: (state, action) => {
      state.companyRegisteredAddress = action.payload;
    },
    updateCompanyAddressPincode: (state, action) => {
      state.companyAddressPincode = action.payload;
    },
    updateCompanyState: (state, action) => {
      state.companyState = action.payload;
    },
    updateCompanyCity: (state, action) => {
      state.companyCity = action.payload;
    },
    updateCompanyRegisteredDistrict: (state, action) => {
      state.companyDistrict = action.payload;
    },
    updateCommunicationAddress: (state, action) => {
      state.communicationAddress = action.payload;
    },
    updateCommunicationAddressPincode: (state, action) => {
      state.communicationAddressPincode = action.payload;
    },
    updateCommunicationAddressState: (state, action) => {
      state.communicationAddressState = action.payload;
    },
    updateCommunicationAddressCity: (state, action) => {
      state.communicationAddressCity = action.payload;
    },
    updateCommunicationAddressDistrict: (state, action) => {
      state.communicationAddressDistrict = action.payload;
    },
    updateAuthorizedAddress: (state, action) => {
      state.authorizedAddress = action.payload;
    },
    updateAuthorizedAddressPincode: (state, action) => {
      state.authorizedAddressPincode = action.payload;
    },
    updateAuthorizedAddressState: (state, action) => {
      state.authorizedAddressState = action.payload;
    },
    updateAuthorizedAddressCity: (state, action) => {
      state.authorizedAddressCity = action.payload;
    },
    updateAuthorizedAddressDistrict: (state, action) => {
      state.authorizedAddressDistrict = action.payload;
    },
    updateTypeOfServices: (state, action) => {
      state.typeOfServices = action.payload;
    },
    updateTypeOfServicesId: (state, action) => {
      state.typeOfServicesId = action.payload;
    },
    updateCompanyNameAf: (state, action) => {
      state.companyNameAf = action.payload;
    },
    updateRefNumberAf: (state, action) => {
      state.refNumberAf = action.payload;
    },
    updatePincodeAf: (state, action) => {
      state.pincodeAf = action.payload;
    },
    updateStateAf: (state, action) => {
      state.stateAf = action.payload;
    },
    updateCityAf: (state, action) => {
      state.cityAf = action.payload;
    },
    updateDistrictAf: (state, action) => {
      state.districtAf = action.payload;
    },
    updateAuthorizedPersonNameAf: (state, action) => {
      state.authorizedPersonNameAf = action.payload;
    },
    updateAadharNumberAf: (state, action) => {
      state.aadharNumberAf = action.payload;
    },
    updatePanCardNumberAf: (state, action) => {
      state.panCardNumberAf = action.payload;
    },
    updateRegisteredIdEmail: (state, action) => {
      state.registeredIdEmail = action.payload;
    },
    updateGstVerified: (state, action) => {
      state.gstVerified = action.payload;
    },
    updateCompanyPanNumberVerified: (state, action) => {
      state.companyPanNumberVerified = action.payload;
    },
    updateAadharNumberVerifyAf: (state, action) => {
      state.aadharNumberVerifyAf = action.payload;
    },
    updatePanCardNumberVerifyAf: (state, action) => {
      state.panCardNumberVerifyAf = action.payload;
    },
    updateRegistrationTypeId: (state, action) => {
      state.registrationTypeId = action.payload;
    },
    updateAppurl: (state, action) => {
      state.appUrl = action.payload;
    },
  },
});

export const {
  updateRegisteredId,
  updateFirstName,
  updateLastName,
  updateBusinessType,
  updateBusinessTypeId,
  updateCompanyName,
  updateCompanyPanNumber,
  updateBusinessAs,
  updateWebsite,
  updateBusinessCategory,
  updateOtherBusinessCategory,
  updateBusinessCategoryId,
  updateGstNumber,
  updateCompanyRegisteredAddress,
  updateCompanyAddressPincode,
  updateCompanyState,
  updateCompanyCity,
  updateCompanyRegisteredDistrict,
  updateCommunicationAddress,
  updateCommunicationAddressPincode,
  updateCommunicationAddressState,
  updateCommunicationAddressCity,
  updateCommunicationAddressDistrict,
  updateAuthorizedAddress,
  updateAuthorizedAddressPincode,
  updateAuthorizedAddressState,
  updateAuthorizedAddressCity,
  updateAuthorizedAddressDistrict,
  updateTypeOfServices,
  updateTypeOfServicesId,
  updateCompanyNameAf,
  updateRefNumberAf,
  updatePincodeAf,
  updateStateAf,
  updateCityAf,
  updateAuthorizedPersonNameAf,
  updateAadharNumberAf,
  updatePanCardNumberAf,
  updateDistrictAf,
  updateRegisteredIdEmail,
  updateGstVerified,
  updateCompanyPanNumberVerified,
  updateAadharNumberVerifyAf,
  updatePanCardNumberVerifyAf,
  updateRegistrationTypeId,
  updateAppurl,
} = onBoardSlice.actions;

export default onBoardSlice.reducer;
