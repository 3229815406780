import React, { useEffect, useState } from "react";
import WithdrawInAccountOrPayout from "./WithdrawInAccountOrPayout";
import { Card, Col, Container, Row, Button } from "react-bootstrap";
import axios from "axios";
import { SWIPELINC_API } from "../../utils/constant";
import jwtDecode from "jwt-decode";
import swal from "sweetalert";

const WithdrawInPayout = (props) => {
  console.log(props);
  const [activeButton, setactiveButton] = useState(1);
  const [amount, setAmount] = useState("");
  const [error, setError] = useState(null);
  const [transferType, setTransferType] = useState(props.transferType);
  const [virtualAccountType, setVirtualAccountType] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleToolsButtonClick = (buttonNumber) => {
    // setReportType(type);
    setactiveButton(buttonNumber);
  };

  useEffect(() => {
    if (activeButton === 1) {
      setVirtualAccountType("UPI");
      setAmount("");
    } else if (activeButton === 2) {
      setVirtualAccountType("IMPS");
      setAmount("");
    } else {
      setVirtualAccountType("");
    }
  }, [activeButton]);

  const tokenValue = localStorage.getItem("token");
  const token = tokenValue ? tokenValue.slice(1, -1) : null;
  const decodedToken = jwtDecode(token);
  const { mid } = decodedToken;
  console.log(mid);

  const Payload = {
    mid: mid,
    amount: amount,
    transferType: transferType,
    virtualAccountType: virtualAccountType,
  };

  console.log(Payload);
  const handleAmount = (e) => {
    const value = e.target.value;

    const numberPattern = /^[1-9]\d*$/; // Updated pattern to disallow 0 and negative numbers

    if (numberPattern.test(value)) {
      setAmount(value);
      setError("");
    } else {
      setAmount("");
      setError("Withdraw Amount must be a positive number.");
    }
  };

  const handleSubmit = async () => {
    if (isSubmitting || amount.length === 0) {
      // Prevent multiple submissions while one is in progress
      return;
    }

    // Show a confirmation dialog
    swal({
      title: "Are you sure?",
      text: "Do you want to submit this withdrawal request?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willSubmit) => {
      if (willSubmit) {
        // User confirmed, submit the request
        try {
          const response = await axios.post(
            `${SWIPELINC_API}/MerchantPanel/merchant/withdrawal/save`,
            Payload,
            {
              headers: {
                Authentication: "Bearer " + token,
              },
            }
          );
          if(response.data.statusCode === 309) {
            swal({
              title: "Alert",
              text: response.data.message,
              icon: "error",
            });
            setAmount("");
            return 
          }

          if (response.data.statusCode === 200) {
            swal({
              title: "Success",
              text: "Your Withdrawal request has been submitted successfully.",
              icon: "success",
            });
            setAmount("");
          } else {
            swal({
              title: "Failed",
              text: "Your Withdrawal request has been declined.",
              icon: "warning",
            });
          }
        } catch (error) {
          setError(true);
          swal({
            title: "Failed",
            text: "Something went wrong",
            icon: "error",
          });
        } finally {
          setIsSubmitting(false);
        }
      } else {
        // User canceled the submission
        swal("Submission canceled!");
      }
    });
  };

  return (
    <>
      <section className="dashboard-header-sec merchant-tool-sec">
        <Container>
          <div className="ds-header-sec">
            <Row>
              <Col xs={12} lg={12} md={12}>
                {/* <Card className="shadow border-0 mb-4 ds-header-card"> */}
                  <Card.Body>
                    <div className="filter-field-area">
                      <div className="form-group mb-3 d-flex flex-column align-items-center">
                        <div className="merchant-tab-btn mb-4">
                          <ul className="report-link nav-report-btn">
                            <li
                              className={activeButton === 1 ? "active" : ""}
                              onClick={() => handleToolsButtonClick(1)}
                            >
                              To UPI
                            </li>
                            <li
                              className={activeButton === 2 ? "active" : ""}
                              onClick={() => handleToolsButtonClick(2)}
                            >
                              To IMPS
                            </li>
                          </ul>
                        </div>

                        {/* <div className="w-50">
                          <label className="bg-dark text-white rounded-3 border-none w-100 pl-5 p-2">
                            Enter Amounts
                          </label>
                          <input
                            className="advanced-input-box form-control w-100"
                            type="number"
                            value={amount}
                            onChange={handleAmount}
                            placeholder="Enter Amount"
                          />
                          <Button
                            variant="outline-primary"
                            size="sm"
                            className="text-capitlize mt-2 mb-2 justify-self-end d-flex gap-2 justify-content-center align-items-center"
                            onClick={handleSubmit}
                          >
                            Submit <i class="bi bi-arrow-right"></i>
                          </Button>
                        </div> */}
                      </div>
                    </div>
                    <div className="merchant-form-info d-flex justify-content-center">
                      <Col xs={12} lg={4} md={6}>
                        <div className="form-group">
                          <label
                            htmlFor="companyPanNumber"
                            className="form-label"
                          >
                            Enter Amount
                            <sup className="text-danger">*</sup>
                          </label>
                          <div className="input-field-validate">
                            <input
                              type="number"
                              value={amount}
                              onChange={handleAmount}
                              className="form-control"
                            />

                            <div className="login-btn-sec">
                              <button
                                id="companyPanVerifyBtn"
                                type="button"
                                className="btn btn-primary"
                                onClick={handleSubmit}
                              >
                                Submit <i class="bi bi-arrow-right"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </div>
                  </Card.Body>
                {/* </Card> */}
                {/* ./card */}
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default WithdrawInPayout;
