import React, { useState } from "react";

import Logo from "../../assets/images/Logo.png";
import { useLoginForm } from "../../services/apiService";
import { Link } from "react-router-dom";

import "../../stylesheet/Login.css";
import "../../stylesheet/icomoon.css";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";
import swal from "sweetalert";
import { config } from "../../utils/helper";

function LoginForm() {
    const { userName, setuserName, password, setPassword, error, errorMessages, handleSubmit, isFormSubmitted, showPopUp, setShowPopUp } = useLoginForm();

    const [showPassword, setShowPassword] = useState(false);

    const {logoPath,Name}=config(process.env.REACT_APP_SERVER_ENVIRONMENT)
    const AppNameCapitalized=Name
    const AppName = AppNameCapitalized.toUpperCase()  
    if(showPopUp) {
        swal({
            title: "ALERT!",
            text: "Your Application Is Under Process!!",
            icon: "warning",
            timer: 2000,
          })
          setShowPopUp(!showPopUp)
    }

    console.log("REACT_APP_IS_SERVER_ENV", process.env.REACT_APP_IS_SERVER_ENV);

    return (
        <section className="swiperlinc_login login_inner">
            <Container>
                <Row>
                    <Col xs={12} lg={6} md={6} sm={12} className="login-bg">
                        <div class="welcome-login">
                            <div class="login-banner">
                                <img src={logoPath} alt="logo" class="img-fluid" />
                            </div>
                            <div class="mentor-course text-center">
                                <h2>Welcome to {AppName}</h2>
                                <p className="text-start">
                                    <b className="d-block mb-2">Dear Valued Merchant,</b>
                                    We're excited to welcome you to the {AppNameCapitalized} family! As you log in to our merchant panel, you're stepping into a world of seamless payment solutions and unparalleled convenience. <br />
                                    At {AppNameCapitalized}, we're dedicated to supporting your business growth with our cutting-edge payment gateway services. Our merchant panel is your gateway to streamlined transactions,
                                    <br /> real-time insights, and powerful customization tools. Thank you for choosing {AppNameCapitalized} as your payment partner. Your success is our priority, and we're here to assist you every step of the way.
                                    <span className="tanks-regards d-block mt-2 fw-700">
                                        Best regards, <br />
                                        The {AppNameCapitalized} Team
                                    </span>
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} lg={6} md={6} sm={12} className="login-wrap-bg">
                        <div class="login-wrapper position-relative">
                            <div class="loginbox">
                                <div class="w-100">
                                    <div class="img-logo">
                                        {/* <img src={Logo} alt="logo" /> */}
                                        <div class="back-home merchant-onbording-btn">
                                            <Link to="/EmailForm">
                                                <i class="bi bi-person-fill-add"></i>
                                                <span>Merchant Onboard</span>{" "}
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="mb-md-5 mb-3 login-right-head">
                                        <h2 className="title">Login</h2>
                                        <p className="mb-0">Welcome back! Please login to your account.</p>
                                    </div>
                                    {isFormSubmitted && (!userName || !password) && <div className="error-message alert alert-danger mb-4">Please enter username and password</div>}
                                    {error && <p className="alert alert-danger mb-4">{error ? "Something Went Wrong" : ""}</p>}
                                    {errorMessages?.map((errorMessage, index) => (
                                        <p key={index} className="errorMsg alert alert-danger mb-4">
                                            {errorMessage}
                                        </p>
                                    ))}
                                    <form onSubmit={handleSubmit} className="login-right">
                                        <div className="form-group">
                                            <label className="form-label">Username</label>
                                            <span className="icon icon-type-email"></span>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Username"
                                                value={userName}
                                                onKeyDown={(e) => {
                                                    if (e.key === " ") {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                onChange={(e) => setuserName(e.target.value)}
                                            />
                                            {errorMessages?.mid && <div>{errorMessages?.mid}</div>}
                                        </div>

                                        <div className="form-group">
                                            <label className="form-label">Password</label>
                                            <span className="icon icon-lock"></span>
                                            <div className="password-input">
                                                <input
                                                    type={showPassword ? "text" : "password"}
                                                    className="form-control"
                                                    placeholder="Password"
                                                    value={password}
                                                    onKeyDown={(e) => {
                                                        if (e.key === " ") {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />
                                                <i className={`password-toggle far ${showPassword ? "fa-eye-slash" : "fa-eye"}`} onClick={() => setShowPassword(!showPassword)}></i>
                                            </div>
                                            {errorMessages.password && <div className="alert alert-danger mb-4">{errorMessages.password}</div>}
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12 text-sm-end">
                                                <div className="mb-md-4 mb-2">
                                                    <Link to="/ForgotPassword" className="text-red fnt-18 fnt-s">
                                                        Forgot Password?
                                                    </Link>
                                                </div>
                                                <div className="mb-md-4 mb-2">
                                                    <Link to="/Test" className="text-red fnt-18 fnt-s">
                                                        Test Payment
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="login-btn-sec">
                                            <button type="submit" className="btn btn-dark">
                                                Log In
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default LoginForm;