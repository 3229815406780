import React, { useEffect, useState } from "react";
import { useNavigate, Link, NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/dataTables.bootstrap5.min.css";
import "../../assets/fonts/icomoon.css";
import MainLogo from "../../assets/images/Logo.png";
import jwtDecode from "jwt-decode";
import { PiSignOutDuotone } from "react-icons/pi";
import PersonIcon from "@mui/icons-material/Person";
import "../../stylesheet/Header.css";
import {
  Avatar,
  Badge,
  Button,
  Divider,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import Notification from "./Notification";
import {
  disableNotificationById,
  getNotificationByMid,
} from "../../services/apiService";
import {
  config,
  convertStringDateToDateType,
  isExpired,
} from "../../utils/helper";
import swal from "sweetalert";
import LiveChatWidget from "../../common/LiveChatWidget";

const Header = () => {
  const [visible, setVisible] = useState(false);
  const [activeLink, setActiveLink] = useState(null);
  const [isShowNotify, setIsShowNotify] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);

  const [anchorEl, setAnchorEl] = React.useState(null);

  let navigate = useNavigate();
  const tokenValue = localStorage.getItem("token");
  const token = tokenValue ? tokenValue.slice(1, -1) : null;
  const decodedToken = jwtDecode(token);
  const { mid, merchantName } = decodedToken;
  // if (!mid) {
  //   navigate("/");
  // }
  const location = useLocation();

  const { logoPath } = config(process.env.REACT_APP_SERVER_ENVIRONMENT);

  const handleLinkClick = (linkIndex) => {
    setActiveLink(linkIndex);
  };

  // const handleClearLocalStorage = () => {
  //   localStorage.clear("token");
  //   const storedItem = localStorage.getItem("token");
  //   console.log(storedItem);
  //   if (storedItem) {
  //     navigate("/");
  //   }
  // };

  const getAllNotifications = async () => {
    let res = await getNotificationByMid(mid, token);
    setNotifications(res?.data);
    setNotificationCount(res?.data?.length);
  };

  const closeNotification = async (id) => {
    let res = await disableNotificationById(id, token);
    // console.log("disable res", res);
    if (res.statusCode === 200) {
      getAllNotifications();
    }
  };

  useEffect(() => {
    getAllNotifications();
  }, []);

  const handleProfile = () => {
    setVisible(!visible);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  // const mName = localStorage.getItem("merchantName");
  // const merchantName = mName ? mName.slice(1, -1) : "";

  useEffect(() => {
    const pathname = location.pathname;
    if (pathname === "/Dashboard") {
      setActiveLink(0);
    } else if (pathname === "/Reports") {
      setActiveLink(1);
    } else if (pathname === "/NotActivated") {
      setActiveLink(3);
    } else if (pathname === "/Earnings") {
      setActiveLink(4);
    } else if (pathname === "/Tools") {
      setActiveLink(5);
    } else if (pathname === "/Setting") {
      setActiveLink(6);
    } else if (pathname === "/NotActivated") {
      setActiveLink(7);
    }
  }, []); //eslint-disable-line

  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   // console.log("token", token);
  //   const { expiryTime } = jwtDecode(token);
  //   const expDate = convertStringDateToDateType(expiryTime);
  //   // const currentDate = new Date();
  //   // let isTokenExpired = isExpired(currentDate, expDate)

  //   // if(isTokenExpired) {
  //   //   swal({
  //   //     title: "Oops!",
  //   //     text: "Your Session has been expired ! Please LogIn Again !",
  //   //     icon: "error",
  //   //   }).then(function () {
  //   //     localStorage.removeItem("token")
  //   //     navigate("/")
  //   //   });
  //   // }

  //   setInterval(() => {
  //     const currentDate = new Date();
  //     let isTokenExpired = isExpired(currentDate, expDate);

  //     if (isTokenExpired) {
  //       swal({
  //         title: "Oops!",
  //         text: "Your Session has been expired ! Please LogIn Again !",
  //         icon: "error",
  //       }).then(function () {
  //         localStorage.removeItem("token");
  //         navigate("/");
  //       });
  //     }
  //   }, 300000);
  // }, []);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <nav
        className="navbar navbar-expand-lg lincpay-header-sec"
        style={{ zIndex: "1000" }}
      >
        <div className="container-fluid">
          <Link className="navbar-brand d-flex justify-content-center">
            <img src={logoPath} className="header-logo" alt="logo" />
          </Link>
          <span className="merchant-name">{merchantName}</span>
          <button
            className="navbar-toggler text-white d-lg-none" // Add d-lg-none class to hide the toggle button on large screens
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setVisible(!visible)} // Toggle visibility on button click
          >
            <span className="navbar-toggler-icon white-toggler-icon"></span>
          </button>

          <div
            className={`collapse navbar-collapse justify-content-end${
              visible ? " show header-z-index" : ""
            }`} // Add 'show' class when visible is true
            id="navbarText"
          >
            <ul className="navbar-nav ml-auto align-items-lg-center">
              <li className="nav-item">
                <NavLink
                  to="/Dashboard"
                  className="nav-item"
                  activeClassName="nav-item active"
                >
                  <em className="icon-dashboard"></em> <span>Dashboard </span>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  to="/Reports"
                  className="nav-item"
                  activeClassName="nav-item active"
                >
                  <em className="icon-reports"></em> <span>Reports </span>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  to="/Payment"
                  className="nav-item"
                  activeClassName="nav-item active"
                >
                  <em className="icon-paymen"></em> <span>Payment </span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/Earnings"
                  className="nav-item"
                  activeClassName="nav-item active"
                >
                  <em className="icon-earnings"></em> <span>Earnings </span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/Tools"
                  className="nav-item"
                  activeClassName="nav-item active"
                >
                  <em className="icon-tools"></em> <span>Tools </span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/Setting"
                  className="nav-item"
                  activeClassName="nav-item active"
                >
                  <em className="icon-settings"></em> <span>Settings </span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/FAQ"
                  className="nav-item"
                  activeClassName="nav-item active"
                >
                  <em className="icon-faq"></em> <span>FAQ's </span>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  to="/apis"
                  className="nav-item"
                  activeClassName="nav-item active"
                >
                  <em className="icon-tools"></em> <span>API's </span>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  // to="/Notify"
                  activeClassName="active-link"
                  className="nav-link bg-transparent "
                  onClick={() => setIsShowNotify(true)}
                >
                  <Badge
                    color="error"
                    badgeContent={notificationCount}
                    showZero
                    size="small"
                  >
                    <i class="bi bi-bell fs-7  text-light"></i>
                  </Badge>
                </NavLink>
              </li>

              {/* <li className="signout ">
                <NavLink to="/" className="text-light" onClick={handleLogout}>
                  Signout
                </NavLink>
              </li> */}
            </ul>

            <div
              className={`nav-item order-2 dropdown${
                visible ? "" : " d-none d-lg-block"
              }`}
            >
              <Link
                className="nav-link p-0 d-flex align-items-center dropdown-toggle"
                to="#"
                // id="dropdownMenuButton2"
                // data-bs-toggle="dropdown"
                // aria-expanded="false"
              >
                <div
                  className="user__img rounded-circle bg-transparent"
                  // onClick={handleProfile}
                >
                  <Avatar
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    src="/broken-image.jpg"
                  />

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem className="text-dark">
                      <i> Mid - {mid} </i>
                    </MenuItem>
                    <Divider />
                    <Link to="/MyProfile">
                      <MenuItem onClick={handleClose} className="text-dark">
                        <PersonIcon className="text-grey mx-1" />
                        <i> My Profile </i>
                      </MenuItem>
                    </Link>
                    <Divider />
                    <NavLink
                      to="/"
                      className="text-light"
                      onClick={handleLogout}
                    >
                      <MenuItem className="text-dark">
                        <span className="text-grey mx-1">
                          <PiSignOutDuotone />
                        </span>
                        <i> Signout </i>
                      </MenuItem>
                    </NavLink>
                  </Menu>
                </div>
              </Link>
              {/* <ul
                  className="dropdown-menu dropdown-menu-dark dropdown-menu-end mt-0"
                  aria-labelledby="dropdownMenuButton2"
                >
                  <li>
                    <a className="dropdown-item" href="/#">
                      Sign In
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/">
                      Sign Out
                    </a>
                  </li>
                </ul> */}
            </div>
          </div>
        </div>
      </nav>
      
       {/* <LiveChatWidget/> */}
      {/* <button
        className="sidebar-toggler d-block d-xl-none"
        type="button"
        id="sidebarToggler"
      >
        <i className="bi bi-chevron-right"></i>
      </button> */}
      {isShowNotify ? (
        <Notification
          setIsShowNotify={setIsShowNotify}
          notifications={notifications}
          closeNotification={closeNotification}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default Header;
