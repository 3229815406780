import React, { useState } from "react";
import InactiveServices from "../../common/InactiveServices";
// new import link
import "../../stylesheet/Reports.css";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RegisterPayout from "./RegisterPayout";
import RegisteredVendor from "./RegisteredVendor";
import AddVender from "./payout/AddVender";
import Card from "react-bootstrap/Card";
import PayoutSection from "./PayoutSection";
import WithdrawAmount from "../Tools/WithdrawAmount";
import MyLoader from "../../common/MyLoader";
import TransactionCallback from "./TransactionCallback";
import AdvanceSearcgBox from "../reports/AdvanceSearcgBox";
import PendingVenders from "./PendingVenders";
import AddPayoutBalance from "./AddPayoutBalance";
import Refund from "./Refund";
 
function MerchantTools() {
  const [activeButton, setactiveButton] = useState(4);
  const [reportType, setReportType] = useState("WidhdrawAmount");
  const [showLoader, setShowLoader] = useState(false);

  const handleToolsButtonClick = (type, buttonNumber) => {
    setReportType(type);
    setactiveButton(buttonNumber);
  };

  return (
    <>
      {showLoader ? <MyLoader /> : ""}
      <section className="dashboard-header-sec merchant-tool-sec">
        <Container>
          <div className="ds-header-sec">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-4 ds-header-card">
                  <Card.Body>
                    {/* <Card.Title className="h3">Reports</Card.Title>  */}
                    <div className="merchant-tab-btn">
                      <ul className="report-link nav-report-btn">
                        {/* <li
                          className={activeButton === 3 ? "active" : ""}
                          onClick={() => handleToolsButtonClick("Deposit", 3)}
                        >
                          Deposit
                        </li> */}
                        <li
                          className={activeButton === 4 ? "active" : ""}
                          onClick={() =>
                            handleToolsButtonClick("WidhdrawAmount", 4)
                          }
                        >
                          Withdraw Amount
                        </li>
                        <li
                          className={activeButton === 1 ? "active" : ""}
                          onClick={() => handleToolsButtonClick("refund", 1)}
                        >
                          Refund
                        </li>

                        <li
                          className={activeButton === 2 ? "active" : ""}
                          onClick={() =>
                            handleToolsButtonClick("VendorRegister", 2)
                          }
                        >
                          Register Vendor
                        </li>
                        <li
                          className={activeButton === 5 ? "active" : ""}
                          onClick={() =>
                            handleToolsButtonClick("registerPayout", 5)
                          }
                        >
                          Payout Request
                        </li>
                        <li
                          className={activeButton === 6 ? "active" : ""}
                          onClick={() => handleToolsButtonClick("txnStatus", 6)}
                        >
                          Txn Status Check
                        </li>
                        <li
                          className={activeButton === 7 ? "active" : ""}
                          onClick={() =>
                            handleToolsButtonClick("txnCallBack", 7)
                          }
                        >
                          Txn Callback
                        </li>
                        <li
                          className={activeButton === 10 ? "active" : ""}
                          onClick={() =>
                            handleToolsButtonClick("payoutTxnCallBack", 10)
                          }
                        >
                          Payout Txn Callback
                        </li>
                        <li
                          className={activeButton === 8 ? "active" : ""}
                          onClick={() =>
                            handleToolsButtonClick("pendingVenders", 8)
                          }
                        >
                          Submitted Vendors
                        </li>
                        <li
                          className={activeButton === 9 ? "active" : ""}
                          onClick={() => handleToolsButtonClick("AddPayout", 9)}
                        >
                          Add Wallet Balance
                        </li>
                      </ul>
                    </div>
                  </Card.Body>
                </Card>
                {/* ./card */}
              </Col>

              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0">
                  <div>
                    {reportType === "registerPayout" ? (
                      <PayoutSection setShowLoader={setShowLoader} />
                    ) : reportType === "txnStatus" ? (
                      <AdvanceSearcgBox setShowLoader={setShowLoader} />
                    ) : reportType === "VendorRegister" ? (
                      <AddVender setShowLoader={setShowLoader} />
                    ) : reportType === "txnCallBack" ? (
                      <TransactionCallback reportType={reportType}  setShowLoader={setShowLoader} />
                    ) : reportType === "payoutTxnCallBack" ? (
                      < TransactionCallback reportType={reportType} setShowLoader={setShowLoader} />
                    ) : reportType === "WidhdrawAmount" ? (
                      <WithdrawAmount />
                    ) : reportType === "AddPayout" ? (
                      <AddPayoutBalance />
                    ) : reportType === "pendingVenders" ? (
                      <PendingVenders setShowLoader={setShowLoader} />
                    ) : reportType === "refund" ? (
                      <Refund />
                    ) : (
                      <InactiveServices />
                    )}
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
}

export default MerchantTools;
