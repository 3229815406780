import React, { useState, useEffect } from "react";
// Import your API function here
import { balanceFetchByMid } from "../../services/apiService";
import { Card } from "react-bootstrap";
import jwtDecode from "jwt-decode";

function BalanceComponent({ isShowWalletBalance, isShowIMPSOrUPI,getReq }) {
  const [showLoader, setShowLoader] = useState(false);
  const [getAgain, setGetAgain] = useState(false);
  const [apiResponse, setApiResponse] = useState({
    upiAmount: "",
    impsAmount: "",
  });

  const tokenValue = localStorage.getItem("token");
  const token = tokenValue ? tokenValue.slice(1, -1) : null;
  const decodedToken = jwtDecode(token);
  const { mid } = decodedToken;

  const getBalance = async () => {
    setShowLoader(true);

    try {
      const response = await balanceFetchByMid(mid, token);

      if (response.statusCode === 200) {
        setApiResponse(response.data);
      } else if (response.statusCode === 304) {
        setApiResponse({ upiAmount: "", impsAmount: "" });
      } else {
        console.error("API Call Failed");
        // Handle failure
      }
    } catch (error) {
      console.log(error.message);
      // Handle error
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    getBalance();
  }, [getReq]); // Make the API call when the component mounts

  return (
    <div className="d-flex align-items-center">
      {showLoader ? (
        <p>Loading...</p>
      ) : (
        <div className="d-flex justify-content-between gap-4">
          {isShowIMPSOrUPI === "true" ? (
            <div className="d-flex justify-content-between gap-4">
              <Card>
                <div className="d-flex align-items-center p-2 ">
                  <b>
                    {" "}
                    Payout Balance ( UPI ):{" "}
                    <b>
                      {" "}
                      <i class="bi bi-currency-rupee"></i>{" "}
                    </b>
                    {apiResponse.upiAmount}{" "}
                  </b>
                </div>
              </Card>
              <Card>
                <div className="d-flex align-items-center p-2">
                  <b>
                    {" "}
                    Payout Balance ( IMPS ):
                    <b>
                      {" "}
                      <i class="bi bi-currency-rupee"></i>{" "}
                    </b>
                    {apiResponse.impsAmount}{" "}
                  </b>
                </div>
              </Card>
            </div>
          ) : (
            ""
          )}
          {isShowWalletBalance === "true" ? (
            <Card className="border border-4">
              <div className="d-flex align-items-center p-2">
                <b>
                  Available Wallet Balance:
                  <b>
                    <i class="bi bi-currency-rupee"></i>
                  </b>
                  {apiResponse.wallet}
                </b>
              </div>
            </Card>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
}

export default BalanceComponent;
