import React, { useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from 'reactstrap';
import { FAQList } from "./faqList";

const FAQ = () => {
  const [open, setOpen] = useState();
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <section className="dashboard-header-sec merchant-settings-sec mt-3 rounded-top">
        <Container>
          <div className="ds-header-sec">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-4 ds-header-card">
                  <Card.Body>
                    <div className="merchant-tab-btn px-3">
                      <Accordion flush open={open}  toggle={toggle}>
                      {
                        FAQList.map((faqList) => {
                          return (
                            <AccordionItem className="mt-3 border">
                            <AccordionHeader targetId={faqList.questionId}>
                              <span className="text-primary" dangerouslySetInnerHTML={{ __html: faqList.question }}></span>
                            </AccordionHeader>
                            <AccordionBody accordionId={faqList.questionId}>
                              <span dangerouslySetInnerHTML={{ __html: faqList.answer }}></span>
                            </AccordionBody>
                          </AccordionItem>
                          )
                        })
                       }
                      </Accordion>
                    </div>
                  </Card.Body>
                </Card>
                {/* ./card */}
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default FAQ;