import React from "react";
import { Alert, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Notification = ({
  setIsShowNotify,
  notifications,
  closeNotification,
}) => {
  const style = {
    width: "25vw",
    height: "100vh",
    backgroundColor: "grey",
    opacity: 0.9,
    position: "fixed",
    right: "0",
    top: "0",
    paddingTop: "90px",
    zIndex: "999",
  };
  const style2 = {
    width: "100vw",
    height: "100vh",
    // position: "absolute",
    // backgroundColor: "red",
    opacity: 1,
    position: "fixed",
    right: "0",
    top: "0",
    zIndex: "999",
  };

  const closeBtnStyle = {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "25px",
    cursor: "pointer",
  };
  const handleCloseNotify=(e)=>{
    console.log(e.target.id)
    if(e.target.id === "OuterDiv" || e.target.id === "CrossBTN"){
     setIsShowNotify(false)
    }
     }

  return (
    <div className="d-flex">
      <div style={style2} id="OuterDiv" onClick={handleCloseNotify}></div>
    <div style={style} className="shadow-lg overflow-y-scroll">
      <div className="d-flex justify-content-end px-2">
        <p style={closeBtnStyle} className="text-light">
          <i class="bi bi-x-circle" id="CrossBTN" onClick={handleCloseNotify}></i>
        </p>
      </div>
      <div className="d-flex justify-content-center flex-column align-items-center px-3">
        {notifications === null ? (
          <h5 className="text-white  mt-3">No Notifications Yet!!</h5>
        ) : (
          ""
        )}

        {notifications?.map((notify) => {
          return (
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    closeNotification(notify.id);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {`${notify.notification}`}
            </Alert>
          );
        })}
      </div>
    </div>
    </div>
  );
};

export default Notification;
