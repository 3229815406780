import React, { Component } from "react";
import jwtDecode from "jwt-decode";
import Chart from "react-google-charts";
// import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { useMultiLineChartDataFetcher as fetchMultiLineChartData } from "../../services/apiService";

class MultiLineChart extends Component {
  constructor(props) {
    super(props);
    const tokenValue = localStorage.getItem("token");
    const token = tokenValue ? tokenValue.slice(1, -1) : null;
    const decodedToken = jwtDecode(token);
    const { mid } = decodedToken;

    this.state = {
      responseData: null,
      mid: mid,
      token: token,
      error: null,
    };
  }
  async componentDidMount() {
    try {
      const { mid } = this.state;
      const { token } = this.state;
      const data = await fetchMultiLineChartData(mid, token);
      this.setState({ responseData: data });
    } catch (error) {
      this.setState({ error: error?.message });
    }
  }
  // this.setState({responseData : })
  // fetchData = async () => {
  //   const { mid } = this.state;
  //   try {
  //     const response = await axios.get(MULTILINE_CHART_API + mid);

  //     if (response.status !== 200) {
  //       throw new Error("Failed to fetch data");
  //     }
  //     this.setState({ responseData: response?.data?.responseData });
  //   } catch (error) {
  //     this.setState({ error: error?.message });
  //   }
  // };

  render() {
    const { responseData, error } = this.state;

    if (error) {
      return <div>Error: {error}</div>;
    }

    if (!responseData) {
      return <div>Loading...</div>;
    }

    const LineData = [
      ["x", "Success", "Failed"],
      ...responseData?.map((data) => [
        new Date(data?.date)?.getDate(),
        data?.successCount,
        data?.failedCount,
      ]),
    ];

    const LineChartOptions = {
      hAxis: {
        title: "Days",
        titleTextStyle: {
          fontSize: 14,
          bold: true,
        },
        minValue: 0,
        // ticks: [0, 2, 4, 6],
      },
      vAxis: {
        title: "Transaction",
        titleTextStyle: {
          fontSize: 14,
          bold: true,
        },
        viewWindow: {
          min: 0, // Set the minimum value of the vertical axis to 0
        },
        format: "0", // Format the values as integers
        // ticks: [0, 2, 4, 6],
      },
      // series: {
      //   1: { curveType: "function" },
      // },
      series: {
        0: {
          curveType: "function",
          lineWidth: 3,
        },
        1: {
          curveType: "function",
          lineWidth: 3,
        },
      },
    };

    return (
      <div className="chartViewArea">
        <Chart className="chart-v mt-4" 
          height={"500px"}
          chartType="LineChart"
          loader={<div>Loading Chart</div>}
          data={LineData}
          options={LineChartOptions}
          rootProps={{ "data-testid": "2" }}
        />
      </div>
    );
  }
}

export default MultiLineChart;
