import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { USE_RECONCILIATION_MULTIPLE_FILTER_API as getApiData } from "../../../services/apiService";
import { useDispatch } from "react-redux";
import {
 
  setTransactionIdValue,
  setStartDateValue,
  setEndDateValue,
  setCustRefNoSearchValue,
 
} from "../../../store/slices/reportSlice";
import jwtDecode from "jwt-decode";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  isTransactionIdRegexIsValid,
} from "../../../utils/validations";

const ReconciliationAdvanceFilter = ({
  onClose,
  setIsAdvanceFilterOpen,
  setCustomApiResponse,
  setShowLoader,
}) => {
  const tokenValue = localStorage.getItem("token");
  const token = tokenValue ? tokenValue.slice(1, -1) : null;
  const decodedToken = jwtDecode(token);
  const { mid } = decodedToken;
  const [searchOption, setSearchOption] = useState("txnId");
  const [searchValue, setSearchValue] = useState("");
  const [show, setShow] = useState(true);
  const [searchValueError, setSearchValueError] = useState("");
  const [isSearchValueError, setIsSearchValueError] = useState(false);
  const storeSearchValue = useSelector((state) => state.report.searchValue);
  const dispatch = useDispatch();

  const handleModalClose = () => {
    setShow(false);
    setIsAdvanceFilterOpen(false);
    
  };

  const handleClick = (option) => {
    setSearchOption(option);
    setSearchValue("");
  };

  const submitHandler = () => {
   
  getApiData(
      "",
      "",
      "",
      mid,
      "",
      searchOption === "rrn" ? searchValue : "",
      "",
      "",
      searchOption === "txnId" ? `Zenex${searchValue}` : "",
      token,
      setCustomApiResponse,
      setShowLoader,
    );
  

    handleModalClose();
  };

  const handelSearchData = (e) => {
    setSearchValue(e.target.value);
    setIsSearchValueError(false);
    setSearchValueError("");
    document.getElementById("searchBtn").disabled = false;
  };

  const handelValidations = (e) => {
    if (searchOption === "txnId") {
      if (!isTransactionIdRegexIsValid(searchValue)) {
        setIsSearchValueError(true);
        setSearchValueError("Enter Valid Transaction Id.");
        document.getElementById("searchBtn").disabled = true;
      }
      return;
    }
};

useEffect(() => {
  setSearchValue("");
}, [storeSearchValue]);


  useEffect(() => {
    handleClick("transactionId");
  }, []);
  useEffect(() => {
  
    dispatch(
      setTransactionIdValue(searchOption === "transactionId" ? searchValue : "")
    );
   
    dispatch(
      setCustRefNoSearchValue(searchOption === "custRefNo" ? searchValue : "")
    );
   
  }, [searchOption, searchValue]);
  useEffect(() => {
    dispatch(setStartDateValue(""));
    dispatch(setEndDateValue(""));
  }, []);

  return (
    <div className="advanced-search-popup">
   
      <Modal show={show} centered>
        <Modal.Header className="bg-dark text-white">
          <Modal.Title>Reconciliation Advanced Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="filter-field-area">
            <div className="form-group mb-3">
              <select
                className="form-control"
                value={searchOption}
                onChange={(e) => {
                  setSearchOption(e.target.value);
                  setSearchValue("");
                  setIsSearchValueError(false);
                  setSearchValueError("");
                }}
              >
                <option type="number" value="">
                  Select
                </option>
                <option type="text" value="txnId">
                  Transaction Id
                </option>
                <option type="text" value="rrn">
                  UTR Number
                </option>
              
              </select>
            </div>
            <div className="form-group mb-2">
              <input
                className="advanced-input-box form-control"
                type="text"
                value={searchValue}
                onChange={handelSearchData}
                // onBlur={handelValidations}
                onKeyDown={(e) => {
                  if (e.key === " ") {
                    e.preventDefault();
                  }
                }}
                placeholder={
                  searchOption === "txnId"
                    ? "Enter transaction ID"
                    : searchOption === "rrn"
                    ? "Enter UTR Number"
                    : ""
                }
              />
              {isSearchValueError ? (
                <span id="searchValErr" className=" text-danger">
                  {`${searchValueError}`}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-danger"
            size="sm"
            className="text-capitlize"
            onClick={handleModalClose}
          >
            Close
          </Button>
          <Button
            id="searchBtn"
            variant="outline-primary"
            size="sm"
            className="text-capitlize"
            onClick={submitHandler}
          >
            <i class="bi bi-search mx-1"></i> Search
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ReconciliationAdvanceFilter;