import React, { useState } from 'react'
import InactiveServices from '../../common/InactiveServices'
import { Card, Col, Container, Row } from 'react-bootstrap'
import VirtualTerminal from './VirtualTerminal';
import MyLoader from '../../common/MyLoader';

const Payment = () => {

  const [activeButton, setactiveButton] = useState(1);
  const [showLoader, setShowLoader] = useState(false);

  const Type= localStorage.getItem("typeOfServices")
  const paymentType= JSON.parse(Type)
   

  return (
    <>
    {showLoader?<MyLoader/>:""}
        <section className="dashboard-header-sec merchant-settings-sec">
                <Container>
                    <div className="ds-header-sec">
                        <Row>
                            <Col xs={12} lg={12} md={12}>
                                <Card className="shadow border-0 mb-4 ds-header-card">
                                    <Card.Body>
                                        {/* <Card.Title className="h3">Reports</Card.Title>  */}
                                        <div className="merchant-tab-btn">
                                            <ul className="report-link nav-report-btn">
                                                {paymentType.includes("VIRTUAL TERMINAL") ?(<li className={activeButton === 2 ? "active" : ""} onClick={() => setactiveButton(2)}>
                                                    Virtual Terminal
                                                </li>):""}
                                                <li className={activeButton === 1 ? "active" : ""} onClick={() => setactiveButton(1)}>
                                                    Link Based Payments
                                                </li>
                                                {/* <li className={activeButton === 2 ? "active" : ""} onClick={() => setactiveButton(2)}>
                                                    Virtual Terminal
                                                </li> */}
                                                <li className={activeButton === 3 ? "active" : ""} onClick={() => setactiveButton(3)}>
                                                    Tap N Pay
                                                </li>
                                              
                                            </ul>
                                        </div>
                                    </Card.Body>
                                </Card>
                                {/* ./card */}
                            </Col>

                            <Col xs={12} lg={12} md={12}>
                                <Card className="shadow border-0 mb-4">
                                    <Card.Body>
                                         {activeButton===2? <VirtualTerminal setShowLoader={setShowLoader}/>:<InactiveServices/>}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
    </>
  )
}

export default Payment