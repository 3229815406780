import React from "react";
import { useState } from "react";
import { Col, Modal, Button } from "react-bootstrap";

import jwtDecode from "jwt-decode";
import { columnForRequestPayout } from "../../utils/constant";
import Payout from "./Payout";
import { savePayoutRequest } from "../../services/apiService";
import { MDBDataTable } from "mdbreact";
import swal from "sweetalert";
const TablePopUp = ({ setOpenPopUp , setShowLoader}) => {
  const [show, setShow] = useState(true);

  const [apiResponse, setApiResponse] = useState([]);

  const [errorText, setErrorText] = useState("");
  const [amount, setAmount] = useState("");
  const [payoutClose, setPayoutClose] = useState(true);
  const [reportType, setReportType] = useState("Customer");

  const tokenValue = localStorage.getItem("token");
  const token = tokenValue ? tokenValue.slice(1, -1) : "";
  const decodedToken = jwtDecode(token);
  const { mid } = decodedToken;

  //  console.log(apiResponse)
  const column = columnForRequestPayout;

  // addRow()
  const rows = apiResponse.map((entry) => ({
    checkbox: <input type="checkbox" className="align-self-center" />,
    accountNumber: entry.accountNumber || "",
    amount: (
      <div className="d-flex flex-column align-items-center">
        <input
          type="number"
          className={errorText !== 0 ? "" : "border border-1 red"}
          value={amount || ""}
          onChange={(e) => handleAmountChange(e.target.value)}
        />
        <p style={{ color: "red", fontSize: ".7vw" }} className="w-100">
          {errorText}
        </p>
      </div>
    ),
    bankName: entry.bankName || "",
    branchName: entry.branchName || "",
    vendorName: entry.fullName || "",
    email: entry.email || "",
    mobile: entry.mobileNo || "",
    userVpa: entry.userVpa || "",
    category: entry.category || "",
    ifscCode: entry.ifscCode || "",
    payoutFor: entry.payoutFor || "",
  }));
  const data2 = {
    columns: column,
    rows: rows,
  };

  const handleAmountChange = (value) => {
    const numericValue = value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters except '.'

    if (numericValue === "0") {
      setErrorText("Input must be greater than 0.");
      return; // Prevent setting the value to 0
    }

    if (
      numericValue !== "" &&
      (parseFloat(numericValue) <= 0 || isNaN(numericValue))
    ) {
      setErrorText("Input must be a positive number.");
    } else {
      setErrorText("");
    }

    setAmount(numericValue);
  };

  const Payload = apiResponse.map((entry) => {
    return {
      accountNumber: entry.accountNumber || "",
      amount: amount,
      bankName: entry.bankName || "",
      branchName: entry.branchName || "",
      category: entry.category || "",
      email: entry.email || "",
      fullName: entry.fullName || "",
      ifscCode: entry.ifscCode || "",
      mid: mid,
      mobile: entry.mobileNo || "",
      payoutFor: entry.payoutFor || "",
      remark: entry.remark || "",
      upi: entry.userVpa || "",
    };
  });
  const payload = Payload[0];

  // const handleSubmitEntry = () => {
  //   console.log(payload, rows);
  // };

  const handleModalClose = () => {
    setOpenPopUp(false);
  };
  const handleSubmitEntry = async () => {
    if (amount === "" || parseFloat(amount) <= 0 || isNaN(amount)) {
      setErrorText("Amount Cannot be Empty Or 0");
      return;
    }

    setShowLoader(true)

    await savePayoutRequest(payload, token)
      .then((response) => {
        setShowLoader(false)
        if (response.statusCode === 200) {
          // setShowPopup(true);
          swal({
            title: "Success",
            text: "Your Payout request has been submitted successfully!!!",
            icon: "success",
          });
          setShowLoader(false)
          setApiResponse([]);
          setAmount("");
          setPayoutClose(true);
         
          handleModalClose();
        } else {
          console.error("API Call Failed");
          // Handle failure
        }
      })
      .catch((error) => {
        console.log(error);
        setErrorText("Something Went Wrong");
        //  setErrorText("No Data Found")
      });

    // handleModalClose();
  };

  return (
    <>
      <div>
        <div className="d-flex flex-row">
          <Modal show={show} size="lg" centered>
            <Modal.Body>
              <Col xs={12} lg={12} md={12}>
                {/* <Card className="shadow border-0 mb-4">
                   
                    <Card.Body>
                    <div> */}
                {payoutClose ? (
                  <Payout
                    setReportType={setReportType}
                    setPayoutClose={setPayoutClose}
                    setOpenPopUp={setOpenPopUp}
                    setApiResponse={setApiResponse}
                    apiResponse={apiResponse}
                  />
                ) : (
                  <>
                    <MDBDataTable
                      className=" dataTable overflow-x-scroll"
                      data={data2}
                      footer="none"
                      striped
                      bordered
                      entries={50}
                      exportToCSV
                      responsive
                      theadColor="#333"
                      // small
                    />
                    <div className="mt-2 d-flex justify-content-end">
                      <Button
                        variant="outline-danger"
                        size="sm"
                        className="text-capitlize mx-3"
                        onClick={handleModalClose}
                      >
                        Close
                      </Button>
                      <Button onClick={handleSubmitEntry}>Submit</Button>
                    </div>
                  </>
                )}

                {/* </div> */}
                {/* </Card.Body>
                   
                    <Card.Body>
                    <div> */}

                {/* </div>
                    </Card.Body>
                  </Card> */}
              </Col>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default TablePopUp;
