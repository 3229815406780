import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { Route, useNavigate, useLocation, Routes } from "react-router-dom";
import RootErrorBoundary from "./components/merchant/RootErrorBoundary";
import Dashboard from "./components/merchant/Dashboard"; //eslint-disable-line
import store from "./store/store";
import Header from "./components/merchant/Header";
import routes from "./routes/routes";
import LogoutOnTokenExpiration from "./components/merchant/LogoutOnTokenExpiration ";
import { config } from "./utils/helper";
// Import the dotenv package

function RenderRoutes() {
  const location = useLocation();
  const hideHeaderPaths = [
    "/",
    "/Test",
    "/ForgotPassword",
    "/EmailForm",
    "/CompanyAddForm",
    "/ContinueOnboarding",
    "/MerchantInfoForm",
    "/AdharForm",
    "/AdhaarVerification",
    "/FirstStage",
    "/PasswordChangeForm",
  ];
  const shouldRenderHeader = !hideHeaderPaths.includes(location.pathname);


  return (
    <>
      {shouldRenderHeader && <Header />}
      <Routes>
        {routes.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
        <Route path="*" element={<RootErrorBoundary />} />
      </Routes>
    </>
  );
}

function App() {
  const navigate = useNavigate();

  const {Name,logoPath}=config(process.env.REACT_APP_SERVER_ENVIRONMENT)
  const AppNameCapitalized=Name
  const AppName = AppNameCapitalized.toUpperCase()  
  
    useEffect(() => {
 
      document.title = `${AppNameCapitalized}`;
      const favicon=document.getElementById("dynamic-favicon") 
      if(favicon){
        favicon.href = logoPath; 
      }
     }, [Name])
  useEffect(() => {
    const handlePopstate = () => {
      const storedItem = localStorage.getItem("token");
      // window.history.go(1);
      if (!storedItem) {
        navigate("/");
      }
    };


     
    window.onpopstate = handlePopstate;
    return () => {
      window.onpopstate = null;
    };
  }, []); //eslint-disable-line

  return (
    <Provider store={store}>
      <RenderRoutes />
      <LogoutOnTokenExpiration />
    </Provider>
  );
}

export default App;
