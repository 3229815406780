import React, { useState } from "react";
import { Modal, Button, Col, Card, Row, Container } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import jwtDecode from "jwt-decode";
import axios from "axios";
import { useEffect } from "react";
// import jwtDecode from 'jwt-decode';
import { FIND_BY_ORDER_NO, column } from "../../utils/constant";
import { Search } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import MyLoader from "../../common/MyLoader";

function TxnStatusCheckBtn() {
  const { orderNo } = useParams();

  // const [show2, setShow2] = useState(true);
  const [searchAdvance, setSearchAdvance] = useState(true);
  const [searchOption, setSearchOption] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [data1, setData1] = useState([]);
  const [error1, setError1] = useState(null);

  const [orderNumber, setOrderNumber] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [RRN, setRRN] = useState("");

  // const handleModalClose = () => {
  //     setShow2(false);
  // }
  const getData1 = async (ordNo) => {
    const tokenValue = localStorage.getItem("token");
    const token = tokenValue ? tokenValue.slice(1, -1) : null;
    const decodedToken = jwtDecode(token);
    const { mid } = decodedToken;
    console.log(mid);
    console.log(searchValue + " vaus is");
    const Payload = {
      mid: mid,
      orderNumber: ordNo,
      rrn: "",
    };

    console.log(Payload);
    try {
      setShowLoader(true);
      const response = await axios.post(FIND_BY_ORDER_NO, Payload, {
        headers: {
          Authentication: "Bearer " + token,
        },
      });
      setShowLoader(false);
      setData1(response?.data);
      console.log(" data is", response?.data);
    } catch (error) {
      setError1(true);
      console.error(error);
    }
  };

  var data2 = [];

  const handleSearch = (ordNo) => {
    getData1(ordNo);
    data2 = [data1];
  };

  useEffect(() => {
    setOrderNumber(orderNo);
  }, []);

  useEffect(() => {
    handleSearch(orderNo);
  }, [orderNo]);

  const column = [
    {
      label: "Customer Name",
      field: "customerName",
      sort: "asc",
      width: "auto",
    },
    {
      label: "Transaction ID",
      field: "extTransactionId",
      sort: "asc",
      width: "850px",
    },
    {
      label: "Status",
      field: "primaryStatus",
      sort: "asc",
      width: "850px",
    },
    {
      label: "Actual Status",
      field: "actualStatus",
      sort: "asc",
      width: "850px",
    },

    {
      label: "Transaction Time",
      field: "txnTime",
      sort: "asc",
      width: "auto",
    },
    {
      label: "Amount",
      field: "amount",
      sort: "asc",
      width: "auto",
    },
    {
      label: "UPI ID",
      field: "upiId",
      sort: "asc",
      width: "auto",
    },
    {
      label: " UTR No",
      field: "custRefNo",
      sort: "asc",
      width: "auto",
    },

    {
      label: "Remark",
      field: "remark",
      sort: "asc",
      width: "auto",
    },
  ];

  const datas = {
    columns: column,
    rows: data1.responseData,
    //  .map( (entry)=>{
    //   return {
    //     customerName: entry?.customerName || "NA",
    //   actualStatus: entry?.actualStatus || "NA",
    //   primaryStatus: entry?.primaryStatus || "NA",
    //   txnTime: entry?.txnTime || "NA",
    //   amount: entry?.amount || "NA",
    //   upiId: entry?.upiId || "NA",
    //   custRefNo: entry?.custRefNo || "NA",
    //   remark: entry?.remark || "NA",
    //   extTransactionId:entry?.extTransactionId || "NA",
    //   }
    // })
  };

  return (
    <>
      {/* <div className="filter-field-area">
        <div className="bg-danger mb-3  d-flex justify-content-center">
          <div className="w-50 d-flex  ">
            <label className="bg-dark text-white rounded-3 border-none w-100 pl-5 p-2">
              Enter Order No
            </label>
            <input
              className="advanced-input-box form-control w-100"
              type="text"
              value={orderNumber}
              onChange={(e) => setOrderNumber(e.target.value)}
              placeholder="Enter Order Number"
            />
          </div>
         
        </div>
        <Button
          variant="outline-primary"
          size="sm"
          className="text-capitlize mt-2 mb-2 align-self-end"
          onClick={handleSearch}
        >
          <i class="bi bi-search mx-1"></i> Search
        </Button>
      </div> */}

      {showLoader ? <MyLoader /> : ""}

      <section className="lincpay-dashboard-sec merchant-admin-info-form aadharform-sec">
        <Container>
          <div className="cart-view-sec mt-4">
            <Col xs={12} lg={12} md={12}>
              {/* Tab content start here */}
              <Card className="shadow-cst border-0 mb-3 chart-boxes merchant-info-form-sec">
                <Card.Body>
                  <div className="merchant-form-info d-flex justify-content-center">
                    <Col xs={12} lg={4} md={6}>
                      <div className="form-group">
                        <label
                          htmlFor="companyPanNumber"
                          className="form-label"
                        >
                          Enter Order No
                          <sup className="text-danger">*</sup>
                        </label>
                        <div className="input-field-validate">
                          <input
                            type="text"
                            value={orderNumber}
                            onChange={(e) => setOrderNumber(e.target.value)}
                            className="form-control"
                          />

                          <div className="login-btn-sec">
                            <button
                              id="companyPanVerifyBtn"
                              type="button"
                              className="btn btn-primary"
                              onClick={handleSearch}
                            >
                              <i class="bi bi-search mx-1"></i>Search
                            </button>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </div>
                </Card.Body>
              </Card>
              {/* Tab content end here */}
            </Col>
            {/* ./col */}
          </div>
        </Container>
      </section>

      {/* <div>
        <h2>Data Table</h2>
        <table className="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Title</th>
              <th>Body</th>
            </tr>
          </thead>
          <tbody>

            <tr>
              <td>{data1.customerName}</td>
              <td>{data1.upiTxnId}</td>
              <td>{data1.respMessage}</td>
            </tr>

          </tbody>
        </table>
      </div> */}

      <section className="lincpay-dashboard-sec merchant-admin-info-form aadharform-sec">
        <Container>
          <div className="cart-view-sec mt-4">
            <Col xs={12} lg={12} md={12}>
              {/* Tab content start here */}
              <Card className="shadow-cst border-0 mb-3 chart-boxes merchant-info-form-sec">
                <Card.Body>
                  <div className="merchant-form-info d-flex justify-content-center">
                    <Col >
                      {data1.statusCode === 200 ? (
                        <MDBDataTable
                          className=" dataTable overflow-x-scroll"
                          data={datas}
                          footer="none"
                          striped
                          bordered
                          exportToCSV
                          responsive
                          theadColor="#333"

                          // small
                        />
                      ) : (
                        <div className="no-data-message fs-5 w-100 bg-secondary fw-semibold rounded-3 px-5 pl-5 p-2 d-flex align-items-center justify-content-center">
                          {" "}
                          No data available.{" "}
                        </div>
                      )}
                    </Col>
                  </div>
                </Card.Body>
              </Card>
              {/* Tab content end here */}
            </Col>
            {/* ./col */}
          </div>
        </Container>
      </section>
    </>
  );
}
export default TxnStatusCheckBtn;
