import React from "react";
import { Card } from "react-bootstrap";

const DailyTransactionBox = ({ data }) => {

  return (
    <Card className="shadow-cst  border-1 dashboard-boxs">
      <Card.Body>
       
          <div className=" fw-bold ds-card-dt d-flex justify-content-center align-items-center">
            {data.evokTxnCode}
          </div>
          <div className=" fw-bold ds-card-dt d-flex justify-content-center align-items-center">
            {data.count}
          </div>
         
      </Card.Body>
    </Card>
  );
};

export default DailyTransactionBox;