import React, { useEffect, useState } from "react";
import { getChargeBackData } from "../../services/apiService";
import jwtDecode from "jwt-decode";
import { MDBDataTable } from "mdbreact";
import { FiDownload } from "react-icons/fi";
import Papa from "papaparse";
import { columnforChargeback } from "../../utils/constant";
import swal from "sweetalert";

function ChargebackReport({
  setShowLoader,
  setCustomMultiApiResponse,
  responseData,
}) {
  const [serial] = useState(1);
  const tokenValue = localStorage.getItem("token");
  const token = tokenValue ? tokenValue.slice(1, -1) : null;
  const decodedToken = jwtDecode(token);
  const { mid } = decodedToken;

  const getInitialChargebackData = async () => {
    getChargeBackData(mid, token, setShowLoader, setCustomMultiApiResponse);
  };

  useEffect(() => {
    getInitialChargebackData();
  }, []);

  const handleButtonClick1 = () => {
    swal({
      title: "Alert!",
      text: "Service Not Activated yet.",
      icon: "error",
    });
  };

  const tableData = responseData?.map((item, index) => ({
    index: serial + index,
    ...item,
    actionButtons: (
      <div className="d-flex gap-2">
        <button
          className=" rounded rounded-2 border border-none bg-danger"
          onClick={handleButtonClick1}
        >
          Accept / Refund
        </button>
        <button
          className=" rounded rounded-2 border border-none bg-success"
          onClick={handleButtonClick1}
        >
          Denied / Fight
        </button>
      </div>
    ),
  }));

  const tableColumns = Object.keys(tableData[0] || {});

  // console.log("tableColumns", tableColumns);
  // console.log("responseData--", responseData);

  const data = {
    columns: columnforChargeback,
    rows: tableData,
  };
  //   download csv button logic:
  const handleDownloadCSV = () => {
    const table = document.getElementById("chargeback-datatable-container");
    const visibleRows = table.querySelectorAll(".dataTable tbody tr");

    const visibleData = [];

    visibleRows.forEach((row) => {
      const rowData = [];
      row.querySelectorAll("td").forEach((cell) => {
        rowData.push(cell.textContent.trim());
      });
      visibleData.push(rowData);
    });

    const headerLabels = data.columns.map((column) => column.label);
    const dataWithHeader = [headerLabels, ...visibleData];

    const csv = Papa.unparse(dataWithHeader);

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "chargeback_report.csv";
    link.style.display = "none";

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  const calculateTotalAmountForSuccess = (data) => {
    return data.reduce(
      (total, item) => total + parseFloat(item.chargeBackAmount),
      0
    );
  };

  const totalAmountForSuccess = calculateTotalAmountForSuccess(
    tableData?.length ? tableData : []
  ).toFixed(2);

  return (
    <div id="chargeback-datatable-container">
      <MDBDataTable
        className=" dataTable overflow-x-scroll"
        footer="none"
        data={data}
        striped
        bordered
        entries={50}
        exportToCSV
        responsive
        theadColor="#333"
        // small
      />

      {/* <MDBDataTable className=' dataTable overflow-x-scroll' striped 
            bordered
            responsive
            exportToCSV
            entries={50} 
            theadColor="#333"
            hover data={data} /> */}
      <div className="d-flex align-items-center justify-content-between mt-3">
        <button
          className="advanced-btn btn btn-primary text-capitlize"
          onClick={handleDownloadCSV}
        >
          <FiDownload className="d-none" />
          <i className="bi bi-arrow-down-circle"></i> Download CSV
        </button>
        <div className="d-flex align-items-center justify-content-between w-25 gap-4 border boeder-1 p-2 ">
          <lable className="fs-4 w-50">Total Amount</lable>
          <span className="fs-4 fw-bold w-50 border boeder-2 rounded-2 p-1">
            {totalAmountForSuccess}
          </span>
        </div>
      </div>
    </div>
  );
}

export default ChargebackReport;