import React, { useEffect, useState } from "react";
import { BsCheck2All } from "react-icons/bs";
import { MdCancel } from "react-icons/md";
import {
  accountNumberIfscCodeVerifier,
  getAllThirdPartyServiceStatus,
  handleAadharSendOtp,
  handleVerifyAadharOtp,
  ifscCodeVerifier,
  saveVenderDetails,
  verifyPan,
  verifyUpiId,
  verifyVenderRegEmail,
  verifyVenderRegMobileNum,
} from "../../../services/apiService";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const AddVender = ({ setShowLoader }) => {
  const [showBankDetails, setShowBanklDetails] = useState(true);
  const [isIfscVerified, setIsIfscVerified] = useState("");
  const [isUpiVerified, setIsUpiVerified] = useState(false);
  const [isPanVerified, setIsPanVerified] = useState(false);
  const [isAadharVerified, setIsAadharVerified] = useState(false);
  const [isBankVerifieed, setIsBankVerifieed] = useState(false);

  const [isUpiError, setIsUpiError] = useState(true);
  const [isIfscError, setIsIfscError] = useState(true);
  const [isPanError, setIsPanError] = useState(true);
  const [isAadharErr, setIsAadharErr] = useState(true);
  const [isVenderNameErr, setIsVenderNameErr] = useState(false);
  const [isVenderNameLengthErr, setIsVenderLengthNameErr] = useState(false);
  const [isCategoryErr, setIsCategoryErr] = useState(false);
  const [thirdPartyServiceStatus, setThirdPartyServiceStatus] = useState([]);
  const [showAadharOtpInput, setShowAadharOtpInput] = useState(false);
  const [pressedKeyName, setPressedKeyName] = useState("");
  const [isBankVerificationRequired, setIsBankVerificationRequired] =
    useState(true);

  const [handelAadharOtpInput, setHandelAadharOtpInput] = useState({
    refNumber: "",
    otp: "",
  });
  const [venderDetails, setVenderDetails] = useState({
    companyName: "",
    mobileNumber: "",
    email: "",
    category: "",
    accountNumber: "",
    confirmAccountNumber: "",
    ifscCode: "",
    bankName: "",
    branchName: "",
    upiId: "",
    aadharNumber: "",
    panCard: "",
    isAadharVerified: false,
    isPanverified: false,
    isBankVerified: isBankVerifieed,
  });

  const navigate = useNavigate();

  const getThirdPartyServiceDetails = async () => {
    const res = await getAllThirdPartyServiceStatus();
    setThirdPartyServiceStatus(res);
    
    res?.forEach((service) => {
      if (service.serviceName === "IFSC" && service.isActive === false) {
        setIsBankVerificationRequired(false);
      }
    });
  };

  const handelValidations = (e) => {
    if (e.target.name === "mobileNumber") {
      if (venderDetails.mobileNumber.length !== 10) {
        document.getElementById("mobErr").classList.remove("d-none");
        document.getElementById("registerVender").disabled = true;
        return;
      }
      verifymobileNumber();
    }

    if (e.target.name === "email") {
      const emailRegex = new RegExp(/^[A-Za-z0-9._%]+@[A-Za-z0-9.-]+$/);
      const isValidEmail = emailRegex.test(venderDetails.email);
      if (!isValidEmail) {
        document.getElementById("emailErr").textContent =
          "*Please Enter Valid Email!";
        document.getElementById("emailErr").classList.remove("d-none");
        document.getElementById("registerVender").disabled = true;
        return;
      }
      verifyEmail();
    }

    if (
      e.target.name === "confirmAccountNumber" ||
      e.target.name === "accountNumber"
    ) {
      if (venderDetails.accountNumber !== venderDetails.confirmAccountNumber) {
        document.getElementById("cnfAccNumErr").classList.remove("d-none");
        document.getElementById("registerVender").disabled = true;
      }
    }

    if (e.target.name === "ifscCode") {
      const ifscRegex = new RegExp(/^[a-zA-Z]{4}0[A-Z0-9]{6}$/);
      const isValidIfsc = ifscRegex.test(venderDetails.ifscCode);
      if (!isValidIfsc) {
        setIsIfscError(true);
        document.getElementById("ifscErr").classList.remove("d-none");
        document.getElementById("registerVender").disabled = true;
        return;
      }
      setIsIfscError(false);
    }

    if (!showBankDetails) {
      if (e.target.name === "upiId") {
        const upiRegex = new RegExp(
          /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$|^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+$/
        );
        const isValidUpi = upiRegex.test(venderDetails.upiId);
        if (!isValidUpi) {
          setIsUpiError(true);
          document.getElementById("upiErr").classList.remove("d-none");
          document.getElementById("registerVender").disabled = true;
        }
      }
    }

    if (e.target.name === "aadharNumber") {
      let aadharRegex = new RegExp(/^[0-9]{4}[ -]?[0-9]{4}[ -]?[0-9]{4}$/);
      const isValidAadhar = aadharRegex.test(venderDetails.aadharNumber);
      if (!isValidAadhar) {
        // setIsUpiError(true);
        document.getElementById("aadharErr").classList.remove("d-none");
        document.getElementById("registerVender").disabled = true;
      }
      setIsAadharErr(false);
    }

    if (e.target.name === "panCard") {
      let panCardRegex = new RegExp(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/);
      const isPanCardValid = panCardRegex.test(venderDetails.panCard);
      if (!isPanCardValid) {
        // setIsUpiError(true);
        document.getElementById("panErr").classList.remove("d-none");
        document.getElementById("panErr").disabled = true;
      }
      setIsPanError(false);
    }
  };

  const getKeyPressed = (e) => {
    setPressedKeyName(e.key);
  };

  function handelVenderData(e) {
    if (
      isIfscVerified &&
      (e.target.name === "bankName" || e.target.name === "branchName")
    ) {
      return;
    }

    if (pressedKeyName === "Backspace" && e.target.name === "aadharNumber") {
      setVenderDetails({ ...venderDetails, [e.target.name]: e.target.value });
    }

    if (
      venderDetails.aadharNumber.length >= 12 &&
      e.target.name === "aadharNumber"
    ) {
      return;
    }

    setVenderDetails({ ...venderDetails, [e.target.name]: e.target.value });

    document.getElementById("mobErr").classList.add("d-none");
    document.getElementById("emailErr").classList.add("d-none");
    document.getElementById("aadharErr").classList.add("d-none");
    document.getElementById("panErr").classList.add("d-none");
    document.getElementById("registerVender").disabled = false;
    setIsPanError(false);
    setIsAadharErr(false);
    setIsVenderNameErr(false);
    setIsVenderLengthNameErr(false);
    setIsCategoryErr(false);

    if (!isPanVerified) {
      setIsPanVerified(false);
      document.getElementById("panCustomerName").innerHTML = "";
    }

    if (e.target.name === "panCard") {
      setIsPanVerified(false);
    }
    if (showBankDetails) {
      if (!isIfscVerified) {
        setIsIfscVerified(false);
      }
      setIsIfscError(true);
      setIsIfscVerified(false);
      // setVenderDetails({ ...venderDetails, isBankVerified: false });
      document.getElementById("cnfAccNumErr").classList.add("d-none");
      document.getElementById("ifscErr").classList.add("d-none");
    }
    if (!showBankDetails) {
      if (!isUpiVerified) {
        setIsUpiVerified(false);
        document.getElementById("upiCustomerName").innerHTML = "";
      }
      setIsUpiError(false);
      setIsUpiVerified(false);
      document.getElementById("upiErr").classList.add("d-none");
      document.getElementById("upiCustomerName").innerHTML = "";
    }
  }

  const emptyIfscError = () => {
    if (venderDetails.ifscCode === "") {
      document.getElementById("ifscErr").classList.remove("d-none");
      document.getElementById("ifscErr").innerHTML = "*Please Enter IFSC Code";
      document.getElementById("registerVender").disabled = true;
      return;
    }
  };

  const verifyIfsc = async () => {
    emptyIfscError();
    document.getElementById("bankVerifyErr").classList.add("d-none");
    setShowLoader(true);
    const isAccVerified = await accountNumberIfscCodeVerifier(
      venderDetails.ifscCode,
      venderDetails.accountNumber
    );
    // console.log(isAccVerified.statusCode, "isAxcc");
    const res = await ifscCodeVerifier(venderDetails.ifscCode);
    setShowLoader(false);
    // console.log(res, "res--");
    if (res.status === 200 && isAccVerified.statusCode === 200) {
      // console.log("inside............");
      setIsBankVerifieed(true);
      // setVenderDetails({ ...venderDetails, isBankVerified: true })
      setVenderDetails({
        ...venderDetails,
        branchName: res.data.branch,
        bankName: res.data.bank,
        isBankVerified: true,
      });
      setIsIfscVerified(true);
    } else {
      setIsIfscVerified("NotVerified");
      swal({
        title: "Alert!",
        text: "Invalid Bank Details !",
        icon: "error",
      });
    }
  };

  const emptyUpiError = () => {
    if (venderDetails.upiId === "") {
      document.getElementById("upiErr").classList.remove("d-none");
      document.getElementById("upiErr").innerHTML = "*Please Enter UPI ID";
      document.getElementById("registerVender").disabled = true;
      return;
    }
  };

  const verifyUpi = async () => {
    emptyUpiError();
    setShowLoader(true);
    const res = await verifyUpiId(venderDetails.upiId);
    setShowLoader(false);
    // console.log(res);
    if (res.status === 304) setIsUpiVerified("NotVerified");

    if (res.status === 200) {
      setIsUpiVerified(true);
      // console.log(res.data.customerName);
      setVenderDetails({ ...venderDetails, isBankVerified: true });
      document.getElementById("upiCustomerName").innerHTML =
        res.data.customerName;
    }
  };

  const verifyEmail = async () => {
    const tokenValue = localStorage.getItem("token");
    const token = tokenValue ? tokenValue.slice(1, -1) : null;
    const decodedToken = jwtDecode(token);
    const { mid } = decodedToken;
    const res = await verifyVenderRegEmail(venderDetails.email, token, mid);
    if (res.statusCode !== 200) {
      document.getElementById("emailErr").classList.remove("d-none");
      document.getElementById("emailErr").textContent =
        "Email Already Exists !";
      document.getElementById("registerVender").disabled = true;
    }
  };

  const verifymobileNumber = async () => {
    const tokenValue = localStorage.getItem("token");
    const token = tokenValue ? tokenValue.slice(1, -1) : null;
    const decodedToken = jwtDecode(token);
    const { mid } = decodedToken;
    const res = await verifyVenderRegMobileNum(
      venderDetails.mobileNumber,
      token,
      mid
    );
    if (res.statusCode !== 200) {
      document.getElementById("mobErr").classList.remove("d-none");
      document.getElementById("mobErr").textContent =
        "Mobile Number Already Exists !";
      document.getElementById("registerVender").disabled = true;
    }
  };

  const emptyAadharNumberError = () => {
    if (venderDetails.aadharNumber === "") {
      document.getElementById("aadharErr").classList.remove("d-none");
      document.getElementById("aadharErr").innerHTML =
        "*Please Enter Aadhar Number";
      document.getElementById("registerVender").disabled = true;
      return;
    }
  };

  const verifyAadharCard = async () => {
    // calling api to send otp on aadhaar number
    setShowLoader(true);
    let res = await handleAadharSendOtp(venderDetails.aadharNumber);
    setShowLoader(false);
    if (res.statusCode === 200) {
      // if(res === 200) {
      // console.log(res.data);
      setShowAadharOtpInput(true);
      document.getElementById("verifyAadharBtn").textContent = "Resend OTP";
      document.getElementById("aadharCustomerName").textContent =
        "OTP Send Successfully";
      setHandelAadharOtpInput({ ...handelAadharOtpInput, refNumber: res.data });
    }
  };

  const verifyAadharOtp = async () => {
    setShowLoader(true);
    let res = await handleVerifyAadharOtp(handelAadharOtpInput);
    setShowLoader(false);
    // console.log(res.statusCode);
    if (res.statusCode === 200) {
      setShowAadharOtpInput(false);
      setIsAadharVerified(true);
      setVenderDetails({ ...venderDetails, isAadharVerified: true });
      document.getElementById("aadharCustomerName").textContent = res.data.Name;
    }
  };

  const emptyPanCardError = () => {
    if (venderDetails.panCard === "") {
      document.getElementById("panErr").classList.remove("d-none");
      document.getElementById("panErr").innerHTML = "*Please Enter Pan Number";
      document.getElementById("registerVender").disabled = true;
      return;
    }
  };

  const verifyPanCard = async () => {
    document.getElementById("panVerifyErr").classList.add("d-none");
    setShowLoader(true);
    let res = await verifyPan(venderDetails.panCard);
    setShowLoader(false);
    // console.log(res.data.Name);
    if (res.statusCode === 200) {
      setIsPanVerified(true);
      setVenderDetails({ ...venderDetails, isPanverified: true });
      document.getElementById("panCustomerName").innerHTML = res?.data?.Name;
    }
    if (res.statusCode !== 200) setIsPanVerified("NotVerified");
  };

  const handelVenderRegister = async (e) => {
    e.preventDefault();

    const { companyName, category, isBankVerified, email, mobileNumber, accountNumber, confirmAccountNumber, ifscCode, bankName, branchName, upiId } =
      venderDetails;

    if (companyName.trim() === "") {
      setIsVenderNameErr(true);
      document.getElementById("registerVender").disabled = true;
      return;
    }

    if (companyName.trim().length < 5) {
      setIsVenderLengthNameErr(true);
      document.getElementById("registerVender").disabled = true;
      return;
    }

    if (mobileNumber.trim() === "") {
      document.getElementById("mobErr").classList.remove("d-none");
      document.getElementById("registerVender").disabled = true;
      return;
    }
    if (email.trim() === "") {
      document.getElementById("emailErr").classList.remove("d-none");
      document.getElementById("registerVender").disabled = true;
      return;
    }

    if (category.trim() === "") {
      setIsCategoryErr(true);
      document.getElementById("registerVender").disabled = true;
      return;
    }

    // if(!isPanVerified) {
    //   document.getElementById("panVerifyErr").classList.remove("d-none");
    // }

    // if (isBankVerificationRequired) {
    //   document.getElementById("bankVerifyErr").classList.remove("d-none");
    //   document.getElementById("registerVender").disabled = true;
    //   return;
    // }

    if (venderDetails.mobileNumber.length !== 10) {
      document.getElementById("mobErr").classList.remove("d-none");
      document.getElementById("registerVender").disabled = true;
      return;
    }

    // if (companyName === "") {
    //   document.getElementById("nameErr").classList.remove("d-none");
    // }

    // if (category === "") {
    //   document.getElementById("categoryErr").classList.remove("d-none");
    // }

    if(showBankDetails && accountNumber ==="" && confirmAccountNumber ==="" && ifscCode ==="" && bankName ==="" && branchName ==="") {
      swal({
        title: "Alert!",
        text: "Please Enter Valid Bank Details",
        icon: "error",
      })
      return
    }

    if(!showBankDetails && upiId ==="" ) {
      swal({
        title: "Alert!",
        text: "Please Enter Valid UPI ID",
        icon: "error",
      })
      return
    }

    if (
      companyName !== "" &&
      category !== "" &&
      email !== "" &&
      mobileNumber !== "" 
    ) {
      const tokenValue = localStorage.getItem("token");
      const token = tokenValue ? tokenValue.slice(1, -1) : null;
      const decodedToken = jwtDecode(token);
      const { mid } = decodedToken;
      console.log(mid);

      let venderDetail = {
        aadhaarNo: venderDetails.aadharNumber,
        aadharVerfied: venderDetails.isAadharVerified,
        accountNumber: venderDetails.accountNumber,
        bankName: venderDetails.bankName,
        bankVerified: venderDetails.isBankVerified,
        branchName: venderDetails.branchName,
        category: venderDetails.category,
        email: venderDetails.email,
        fullName: venderDetails.companyName,
        ifscCode: venderDetails.ifscCode.toUpperCase(),
        mobile: venderDetails.mobileNumber,
        panNumber: venderDetails.panCard,
        panVerfied: venderDetails.isPanverified,
        parentMid: mid,
        upi: venderDetails.upiId,
      };

      setShowLoader(true);
      let res = await saveVenderDetails(venderDetail, token);
      setShowLoader(false);
      // console.log(res);
      if (res.statusCode === 200) {
        // setIsSnackBarAlertOpen(true)
        // setShowSuccessAlert(false)
        // setAlertContent("Vender Registered Successfully")
        swal({
          title: "Success",
          text: "Vender Registered Successfully!!",
          icon: "success",
        });
        navigate("/Dashboard");
      } else {
        swal({
          title: "Opps!",
          text: res.message + " !",
          icon: "error",
        });
      }
    }
  };

  useEffect(() => {
    getThirdPartyServiceDetails();
  }, []);

  return (
    <>
      <h3 className="text-center bg-black text-light p-4">
        Vendor Registration
      </h3>
      <form>
        <div className="d-flex">
          {/* LEFT SECTION */}
          <div className="w-50 px-4 py-5 border ">
            <div className="form-group mt-3">
              <label htmlFor="companyName">
                <span className="text-danger">* </span>VENDOR NAME :
              </label>
              <input
                type="text"
                className="form-control"
                name="companyName"
                value={venderDetails.companyName}
                onChange={handelVenderData}
                placeholder="Company Name/Full Name"
              />
              {isVenderNameErr ? (
                <span id="nameErr" className=" text-danger">
                  * Vender Name/Company Name Can't be Empty !!
                </span>
              ) : (
                ""
              )}

              {isVenderNameLengthErr ? (
                <span id="nameErr" className=" text-danger">
                  Vender Name/Company Name Should be Greater than 5 Character
                </span>
              ) : (
                ""
              )}
            </div>

            <div className="form-group mt-3">
              <label htmlFor="mobileNumber">
                <span className="text-danger">* </span>MOBILE NUMBER:
              </label>
              <input
                type="number"
                className="form-control"
                name="mobileNumber"
                value={venderDetails.mobileNumber}
                onChange={handelVenderData}
                onBlur={handelValidations}
                placeholder="Mobile Number"
              />
              <span id="mobErr" className=" text-danger d-none">
                *Mobile Number can't greater than or less than 10 digits!
              </span>
            </div>

            <div className="form-group mt-3">
              <label htmlFor="email">
                <span className="text-danger">* </span>e-Mail:
              </label>
              <input
                type="email"
                className="form-control"
                name="email"
                value={venderDetails.email}
                onChange={handelVenderData}
                onBlur={handelValidations}
                placeholder="e-Mail"
              />
              <span id="emailErr" className=" text-danger d-none">
                *Please Enter Valid Email!
              </span>
            </div>

            <div className="form-group mt-3">
              <label htmlFor="category">
                <span className="text-danger">* </span> CATEGORY:
              </label>
              <input
                type="text"
                className="form-control"
                name="category"
                value={venderDetails.category}
                onChange={handelVenderData}
                placeholder="Category"
              />
              {isCategoryErr ? (
                <span id="categoryErr" className=" text-danger">
                  * Category Can't be Empty !!
                </span>
              ) : (
                ""
              )}
            </div>

            <div className="form-group mt-3">
              <label htmlFor="aadharNumber">AADHAAR CARD:</label>
              <input
                type="number"
                className="form-control"
                name="aadharNumber"
                value={venderDetails.aadharNumber}
                onChange={handelVenderData}
                onKeyDown={getKeyPressed}
                onBlur={handelValidations}
                placeholder="Aadhar Number"
                minLength={0}
                maxLength={12}
              />
              <span id="aadharErr" className=" text-danger d-none">
                *Please Enter Valid Aadhaar Number!
              </span>

              <div className="d-flex justify-content-between mt-1">
                <span id="aadharCustomerName" className="text-primary"></span>

                {isAadharVerified ? (
                  ""
                ) : (
                  <div className="d-flex justify-content-between">
                    {/* <span>Otp Sent Successfully</span> */}
                    {thirdPartyServiceStatus?.map((serviceName) => {
                      if (
                        serviceName.serviceName === "AADHAAR" &&
                        serviceName.isActive === true
                      ) {
                        return (
                          <span
                            role="button"
                            id="verifyAadharBtn"
                            onClick={
                              isAadharErr
                                ? () => {
                                    emptyAadharNumberError();
                                  }
                                : verifyAadharCard
                            }
                            className="text-primary cursor-pointer align-right mt-1"
                          >
                            Verify AADHAAR
                          </span>
                        );
                      }
                      return null;
                    })}
                  </div>
                )}
                {isAadharVerified === "NotVerified" ? (
                  <div className="d-flex justify-content-end">
                    <span
                      role="button"
                      className="text-danger cursor-pointer align-right mt-1"
                    >
                      <b>
                        <MdCancel />
                        Not Verified
                      </b>
                    </span>
                  </div>
                ) : (
                  ""
                )}
                {isAadharVerified === true ? (
                  <div className="d-flex justify-content-end">
                    <span
                      role="button"
                      className="text-success cursor-pointer align-right mt-1"
                    >
                      <b>
                        <BsCheck2All />
                        Verified
                      </b>
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            {showAadharOtpInput ? (
              <div className="form-group mt-3">
                <label htmlFor="aadharOtp">AADHAAR OTP:</label>
                <div className="d-flex">
                  <input
                    type="password"
                    className="form-control w-25"
                    name="otp"
                    value={handelAadharOtpInput.otp}
                    onChange={(e) => {
                      if (pressedKeyName === "Backspace") {
                        // alert("Back")
                        setHandelAadharOtpInput({
                          ...handelAadharOtpInput,
                          otp: e.target.value,
                        });
                      }

                      if (handelAadharOtpInput.otp.length >= 6) {
                        return;
                      }
                      setHandelAadharOtpInput({
                        ...handelAadharOtpInput,
                        otp: e.target.value,
                      });
                    }}
                    onKeyDown={getKeyPressed}
                    placeholder="Enter aadhaar otp "
                  />
                  <div className="text-primary mt-1 w-75 px-3 d-flex align-items-center ">
                    <span
                      role="button"
                      className="cursor-pointer"
                      onClick={verifyAadharOtp}
                    >
                      Validate OTP
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="form-group mt-3">
              <label htmlFor="panCard">PAN CARD:</label>
              <input
                type="text"
                className="form-control"
                name="panCard"
                value={venderDetails.panCard}
                onChange={handelVenderData}
                onBlur={handelValidations}
                placeholder="PAN Card Number"
              />
              <span id="panErr" className=" text-danger d-none">
                *Please Enter Valid Pan Number!
              </span>
              <span id="panVerifyErr" className=" text-danger d-none">
                *Please Verify Your Pan Number!
              </span>

              <div className="d-flex justify-content-between mt-1">
                <span id="panCustomerName" className="text-primary"></span>
                {isPanVerified ? (
                  ""
                ) : (
                  <div className="d-flex justify-content-end">
                    {thirdPartyServiceStatus?.map((serviceName) => {
                      if (
                        serviceName.serviceName === "PAN" &&
                        serviceName.isActive === true
                      ) {
                        return (
                          <span
                            role="button"
                            onClick={
                              isPanError
                                ? () => {
                                    emptyPanCardError();
                                  }
                                : verifyPanCard
                            }
                            className="text-primary cursor-pointer align-right mt-1"
                          >
                            Verify PAN
                          </span>
                        );
                      }
                      return null;
                    })}
                  </div>
                )}
                {isPanVerified === "NotVerified" ? (
                  <div className="d-flex justify-content-end">
                    <span
                      role="button"
                      className="text-danger cursor-pointer align-right mt-1"
                    >
                      <b>
                        <MdCancel />
                        Not Verified
                      </b>
                    </span>
                  </div>
                ) : (
                  ""
                )}
                {isPanVerified === true ? (
                  <div className="d-flex justify-content-end">
                    <span
                      role="button"
                      className="text-success cursor-pointer align-right mt-1"
                    >
                      <b>
                        <BsCheck2All />
                        Verified
                      </b>
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          {/* RIGHT SECTION  */}
          <div className="w-50 px-4 border pb-4 ">
            <div className="form-group mt-3 d-flex">
              <div className="">
                <input
                  type="radio"
                  className="form-check-input "
                  name="bankDetails"
                  checked={showBankDetails}
                  onChange={() => setShowBanklDetails(!showBankDetails)}
                />
                <label htmlFor="detailsRadio">
                  <span>&nbsp;Bank Details</span>
                </label>
              </div>

              <div className="mx-3">
                <input
                  type="radio"
                  className="form-check-input ml-3"
                  name="bankDetails"
                  checked={!showBankDetails}
                  onChange={() => setShowBanklDetails(!showBankDetails)}
                />
                <label htmlFor="detailsRadio">
                  <span> &nbsp;UPI ID / VPA</span>
                </label>
              </div>
            </div>
            {/* BANK DETAILS SECTION */}
            {showBankDetails ? (
              <div>
                <div className="form-group mt-3">
                  <label htmlFor="accountNumber">
                    <span className="text-danger">* </span>ACCOUNT NUMBER:
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    name="accountNumber"
                    value={venderDetails.accountNumber}
                    onChange={handelVenderData}
                    onBlur={handelValidations}
                    placeholder="Account Number"
                  />
                </div>

                <div className="form-group mt-3">
                  <label htmlFor="confirmAccountNumber">
                    <span className="text-danger">* </span>CONFIRM ACCOUNT
                    NUMBER:
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    name="confirmAccountNumber"
                    value={venderDetails.confirmAccountNumber}
                    onChange={handelVenderData}
                    onBlur={handelValidations}
                    placeholder="Confirm Account Number"
                  />
                  <span id="cnfAccNumErr" className=" text-danger d-none">
                    *Account Number Mismatch!{" "}
                  </span>
                </div>

                <div className="form-group mt-3">
                  <label htmlFor="ifscCode">
                    <span className="text-danger">* </span>IFSC CODE:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="ifscCode"
                    value={venderDetails.ifscCode}
                    onChange={handelVenderData}
                    onBlur={handelValidations}
                    placeholder="IFSC Code"
                  />
                  <span id="ifscErr" className=" text-danger d-none">
                    *Please Enter Valid IFSC!{" "}
                  </span>
                  <span id="bankVerifyErr" className=" text-danger d-none">
                    *Please Verify Your Bank !
                  </span>
                  {!isIfscVerified ? (
                    <div className="d-flex justify-content-end">
                      {thirdPartyServiceStatus?.map((serviceName) => {
                        if (
                          serviceName.serviceName === "IFSC" &&
                          serviceName.isActive === true
                        ) {
                          return (
                            <span
                              onClick={
                                isIfscError
                                  ? () => {
                                      emptyIfscError();
                                    }
                                  : verifyIfsc
                              }
                              role="button"
                              className="text-primary cursor-pointer align-right mt-1"
                            >
                              Verify IFSC
                            </span>
                          );
                        }
                        return null;
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                  {isIfscVerified === true ? (
                    <div className="d-flex justify-content-end">
                      <span
                        role="button"
                        className="text-success cursor-pointer align-right mt-1"
                      >
                        <b>
                          <BsCheck2All />
                          Verified
                        </b>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  {isIfscVerified === "NotVerified" ? (
                    <div className="d-flex justify-content-end">
                      <span
                        role="button"
                        className="text-danger cursor-pointer align-right mt-1"
                      >
                        <b>
                          <MdCancel />
                          Not Verified
                        </b>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="form-group mt-3">
                  <label htmlFor="bankName">
                    <span className="text-danger">* </span>BANK NAME:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="bankName"
                    value={venderDetails.bankName}
                    onChange={handelVenderData}
                    placeholder="Bank Name"
                  />
                </div>

                <div className="form-group mt-3">
                  <label htmlFor="branchName">
                    <span className="text-danger">* </span>BRANCH NAME:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="branchName"
                    value={venderDetails.branchName}
                    onChange={handelVenderData}
                    placeholder="Branch Name"
                  />
                </div>
              </div>
            ) : (
              ""
            )}

            {/* UPI ID SECTION */}
            {!showBankDetails ? (
              <div>
                <div className="form-group mt-3">
                  <label htmlFor="upiId">
                    <span className="text-danger">* </span>UPI ID:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="upiId"
                    value={venderDetails.upiId}
                    onChange={handelVenderData}
                    onBlur={handelValidations}
                    placeholder="UPI ID"
                  />
                  <span id="upiErr" className=" text-danger d-none">
                    *Please Enter Valid UPI ID !
                  </span>
                  <div className="d-flex justify-content-between mt-1">
                    <span id="upiCustomerName" className="text-primary"></span>
                    {!isUpiVerified ? (
                      <div className="d-flex justify-content-end">
                        {thirdPartyServiceStatus?.map((serviceName) => {
                          if (
                            serviceName.serviceName === "UPI" &&
                            serviceName.isActive === true
                          ) {
                            return (
                              <span
                                onClick={
                                  isUpiError
                                    ? () => {
                                        emptyUpiError();
                                      }
                                    : verifyUpi
                                }
                                role="button"
                                className="text-primary cursor-pointer align-right mt-1"
                              >
                                Verify UPI
                              </span>
                            );
                          }
                          return null;
                        })}
                      </div>
                    ) : (
                      ""
                    )}
                    {isUpiVerified === "NotVerified" ? (
                      <div className="d-flex justify-content-end">
                        <span
                          role="button"
                          className="text-danger cursor-pointer align-right mt-1"
                        >
                          <b>
                            <MdCancel />
                            Not Verified
                          </b>
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    {isUpiVerified === true && isUpiVerified ? (
                      <div className="d-flex justify-content-end">
                        <span
                          role="button"
                          className="text-success cursor-pointer align-right mt-1"
                        >
                          <b>
                            {" "}
                            <BsCheck2All />
                            Verified
                          </b>
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="d-flex justify-content-center mt-4">
          <div className="Advanced-btn">
            <button
              id="registerVender"
              className={`advanced-btn btn btn-primary text-capitlize`}
              onClick={handelVenderRegister}
            >
              Register
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddVender;
