import React from "react";
import jwtDecode from "jwt-decode";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";
import { usePieChartDataFetcher } from "../../services/apiService";
import "./Chart.css"; // Import your custom CSS file for styling

function Chart() {
  const tokenValue = localStorage.getItem("token");
  const token = tokenValue ? tokenValue.slice(1, -1) : null;
  const decodedToken = jwtDecode(token);
  const { mid } = decodedToken;

  const chartData = usePieChartDataFetcher(mid, token);

  // Define the colors for the pie chart
  const colors = [
    // "#008000",
    // "#FFA500",
    "#AAA4A2",
    "#154c79",
    "#FF0000",
  "#0000FF",
  "#800080",
  "#00FFFF",
  "#FF00FF",
  "#C1AB56",
  "#008080",
  "#00FF00",
  "#A52A2A",
  "#FFA500",
  "#000000",
  "#FFFF00"]

  // Create an array of data with your provided labels and values
  const data = [
    { name: "QR", value: 0 },
    { name: "UPI", value: 0 },
    { name: "DCP", value: 0 },
    { name: "POS", value: 0 },
    { name: "mATM", value: 0 },
    { name: "PAYOUT", value: 0 },
    { name: "DMT", value: 0 },
    { name: "AEPS", value: 0 },
    { name: "IPG", value: 0 },
    { name: "NET BANKING", value: 0 },
    { name: "REMITTANCE", value: 0 },
    { name: "Soft POS", value: 0 },
    { name: "VIRTUAL TERMINAL", value: 0 },
    
  ];

  // Update the data with the values from your API response
  if (chartData) {
    chartData.forEach((dataItem) => {
      const index = data.findIndex((item) => item.name === dataItem.category);
      if (index !== -1) {
        data[index].value = dataItem.totalCount;
      }
    });
  }

  return (
    <div className="chart-container">
      <div className="pie-chart">
        {chartData ? (
          <PieChart width={600} height={500}>
            <Pie
            className="mb-4"
              data={data}
              cx={300}
              cy={200}
              innerRadius={60}
              outerRadius={100}
              fill="#8884d8"
              dataKey="value"
              label
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
              ))}
            </Pie>
            <Tooltip />
      <Legend  />
            
          </PieChart>
          
        ) : (
          <p>Loading chart data...</p>
        )}
      </div>
       
    </div>
  );
}

export default Chart;