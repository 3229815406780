// import { useRouteError } from "react-router-dom";
const RootErrorBoundary = () => {
  // const error = useRouteError();
  return (
    <>
      <div className="error-page">
        <h1>{/* {error.status} : {error.statusText} */}</h1>
        <h1>404</h1>
        {/* <p>{error.statusText}</p> */}
        <p>Oh no! We couldn’t find it</p>
      </div>
    </>
  );
};

export default RootErrorBoundary;
