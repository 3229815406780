import React from "react";

const MerchantProfile = () => {
  return (
    <div>
      <ul className="merchant-profile">
        <li>View Profile</li>
        <li>Change Password</li>
      </ul>
    </div>
  );
};

export default MerchantProfile;
